@import '../../variables/colors';
@import './show';

.search-main .page.show {
  margin-top: 65px;
  &.menu-show,
  &.entertainment-show,
  &.drink-show,
  &.staff-show,
  &.furniture-show {
    > .ct--search-show-topbar {
      border-bottom: 1px solid $breadcrumb;
    }
    > .show-wrapper {
      padding: 0 0 20px 55px;
      min-height: 70vh;
      .row-fiche {
        .col {
          .headline {
            .ct--breadcrumb {
              .inner {
                padding: 20px 0;
              }
            }
          }
        }
      }
    }
  }
  > .ct--search-show-topbar {
    border-bottom: 1px solid $breadcrumb;
    position: fixed;
    top: 80px;
    width: calc(100vw - 330px);
    background-color: #ffffff;
    min-height: 65px;
    height: auto;
    border-bottom: 1px solid #e0dcd8;
    padding: 0 25px;
    z-index: 20;
    .col {
      > .price-topnav,
      > .quantity,
      > .filter-slider-quantity,
      > .options-topnav,
      > .duration {
        margin-right: 15px;
      }
      > .price-topnav {
        min-width: 92px;
      }
      > .quantity,
      > .duration {
        display: flex;
        flex-wrap: wrap;
        min-width: 112px;
        .icon-filter {
          display: inline-block;
          padding-top: 14px;
          margin-right: 12px;
          svg {
            fill: $medium-grey;
            width: 23px;
            height: 23px;
          }
        }
      }
      > .duration {
        .icon-filter {
          padding-top: 17px;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
      .options-topnav {
        .choose-option {
          max-width: 200px;
        }
      }
      .price-tooltip {
        display: inline-block;
        margin-right: 10px;
        position: relative;
        top: 1px;
      }
    }
  }
  .list-fiche {
    @media screen and (max-width: 1115px) {
      li {
        width: 100%;
      }
    }
  }
  > .show-wrapper {
    > .headline {
      margin-bottom: 20px;
    }
    .row-table {
      // overflow-x: auto;
    }
    .row-fiche {
      .title-row-fiche {
        line-height: 1.1;
        text-transform: uppercase;
      }
      .product-title {
        line-height: 2.8rem;
      }
      .list-fiche {
        @media screen and (max-width: 1115px) {
          li {
            width: 100%;
          }
        }
      }
      &.row-menu {
        width: 50%;
        @media screen and (max-width: 1366px) {
          width: 90%;
        }
        .about-menu {
          li {
            width: 85px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  // tabs
  .nav-tabs {
    margin-bottom: 40px;

    .nav-item {
      display: inline-block;
      margin-right: 40px;
      vertical-align: middle;

      .nav-link {
        @extend .montserrat-light;
        font-size: 2.8rem;
        text-transform: uppercase;
        background: transparent;
        border: none;
        border-bottom: 5px solid $light-grey;
        color: $light-grey;
        padding: 0 0 8px;
        cursor: pointer;

        &:hover,
        &.active {
          border-bottom-color: $yellow;
          color: $black;
        }

        &.active {
          cursor: initial;
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.menu-show {
  .surplus-management-row {
    display: flex;
    margin-bottom: 20px;

    .surplus-management-icon {
      width: auto;

      svg {
        background-color: #3f6a8f;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        fill: white;
      }
    }

    .surplus-management-description {
      width: auto;
      padding-left: 10px;

      p {
        font-weight: bold;
        font-size: 16px;
        height: 100%;
        line-height: 25px;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .search-main .page.show > .ct--search-show-topbar {
    width: 1270px;
  }
}
