@import 'trends';

.section-trends {
  margin-bottom: 80px;

  &.section-trends-last {
    margin-bottom: 160px;
  }

  .title-trends {
    font-size: 9rem;
  }

  .container {
    .inner {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      padding-top: 165px;

      .col.col-right {
        .content {
          transform: translateY(-30%);
        }
      }

      .col.col-left {
        .content {
          transform: translateY(30%);
        }
      }

      .col.col-left .double-pic .pic,
      .col.col-right .double-pic .pic {
        .title-2 {
          line-height: 1.5;
          text-align: center;
          text-shadow: 0 1px 3px #000000cc;
        }

        &:after {
          opacity: 0.9;
          transform: scale(1);
          transition: opacity 250ms $ease-in-out-quad;
        }

        a {
          opacity: 1;
          transform: translateY(0);
          transition: transform 250ms $ease-in-out-quad;
        }

        &:hover {
          &:after {
            opacity: 1;
          }

          a {
            transform: translateY(-5px);
          }
        }
      }
    }
  }
}
