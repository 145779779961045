@import '../../variables/colors';

.page.static-place {
  > .ct--header {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:before {
        content: initial; // todo : apply globally ?
      }

      .first-title {
        position: initial;
        transform: none;

        &.c10 {
          width: auto;
        }
      }

      .second-title {
        margin-top: 10px;
        font-size: 2rem;
        color: #fff;
        z-index: 10;
      }
    }
  }

  .ct--breadcrumb {
    margin-top: 10px;

    .inner {
      padding-left: 0;
    }
  }

  .static-place-image {
    max-width: 100%;
    border-radius: 15px;
  }

  .static-place-title {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .static-place-alert {
    margin: 0;
    padding: 20px 0;
    background-color: $yellow;
    color: #000;

    .icon {
      position: relative;
      top: 2px;
      margin-right: 2px;
    }

    a {
      border-bottom: 1px solid;
      color: #000;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .static-place-content {
    h2 {
      @extend .title-1;
      margin-bottom: 30px;
      line-height: 35px;
    }

    p,
    ul {
      @extend .text-1;
      margin-bottom: 30px;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    ul {
      padding-left: 20px;
      list-style-type: initial;

      li {
        margin-bottom: 6px;
      }
    }

    p + ul {
      margin-top: -15px;
    }
  }

  .static-place-location {
    > .cols {
      display: flex;
    }

    .public-transports-wrapper {
      h2 {
        font-size: 2rem;
        margin-bottom: 10px;
      }
    }

    .subway-station-list {
      padding-left: 10px;

      li {
        line-height: 1.5;
        list-style: none;

        .icon {
          position: relative;
          top: 2px;
          margin-right: 1px;
        }

        .duration {
          color: #818181;
          font-size: 1.35rem;
        }
      }
    }
  }

  .card-show-wrapper {
    margin-bottom: 20px;
  }

  .static-place-capacity {
    @extend .text-1;
  }

  .static-place-similar {
    padding: 30px 15px 15px;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: $light-beige;

    .section-header {
      text-align: center;
    }

    .static-place-similar-content {
      display: flex;
      gap: 5px;

      .ct--item-result {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;

        .about {
          flex: 1;

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .static-place-faq {
    margin-bottom: 20px;

    .static-place-faq-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;

      &.odd {
        & > :last-child {
          grid-column: span 2;
        }
      }
    }
  }
}
