.input-text,
input[type='text'] {
  @extend .rawline-regular;
  display: block;
  font-size: 1.5rem;
  height: 50px;
  padding: 0 20px;
  background-color: $white;
  border: 0;
  color: $black;
  &.error {
    border: 2px solid $red;
    color: $red;
  }
}

.button-radio {
  display: block;
  .checkmark {
    margin-left: 12px;
    vertical-align: middle;
  }
  .checkbox {
    border-radius: 100%;
    vertical-align: middle;
  }
}

.label,
label {
  color: $black;
  margin-bottom: 8px;
  display: block;
  &.error {
    color: $red;
  }
}
