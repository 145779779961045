@import '../../variables/colors';

.section-trends {
  .container {
    .title-trends {
      color: $yellow;
      display: block;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 160px;
    }

    .inner {
      .col {
        &.col-left {
          align-self: flex-start;
        }

        &.col-right {
          .content {
            position: relative;
            transform: translateY(-50%);

            .title-right {
              display: block;
              margin-bottom: 20px;
            }

            .text-content {
              margin-bottom: 45px;
            }
          }
        }

        .double-pic {
          .pic {
            position: relative;
            width: 50%;
            display: inline-block;
            background-size: cover;
            background-position: center center;

            &:before {
              content: '';
              width: 100%;
              height: 0;
              display: block;
              padding-bottom: 100%;
            }

            a {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: 5;
              color: $white;
              text-transform: uppercase;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:after {
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.3);
              z-index: 2;
            }

            img {
              position: absolute;
              min-width: 100%;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.section-trends {
  .double-pic {
    &.decorated {
      position: relative;
      z-index: 2;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: -60px;
        left: 0;
        z-index: -1;
        background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
          center center repeat;
      }
    }
  }
}
