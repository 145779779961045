.ct--select-checkbox {
  position: relative;
  &.open {
    .filter-select-choice {
      .select-choice {
        display: flex;
      }
    }
    .filter-choice .valid {
      display: block;
    }
  }
  .filter-select-choice {
    position: relative;
    width: 100%;
    .generic-label {
      display: block;
    }
    .select-choice-overflow {
      overflow-y: scroll;
      max-height: 170px;
    }
    .select-choice {
      cursor: pointer;
      display: none;
      justify-content: space-between;
      .label {
        margin-bottom: 0;
      }
      &:hover {
        background: $dark-beige;
      }
    }
    .select-choice,
    .validate-choice {
      padding: 8px 15px;
      background: $light-beige;
      align-items: center;
      .label {
        color: $black;
        cursor: pointer;
        font-size: 13px;
      }
    }
    .validate-choice {
      border-bottom: 1px solid $medium-grey;
    }
    .other-choice {
      overflow-y: scroll;
      width: 100%;
      height: calc(62px * 4);
    }
  }
  .filter-choice {
    position: relative;
    color: $black;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 35px;
    background: $light-beige;
    @extend .filter-1;
    line-height: 1.9rem;
    .response-filter {
      display: block;
      width: calc(100% - 30px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: bold;
    }
    .arrow {
      position: relative;
      width: 8px;
      height: 5px;
      .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .valid {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $yellow;
      z-index: 5;
      left: 0;
      top: 0;
      border: 0;
      cursor: pointer;
      display: none;
      .icon {
        position: absolute;
        width: 10px;
        height: 10px;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px;
        z-index: 10;
        fill: $bobby-brown;
      }
    }
  }
}
