@import 'homepage';
@import 'homepage.searchbar.desktop';

.page.homepage-index {
  .section-header {
    min-height: 625px;

    &:before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: percentage(708 / 1366);
    }

    .contents,
    .pictures {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }

    .contents {
      z-index: 10;
    }
  }

  .section-trends {
    // override here
  }

  .section-zoom {
    position: relative;
    width: 100%;

    .wrapper-section-zoom {
      &:before {
        width: 83.33333%;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }

      &:after {
        width: 83.33333%;
        height: 380px;
        left: 50%;
        transform: translateX(-50%);
      }

      .title-zoom {
        @extend .keyword-2;
        margin-bottom: 90px;
      }

      .ct.ct--double-col {
        margin-bottom: 0;
      }

      .ct.ct--button.arrow {
        margin-top: 60px;
      }

      .ct.ct--button.text {
        &:hover {
          &:before {
            background: $white;
          }

          .label-btn {
            color: $white;
          }

          .arrow {
            .icon {
              fill: $white;
            }
          }
        }
      }
    }
  }

  .section-more {
    &:before {
      height: calc(100% - 55px);
    }

    .ct.ct--double-col .container .cols .col .inner .headline,
    .ct.ct--double-col-slider-companies
      .container
      .cols
      .col.col-picture
      .headline {
      @extend .keyword-2;
    }

    .ct--double-col {
      margin-bottom: 110px;
    }
  }
}
