.page.brief {
  .container {
    .sizing-2 {
      .brief-form {
        padding: 40px;
        box-shadow: #ddd 2px 2px 8px;
      }
    }
  }
}
