@import '~vue-multiselect/dist/vue-multiselect.min.css';

.selection-modal.edit-event-modal {
  $min-height: 510px;

  .selection-modal-content {
    overflow: hidden;

    form {
      width: 100%;

      .form-header {
        text-align: center;

        .title {
          @extend .title-2;
          font-family: $montserrat;
          font-size: 2.4rem;
          font-weight: 300;
          line-height: 1.25;
          margin-bottom: 10px;
        }

        .subtitle {
          @extend .text-1;
          font-family: $montserrat;
          font-weight: 300;
        }

        &:after {
          background: #f4c657;
          content: '';
          display: block;
          height: 3px;
          margin: 20px auto 25px;
          width: 20%;
        }
      }

      h3 {
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      .form-body-placeholder {
        background-color: $white;
        min-height: $min-height;
      }

      .form-body {
        min-height: $min-height;
        padding-right: 20px;
        width: 100%;
      }

      hr {
        border: none;
        border-top: 1px solid #e8e8e8;
        margin: 25px -40px 25px -20px;
      }

      label {
        font-family: $montserrat;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;

        &.required::after {
          content: '*';
          margin-left: 6px;
          position: relative;
          top: 1px;
        }

        &.checkbox,
        &.radio {
          display: inline-block;
          margin-top: 6px;
          padding-right: 6px;
          white-space: nowrap;

          input {
            vertical-align: middle;
            position: relative;
            bottom: 0.08em;
            margin-right: 3px;
          }

          input[type='radio'] {
            bottom: 0;
          }

          span {
            vertical-align: middle;
          }
        }
      }

      .form-row {
        display: block;
        margin-bottom: 20px;
        vertical-align: top;
        width: 100%;

        .form-col {
          display: inline-block;
          vertical-align: top;
          width: 48%;

          &:first-child {
            margin-right: 3%;
          }
        }
      }

      .form-group {
        display: block;
        vertical-align: top;

        .form-group {
          display: inline-block;
          width: 48%;
          margin-right: 1%;

          &:last-child {
            margin-right: 0;
            margin-left: 2%;
          }
        }
      }

      // field level errors

      .form-row.has-errors,
      .form-group.has-errors {
        color: $red;

        label {
          color: $red;
        }

        .form-control,
        .multiselect__tags {
          border-color: $red !important;
        }
      }

      .form-error {
        color: $red;
        font-family: $montserrat;
        font-size: 12px;
        font-weight: 300;
        margin-top: 6px;
      }

      // global server errors

      .form-errors {
        border: 1px solid $red;
        color: $red;
        font-family: $montserrat;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.5;
        margin: 0 0 20px;
        padding: 10px;
        position: relative;

        .dismiss-button {
          background: transparent;
          border: none;
          cursor: pointer;
          font-size: 10px;
          position: absolute;
          right: 5px;
          top: 13px;

          svg {
            fill: $red;
          }
        }
      }

      // input + textarea

      .form-control {
        background-color: #fff;
        border: 1px solid #e8e8e8;
        color: $black;
        font-size: 13px;
        height: 40px;
        padding: 0 13px;

        &::placeholder {
          color: transparentize($black, 0.5);
          font-size: 13px;
        }

        &.form-control-inline {
          display: inline-block;
          width: initial;
        }
      }

      select.form-control {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23999999%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-position: right 1em top 50%, 0 0;
        max-width: 360px;
      }

      // flatpickr

      .flatpickr-wrapper {
        display: inline-block;

        .calendar-filter-date {
          display: block;
          width: 100%;
        }

        .flatpickr-calendar {
          border-radius: 0;
          box-sizing: content-box;
          border: 1px solid #e8e8e8;
          bottom: calc(100% + 0px);
          padding: 12px 11px 12px 12px;
          top: auto;

          &:before,
          &:after {
            display: none;
          }

          .flatpickr-months {
            .flatpickr-month {
              .flatpickr-current-month {
                height: 28px;
                line-height: 28px;
                padding-top: 0;

                .cur-year {
                  position: relative;
                  top: 1px;
                  text-align: center;
                }
              }
            }

            .flatpickr-prev-month {
              left: 8px;
              top: 8px;
            }

            .flatpickr-next-month {
              right: 8px;
              top: 8px;
            }
          }
        }
      }

      // multiselect

      .multiselect {
        color: #272727;
        max-width: 360px;

        * {
          font-size: 13px;
          border-radius: 0;
        }

        .multiselect__tags {
          background-color: #fff;

          .multiselect__placeholder,
          .multiselect__resume {
            position: relative;
            display: inline-block;
            min-height: 20px;
            line-height: 20px;
            border: none;
            background: #fff;
            padding: 0 0 0 5px;
            width: 100%;
            transition: border 0.1s ease;
            box-sizing: border-box;
            margin-bottom: 8px;
            vertical-align: top;
          }

          .multiselect__resume {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .multiselect__single {
            background: transparent;

            .icon {
              font-size: 16px;
              margin-right: 4px;
              position: relative;
              top: 2px;
            }
          }
        }

        .multiselect__content-wrapper {
          .multiselect__content {
            .list-title {
              display: none;
            }
          }
        }

        &.no-placeholder {
          .multiselect__tags {
            .multiselect__placeholder,
            .multiselect__resume {
              bottom: 0;
              left: 9px;
              position: absolute;
              right: 40px;
              top: 9px;
              width: auto;
              z-index: 1;
            }

            .multiselect__placeholder {
              background: transparent;
              z-index: 2;
            }
          }
        }

        .multiselect__option {
          .icon {
            font-size: 16px;
            margin-right: 4px;
            position: relative;
            top: 2px;
          }
        }

        .multiselect__option--highlight {
          background: transparentize($yellow, 0.5);
          color: $black;
        }

        .multiselect__tag {
          background: $yellow;
          color: $black;
          padding: 4px 26px 6px 10px;

          .multiselect__tag-icon {
            line-height: 16px;

            &:after {
              color: $black;
              font-size: 20px;
            }

            &:focus,
            &:hover {
              background: darken($yellow, 5%);
            }
          }
        }
      }

      // custom checkboxes
      // see : https://dev.to/proticm/styling-html-checkboxes-is-super-easy-302o

      .checkbox-custom {
        display: inline-flex;
        cursor: pointer;
        position: relative;
      }

      .checkbox-custom > input {
        height: 16px;
        width: 16px;
        appearance: none;
        border: 1px solid $yellow;
        outline: none;
        transition-duration: 250ms;
        background-color: $white;
        cursor: pointer;
      }

      .checkbox-custom > input:checked + span::before {
        content: '\2713';
        display: block;
        text-align: center;
        color: #272727;
        position: absolute;
        left: 0;
        top: -13px;
        font-size: 26px;
      }

      .checkbox-custom > input:active {
        border: 2px solid darken($yellow, 20%);
      }

      // specific fields

      .form-title {
        input {
          max-width: 360px;
        }
      }

      .form-row-date {
        .form-group {
          display: inline-block;
          margin-bottom: 0;
          margin-right: 6px;
        }

        .form-date {
          > .icon {
            display: inline-block;
            margin-right: 10px;
            position: relative;
            top: 6px;
          }
        }

        .form-time {
          min-width: 120px;
        }

        .form-error {
          margin-left: 28px;
          margin-bottom: 5px;
        }

        .more {
          margin-left: 28px;
          margin-top: 2px;

          .checkbox {
            color: #35495e;
            display: inline-block;
            font-weight: 300;
            font-size: 13px;
            margin-right: 10px;
            text-transform: none;
          }
        }
      }

      .form-row-budget {
        .form-budget {
          > .icon {
            margin-right: 10px;
          }

          .form-control {
            display: inline-block;
          }
        }

        .form-error {
          margin-left: 28px;
        }
      }

      .form-guests {
        input {
          max-width: 120px;
          margin-right: 6px;
        }
      }

      .form-bedding {
        input {
          max-width: 120px;
          margin-right: 6px;
        }
      }

      .form-program,
      .form-layout,
      .form-remarks,
      .form-equipments,
      .form-bedding {
        input[disabled='disabled'],
        textarea[disabled='disabled'] {
          background-color: #f7f7f7;
          border-color: #eee;
        }
      }

      .form-actions {
        clear: both;
        padding-top: 20px;
        text-align: right;

        button {
          display: inline-block;
          float: right;
          margin: 0 10px;

          &.cta-clear {
            float: left;
          }
        }
      }
    }
  }
}
