.page.dashboard {
  .dash-nav {
    .back-link {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 4px;
      @extend .text-1;
      color: $black;
    }
    .pro {
      margin-top: 30px;
      .connect {
        position: relative;
        top: 2px;
        svg {
          color: #555;
        }
      }
      .nav-list {
        &.nav-list-pro {
          ul {
            li {
              a {
                svg {
                  height: 18px;
                  width: 18px;
                  position: relative;
                  top: 2px;
                  margin-right: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.quotation {
    .dash-nav {
      a {
        &.ct {
          width: 100%;
          text-align: center;
          margin-bottom: 6px;
          span {
            margin: auto;
          }
          &.neutral {
            background-color: $dark-beige;
          }
          &.cta {
            background-color: $light-duck;
            span.label-btn {
              color: $white;
            }
          }
          &.admin {
            background-color: $red;
            span.label-btn {
              color: $white;
            }
          }
          span.label-btn {
            text-transform: uppercase;
          }
        }
        &.invoices {
          color: $black;
          text-decoration: underline;
        }
      }
    }
    .event-table {
      a {
        color: $black;
        text-decoration: underline;
      }
    }
    font-family: $rawline;
    .cart-info {
      display: block;
      font-size: 18px;
      margin-top: 10px;
      small {
        font-size: 12px;
        font-style: italic;
      }
    }
    .spacer {
      display: block;
      min-height: 20px;
      height: 20px;
    }
    hr {
      margin: 20px 0;
    }
    table {
      font-size: 14px;
      display: block;
      width: 100%;
      margin-bottom: 40px;
      .item {
        @extend .c9;
      }
      .price {
        text-align: right;
        @extend .c3;
      }
      th,
      td {
        padding: 10px 20px;
        display: table-cell;
      }
      tr {
        width: 100%;
        display: block;
        border-bottom: 1px solid #ddd;
        &:last-of-type,
        &.title-row {
          border-bottom: none;
        }
      }
      tbody {
        width: 100%;
        display: block;
        th {
          text-align: left;
          font-size: 16px;
          background: $light-beige;
          @extend .c12;
        }
      }
      tfoot {
        width: 100%;
        display: block;
        background: $light-beige;
        tr {
          border-bottom: none;
        }
        .item {
          text-align: right;
        }
      }
    }
  }
}
.page.payment {
  .code-of-conduct {
    font-family: $rawline;
    font-size: 16px;
    line-height: 1.62;
    background-color: #fff8f0;
    padding: 20px;
    max-height: 500px;
    min-height: 500px;
    height: 500px;
    overflow-y: scroll;
    p {
      margin: 10px 0 10px 20px;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin: 20px 20px 20px 0;
    }
  }
  .label-check {
    margin: 14px 0;
  }
  .bpa-preparation {
    margin: 120px 0 200px;
    h2 {
      text-align: center;
    }
    .loading {
      min-height: 120px;
      text-align: center;
      img {
        margin-top: 40px;
        height: 40px;
      }
    }
    p {
      font-size: 16px;
      margin-top: 30px;
      text-align: center;
    }
  }
  .payment-choice {
    min-height: 200px;
    p {
      margin-bottom: 20px;
      img {
        margin-top: 20px;
        height: 40px;
      }
    }
    .stripe-button-el {
      border: 0;
      border-radius: 0;
      span {
        @extend .btn;
        @extend .btn-info;
        margin: 0;
        border: 0;
      }
    }
  }
}
