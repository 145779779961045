@import 'navbar-account';

.ct.ct--navbar-account {
  position: fixed;
  width: 100%;
  height: 80px;
  background: $black;
  color: $white;
  top: 0;
  display: flex;
  padding: 0 25px;
  align-items: center;
  z-index: 100;
  justify-content: space-between;

  .logo {
    margin-right: 50px;
    .icon {
      width: 212px;
      height: 47px;
      fill: $white;
    }
  }
  .items {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: auto;

    li {
      height: 100%;
      margin-right: 20px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
      a {
        position: relative;
        display: flex;
        align-items: center;
        color: $white;
        text-transform: uppercase;
        height: 100%;
        &.active {
          &:before {
            opacity: 1;
          }
        }
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 5px;
          background: $yellow;
          bottom: 0;
          left: 0;
          opacity: 0;
        }
        .icon {
          fill: $white;
          width: 15px;
          height: 24px;
          margin-right: 10px;
        }
        &.entertainment {
          .icon {
            width: 24px;
          }
        }
        &.drink {
          .icon {
            width: 19px;
          }
        }
        &.staff {
          .icon {
            width: 24px;
          }
        }
      }
    }
  }
  .sub-list-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    .menuburger-my-account {
      width: 18px;
      height: 18px;
      position: relative;
      display: inline-block;
      margin-right: 15px;

      .account-sub-navbar {
        position: relative;
        width: 18px;
        height: 18px;
        background-color: transparent;
        display: inline-block;
        z-index: 10;
        cursor: pointer;

        .icon {
          color: $yellow;
          fill: $yellow;
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
    }

    .name-account {
      color: $yellow;
      font-size: 1.3rem;
      text-transform: uppercase;
      display: inline-block;
      font-family: 'Rawline';
      font-weight: 700;
      margin-right: 30px;
    }

    .menuburger-icon {
      position: relative;
      width: 15px;
      height: 15px;
      background-color: black;
      display: inline-block;
      z-index: 5;
      cursor: pointer;

      .icon {
        color: $yellow;
        stroke: $yellow;
        fill: $yellow;
        width: 15px;
        height: 15px;
        vertical-align: middle;
      }
    }
  }
}
