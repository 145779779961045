@import 'transmission-request';
.page.user.transmission-request {
  .top-bar-return {
    .back {
      margin-right: 40px;
    }
  }
  .content-transmission {
    max-width: 630px;
    padding: 0 30px;
    .title-content {
      font-size: 3.5rem;
    }
    .text-content {
      font-size: 1.5rem;
      line-height: 22px;
      margin: 40px 0 50px;
    }
  }
  .content-cgu {
    @extend .c8;
  }
}
