/*********************
Styles
*********************/

@mixin antialiased() {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin hidden() {
  visibility: hidden;
}

@mixin hidden-alpha() {
  visibility: hidden;
  opacity: 0;
}

@mixin full-relative() {
  position: relative;
  width: 100%;
  height: 100%;
}

@mixin full-absolute() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin full-fixed() {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin center-x() {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0%, 0);
}

@mixin center-y() {
  position: absolute;
  top: 50%;
  transform: translate3d(0%, -50%, 0);
}

@mixin center-xy() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@mixin center-flex-x() {
  display: flex;
  justify-content: center;
}

@mixin center-flex-y() {
  display: flex;
  align-items: center;
}

@mixin center-flex-xy() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin no-select() {
  cursor: default;
  user-select: none;
  -webkit-touch-callout: none;
}

/*********************
Fonts
*********************/
@function file($path, $name, $extension) {
  @return $path + '/' + $name + '.' + $extension;
}

@mixin font-face($family, $path, $font, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url(file($path, $font, 'eot'));
    src: url(file($path, $font, 'eot?#iefix')) format('embedded-opentype'),
      url(file($path, $font, 'ttf')) format('truetype'),
      url(file($path, $font, 'svg')) format('svg');

    font-weight: $weight;
    font-style: $style;
  }
}

/*********************
Animations
*********************/

@mixin animatedSprite($spriteUrl, $width, $height, $nbFrames, $time, $yoyo) {
  background-image: url($spriteUrl);
  background-repeat: no-repeat;
  background-size: $width;

  @if $yoyo {
    animation: reverseAnimatedSprite $time steps($nbFrames - 1);
    animation-direction: reverse;
  }

  &:hover {
    animation: playAnimatedSprite $time steps($nbFrames - 1);
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes reverseAnimatedSprite {
    100% {
      background-position: 0 #{-$height * ($nbFrames - 1)};
    }
  }
  @keyframes playAnimatedSprite {
    100% {
      background-position: 0 #{-$height * ($nbFrames - 1)};
    }
  }
}

/*********************
Buttons
*********************/

@mixin ct--button-action(
  $backgroundColor: $yellow,
  $color: $bobby-brown,
  $operation: darken
) {
  $hoverColor: darken($color, 10%);
  $hoverBackground: darken($backgroundColor, 6%);
  @if ($operation == lighten) {
    $hoverColor: lighten($color, 10%);
    $hoverBackground: lighten($backgroundColor, 6%);
  }
  color: $color;
  background-color: $backgroundColor;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  span {
    color: $color;
    transition: color 0.15s ease-in-out;
  }
  svg {
    fill: $color;
    transition: fill 0.15s ease-in-out;
  }
  &:hover {
    $hoverColor: $hoverColor;
    $hoverBackground: $hoverBackground;
    color: $hoverColor;
    background-color: $hoverBackground;
    span {
      color: $hoverColor;
    }
    svg {
      fill: $hoverColor;
    }
  }
}
