@import '../../variables/fonts';

.page.seminaire-landing {
  margin-bottom: 0;

  .blocks-container {
    > .content-block:last-of-type {
      margin-bottom: 0;
      padding-bottom: 100px;
    }
  }

  .row-form {
    margin: 100px 0 0;

    #formulaire {
      position: relative;
      top: -120px;
    }

    .row-form-content {
      padding-top: 40px;
      vertical-align: top;

      .row-form-content-title {
        line-height: 1.1;
        margin-bottom: 30px;
      }

      p {
        @extend .text-1;
        margin-bottom: 30px;
      }
    }

    .row-form-form {
      vertical-align: top;

      .hs-form {
        box-shadow: #ddd 2px 2px 8px;
        background-color: #fff;
        padding: 40px;
      }
    }
  }

  .linked-pages-block {
    margin: 75px 0;

    .splide {
      .splide__slide {
        margin-bottom: 0;

        > a {
          display: block;
          width: 100%;
          height: 400px;
          background: transparent center center no-repeat;
          background-size: cover;
          color: #fff;
          text-decoration: none;

          &::before {
            background-color: rgba(55, 55, 55, 0.55);
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            transition: background-color 0.2s ease;
          }

          &:hover:before {
            background-color: rgba(55, 55, 55, 0.7);
          }

          .splide__caption {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 100%;
            width: 100%;
            padding: 20px;
            z-index: 2;
            @extend .title-2;
            line-height: 1.3;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }

      .splide__arrow {
        background: transparent;
        width: 30px;
        height: 30px;

        &.splide__arrow--prev {
          left: 10px;
        }

        &.splide__arrow--next {
          right: 10px;
        }

        svg {
          fill: #fff;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
