@import 'about-us';

.page.about-us {
  &.rent,
  &.caterer {
    p {
      a {
        color: black;
        text-decoration: underline;
      }
    }
    .text-unique-places-about {
      p {
        &.sub-paragraph {
          margin-bottom: 40px !important;
        }
      }
    }
  }

  .container {
    .service-title-about {
      margin-top: 120px;

      .title-service-row-about {
        color: $yellow;
        h2 {
          text-align: center;
          margin-bottom: 60px;
        }
        .title-service-row-about {
          float: right;
          &.title-rent-right {
            text-align: right;
          }
        }
      }
      &.service-title-rent,
      &.service-title-caterer {
        margin-top: 50px;
      }
      &.service-title-caterer {
        .title-service-row-about h2.title-service-row-about {
          margin-left: 50px;
        }
      }
    }

    .content-service-about {
      margin-top: 10px;
      display: flex;
      align-items: center;

      .picture-1-about {
        width: 100%;
        img {
          width: 100%;
        }
      }

      .text-content-service-about {
        vertical-align: top;

        .title-text-content-service-about {
          margin-bottom: 40px;
        }

        strong {
          font-weight: 900;
        }

        &.text-content-service-rent {
          p.text-1 {
            margin-bottom: 30px;
            text-align: justify;
          }
        }
      }
    }

    .unique-places-about {
      margin-top: 190px;
      text-align: center;

      .title-unique-places-about {
        color: $yellow;
      }

      .text-unique-places-about {
        margin-top: 70px;
        text-align: left;

        .title-text-unique-places-about {
          margin-bottom: 40px;
        }
        &.text-unique-places-about {
          p.text-1 {
            margin-bottom: 20px;
          }
        }
      }
      &.places-rent-up {
        margin-top: 0;
        p.text-1 {
          margin-bottom: 40px;
          text-align: justify;
        }
        p.text-1:last-of-type {
          margin-bottom: 80px;
        }
        h3.title-1 {
          margin-bottom: 30px;
        }
      }
    }

    .unique-places-list-about {
      a {
        color: $black;
        .img-wrapper-list:hover {
          opacity: 0.7;
        }
      }
      margin-top: 80px;

      .image-unique-places-list-about {
        margin: 0 auto;
        width: auto;
        max-width: span(8.5 of 12);
        &.rent-venue-list {
          margin-bottom: 200px;
          max-width: span(10 of 12);
          .list-unique-places-list-about {
            .list-element-unique-places-list-about {
              width: calc((100% - 45px) / 4);
            }
          }
        }
        .list-unique-places-list-about {
          text-align: center;
          .list-element-unique-places-list-about {
            position: relative;
            vertical-align: top;
            display: inline-block;
            margin: 0 5px;
            text-align: left;
            width: calc((100% - 30px) / 3);

            .img-wrapper-list {
              overflow: hidden;
              position: relative;
              vertical-align: top;
              margin-bottom: 15px;

              img {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              &:before {
                content: '';
                width: 100%;
                height: 0;
                display: block;
                padding-bottom: 117%;
              }
            }

            .text-content-unique-places-list-about {
              font-weight: 100;
              margin-bottom: -40px;
              text-align: center;
            }
          }
        }
      }

      .button-unique-places-list-about {
        margin: 80px auto 140px;
        text-align: center;
      }
    }

    .custom-design-about {
      padding-top: 160px;
      background-color: $light-beige;
      padding-bottom: 90px;

      .title-custom-design-about {
        color: $yellow;
        text-align: center;
        margin-bottom: 55px;
      }

      .column-text-custom-design-about {
        text-align: left;

        .title-text-custom-design-about {
          margin-bottom: 35px;
          margin-top: 80px;
          max-width: span(4 of 5);
        }

        .text-content-custom-design-about {
          max-width: 440px;
          margin-bottom: 40px;

          strong {
            font-weight: 900;
          }
        }

        .button-custom-design-about {
          width: auto;
          text-align: center;
          display: inline-block;
        }
        &.column-text-custom-design-rent {
          p.text-1 {
            text-align: justify;
          }
        }
      }

      .column-img-custom-design-about {
        vertical-align: top;

        .image-place-about {
          position: relative;
          z-index: 2;

          &:before {
            position: absolute;
            content: '';
            z-index: 1;
            width: span(4 of 5);
            height: span(4 of 5);
            bottom: -140px;
            right: -span(1 of 5);
            display: block;
            background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
          }

          &:after {
            content: '';
            width: 100%;
            height: 0;
            display: block;
            padding-bottom: 100%;
          }

          .element-after-image-place-about {
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            top: 0;

            img {
              position: absolute;
              min-width: 100%;
              min-height: 100%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    .dedicated-support-about {
      padding-top: 90px;
      padding-bottom: 150px;
      background-color: $light-beige;

      .title-dedicated {
        @media screen and (max-width: 1100px) {
          margin: 0;
          width: 100%;
          text-align: center;
        }
      }

      .title-strat-about {
        margin-top: 100px;
        color: $yellow;
        margin-bottom: 80px;
      }

      .image-place-dedicated-support-about {
        vertical-align: top;
        padding-top: 50px;

        img {
          width: 100%;
        }
      }

      .text-dedicated-support-about {
        vertical-align: top;

        .text-place-dedicated-support-about {
          margin-left: span(1 of 7);

          .title-text-dedicated-support-about {
            margin-bottom: 35px;
            max-width: span(6 of 7);
          }

          .text-content-dedicated-support-about {
            max-width: span(4 of 7);
            margin-bottom: 20px;
            &:last-of-type {
              margin-bottom: 35px;
            }
            strong {
              font-weight: 900;
            }
          }
          &.text-place-dedicated-support-rent {
            p {
              &.text-content-dedicated-support-about {
                margin-bottom: 20px;
                &:last-of-type {
                  margin-bottom: 35px;
                }
              }
            }
          }
        }

        .image-place-in-text-place-about {
          width: span(5 of 7);

          img {
            display: inline-block;
            width: span(2.5 of 5);
          }

          &.single {
            img {
              max-width: 100%;
              width: auto;
            }
          }
        }
      }
    }

    .new-concept-about {
      padding-top: 200px;
      padding-bottom: 190px;
      background-color: $white;
      text-align: center;
      background-image: url('../../../../../../web/medias/img/desktop/pages/about-us/about-bg.png');
      background-repeat: no-repeat;
      background-position: center center;

      .text-new-concept-about {
        text-align: center;

        .title-text-new-concept-about {
          max-width: span(9 of 12);
          display: block;
          margin: 0 auto 40px;
        }

        .text-content-new-concept-about {
          max-width: 540px;
          display: block;
          margin: 0 auto 40px;
        }

        .button-new-concept-about {
          width: auto;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
}
