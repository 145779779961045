.ct.ct--slider-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  .inner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
    .slide {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      max-width: 120%;
      max-height: 120%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      background-size: cover;
      background-position: center;
      &:nth-child(1) {
        opacity: 1;
        visibility: visible;
      }
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90px;
        z-index: 3;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.65) 100%
        );
      }
      .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        padding: 20px 40px;
        text-align: center;
        font-family: $rawline;
        font-size: 1.5rem;
        color: #fff;
      }
      .download-link {
        position: absolute;
        top: 10px;
        right: 20px;
        color: $white;
        background-color: transparent;
        border: 1px solid $white;
        padding: 7px 15px 10px;
        margin-left: 15px;
        @extend .rawline-regular;
        font-size: 1.3rem;
      }
    }
  }
  .bullets {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    li {
      width: 9px;
      height: 9px;
      background: rgba(255, 255, 255, 0.2);
      display: inline-block;
      border-radius: 50%;
      margin: 0 1px;
      cursor: pointer;
      &.active {
        background: rgba(255, 255, 255, 1);
      }
    }
  }
  .ui-slider-item {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 5;
    .btn-slider-item {
      position: relative;
      color: $white;
      background-color: transparent;
      border: 1px solid $white;
      padding: 7px 15px 10px;
      margin-left: 15px;
      @extend .rawline-regular;
      font-size: 1.3rem;
      line-height: initial;
      cursor: pointer;
      transition: background-color 0.15s ease-in-out;
      opacity: 1;
      &.hidden {
        display: none;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  .arrow {
    position: absolute;
    z-index: 5;
    background: none;
    border: 0;
    top: 0;
    width: 50px;
    height: 100%;
    -webkit-appearance: none;
    cursor: pointer;
    &:hover {
      .icon {
        transform: translateX(5px);
      }
    }
    &.left {
      transform: rotate(180deg);
      left: 0;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
    &.right {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
    .icon {
      width: 15px;
      height: 26px;
      fill: $white;
      transform: translateX(0);
      transition: transform 250ms $ease-out-quad;
    }
  }
}

.popins-ui-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
  opacity: 0;
  visibility: hidden;
  .popin-ui {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    .close-popin {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 10;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;
        fill: $white;
        transform: scale(0.75);
        transition: transform 250ms $ease-out-quad;
      }
      &:hover {
        .icon {
          transform: scale(1);
        }
      }
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 44%;
    }

    .wrapper-3d {
      position: absolute;
      color: $white;
      font-size: 50px;
      text-align: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(10, 20, 30, 0.65);
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
