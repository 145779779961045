@import '../../variables/colors';

.menuburger {
  position: absolute;
  background-color: $light-beige;
  right: -480px;
  top: 0;
  text-align: center;
  color: $medium-grey;
  padding: 25px 0% 50px;
  max-width: 480px;
  height: 100vh;
  transition: right 600ms ease-in-out;
  z-index: 20;

  &.show {
    right: 0;
  }

  .close-menuburger {
    position: absolute;
    top: 27px;
    right: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    .close {
      position: relative;
      width: 20px;
      height: 20px;
      background-color: transparent;
      display: inline-block;
      margin-bottom: 0;
      z-index: 10;

      .icon {
        color: $black;
        fill: $black;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }

  .menuburger-title {
    text-transform: uppercase;
    margin-bottom: 30px;
    display: block;
  }

  .menuburger-content {
    border-top: solid 1px $dark-beige;
    margin: 26px 0 30px;

    .menuburger-list-content {
      margin-top: 24px;
      margin-bottom: 24px;
      cursor: default;

      a {
        color: $black;
        transition: color 600ms ease-in-out;
        &:hover {
          color: $yellow;
        }
        &.blue-link,
        &.blue-link:hover {
          color: $light-duck-accessible;
        }
      }

      .arrow {
        width: 7px;
        height: 7px;

        .icon {
          margin-left: 10px;
          color: $black;
          fill: $black;
          stroke: $black;
          width: 7px;
          height: 7px;
          vertical-align: middle;
        }
      }

      .menuburger-underlist {
        height: 0;
        transition: height 600ms ease-in-out;
        transform-origin: 100% 0;
        overflow: hidden;
      }

      &:hover {
        .menuburger-underlist {
          height: 220px;
        }
      }
    }
  }

  .menuburger-box {
    padding: 0;
    border-top: 1px solid $dark-beige;
    margin: 26px 0 30px;

    .menuburger-rent {
      margin-top: 30px;
      color: $black;
      padding: 30px 0;

      a {
        color: $black;
        transition: color 600ms ease-in-out;

        &:hover {
          color: $yellow;
        }
      }
    }

    .menuburger-sitemap {
      padding-top: 35px;
      border-bottom: 1px solid $dark-beige;
      padding-bottom: 16px;

      .list-sitemap {
        display: inline-block;
        text-align: left;

        &:first-of-type {
          margin-right: 50px;
        }

        .menuburger-list-sitemap {
          margin-bottom: 20px;
          font-weight: 600;
          a {
            color: $black;
            transition: color 600ms ease-in-out;

            &:hover {
              color: $yellow;
            }
          }
        }
      }
    }

    .menuburger-advise {
      text-align: left;
      padding-top: 30px;

      .menuburger-advise-left {
        display: inline-block;
        width: 50%;
        padding-left: 50px;

        .contact-link {
          font-weight: 600;
          line-height: 50px;
          color: $black;
          transition: color 600ms ease-in-out;
          &:hover {
            color: $yellow;
          }
        }

        .menuburger-subtitle {
          margin-bottom: 10px;
          display: block;
        }

        .menuburger-text {
          margin-bottom: 20px;
        }

        .phone {
          position: relative;
          width: 10px;
          height: 10px;
          background-color: transparent;
          display: inline-block;
          margin-bottom: 0;
          z-index: 10;
          margin-right: 15px;
          cursor: pointer;

          .icon {
            color: $black;
            fill: $black;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }

        .phone-number {
          display: inline-block;
          font-weight: 600;
          font-size: 1.6rem;
          font-family: $rawline;
        }
      }

      .menuburger-rs {
        height: 100%;
        display: inline-block;
        vertical-align: top;
        padding-left: 8%;

        .facebook {
          position: relative;
          width: 20px;
          height: 20px;
          background-color: transparent;
          display: inline-block;
          margin-bottom: 0;
          z-index: 10;
          margin-right: 20px;
          cursor: pointer;

          .icon {
            color: $black;
            fill: $black;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }

        .instagram {
          position: relative;
          width: 20px;
          height: 20px;
          background-color: transparent;
          display: inline-block;
          margin-bottom: 0;
          z-index: 10;
          margin-right: 20px;
          cursor: pointer;

          .icon {
            color: $black;
            fill: $black;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }

        .twitter {
          position: relative;
          width: 20px;
          height: 20px;
          background-color: transparent;
          display: inline-block;
          margin-bottom: 0;
          z-index: 10;
          margin-right: 0;
          cursor: pointer;

          .icon {
            color: $black;
            fill: $black;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }
      }

      .menuburger-utils {
        margin-top: 30px;
        color: $black;
        padding: 30px 0;
        border-top: 1px solid $dark-beige;
        text-align: center;
        a {
          color: $black;
          transition: color 600ms ease-in-out;
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }
}
