@import 'tos';

.page.tos {
  position: relative;
  padding-top: 40px;
  padding-bottom: 80px;
  text-align: center;
  background-position: right top;
  background-repeat: no-repeat;
  background-image: url('../../../../../../web/medias/img/desktop/pages/faq/faq-bg-split.png');
  background-size: 426px 840px;
  .container {
    @extend .c8;
  }
  .container-tos {
    @extend .c8;
    padding-top: 160px;
  }
  .ct--breadcrumb {
    position: absolute;
    top: 140px;
    left: 25px;
    text-align: left;
    width: 100%;
  }
}
