.page.payment {
  min-height: calc(100vh - 75px);
  .ct--breadcrumb {
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 40px;
  }
  .row-fiche {
    margin-bottom: 60px;
    & > p {
      margin-bottom: 40px;
    }
  }
  .about p {
    text-align: center;
    font-size: 12px;
    display: block;
    margin-top: 20px;
    width: 100%;
  }
  .payment-choice {
    text-align: left;
    vertical-align: top;
    h4 {
      color: $yellow;
      text-transform: uppercase;
      font-size: 26px;
      display: inline-block;
      margin-bottom: 20px;
    }
    p.text-2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }
    p.text-1 {
      font-size: 14px;
      &.offline-spacer {
        margin-bottom: 188px;
      }
    }
    .cta {
      min-width: 318px;
      text-align: center;
    }
    .cta-clear {
      border: 2px solid $yellow;
    }
  }
}

.page.bpa {
  min-height: calc(100vh - 75px);
  .quotation-preview {
    width: 100%;
    min-height: 400px;
    box-shadow: 0 0 10px #ccc;
    margin: 40px 0 10px;
  }
  .quotation-download-link {
    float: right;
    color: $light-duck;
    &:hover,
    &:active {
      color: $light-duck;
    }
    .icon {
      color: $light-duck;
      stroke: $light-duck;
    }
  }
  form {
    margin-bottom: 40px;
  }
  label {
    margin-left: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: $rawline;
    font-size: 14px;
    line-height: 16px;
    input[type='checkbox'] {
      margin-left: -18px;
      margin-right: 2px;
    }
  }
  .about p {
    text-align: center;
    font-size: 12px;
    display: block;
    margin-top: 20px;
    width: 100%;
  }
}
