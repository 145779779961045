@import 'instagram-net';

.ct--instagram-net {
  background-color: $light-beige;
  padding-top: 127px;
  margin-bottom: 200px;

  .instagram-title {
    color: $yellow;
    text-transform: uppercase;
    margin-bottom: 51px;
  }

  .instagram-col-pic {
    vertical-align: middle;
    text-align: center;
    display: inline-block;

    .image-place-col-pic {
      position: relative;
      z-index: 2;

      &:before {
        position: absolute;
        content: '';
        z-index: 1;
        width: span(4 of 5);
        height: span(4 of 5);
        bottom: -140px;
        right: span(2 of 5);
        display: block;
        background-color: $light-beige;
        background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
      }

      .image-wrapper {
        position: relative;
        z-index: 3;

        .image-element {
          width: calc(100% / 3);
          display: inline-block;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .instagram-col-text {
    display: inline-block;
    vertical-align: middle;

    .subtitle-instagram {
      margin-bottom: 30px;
    }

    .text-instagram {
      margin-bottom: 50px;
      max-width: 350px;
    }

    .list-instagram {
      .element-list-instagram {
        display: inline-block;
        margin-right: 20px;

        .icon {
          color: $yellow;
          fill: $yellow;
          width: 21px;
          height: 21px;
          display: inline-block;
        }
      }
    }
  }
}
