.ct.ct--item-result {
  display: block;
  position: relative;
  cursor: pointer;
  z-index: 1;
  border: 5px solid transparent;
  color: inherit;

  > .about {
    color: $black;
    background: transparent;
    transition: background 250ms $ease-out-quad;
  }

  &.selected {
    border: 5px solid $yellow;
    &:before {
      position: absolute;
      content: '';
      z-index: 6;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 50px 50px 0;
      border-color: transparent $yellow transparent transparent;
    }
    &:after {
      position: absolute;
      content: '';
      z-index: 10;
      background: url('../../../../../../web/medias/img/generics/svg/home_recherche_coche.svg')
        no-repeat center center;
      width: 10px;
      height: 10px;
      right: 7px;
      top: 7px;
    }
    &.drink,
    &.furniture,
    &.glass,
    &.staff {
      .product {
        border: 1px solid transparent;
        border-bottom: 1px solid $medium-grey;
      }
    }
  }
  .restricted {
    background: rgba(255, 200, 200, 0.8);
    padding: 6px 12px;
    font-size: 15px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
  .right-corner-indicator {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin: {
      top: 10px;
      right: 10px;
    }
  }
  &.is-promoted {
    .about {
      color: $light-duck;
      .title-item-result {
        color: $light-duck !important;
        font-weight: 500;
      }
      .icon {
        fill: $light-duck;
      }
    }
    .angle-promoted {
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      border-top: 80px solid $light-duck;
      border-right: 80px solid transparent;
      z-index: 5;
    }
    .exclusive {
      position: absolute;
      z-index: 5;
      color: white;
      top: 19px;
      left: 2px;
      transform: rotate(-45deg);
      font-family: $montserrat;
    }
    .svg-promoted {
      position: absolute;
      top: 8px;
      left: 9px;
      stroke: #fff;
      fill: #fff;
      width: 30px;
      height: 30px;
      z-index: 6;
    }
  }
  &.surplus-management {
    .angle-surplus {
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      border-top: 80px solid $light-duck;
      border-right: 80px solid transparent;
      z-index: 5;
    }
    .svg-surplus {
      position: absolute;
      top: -70px;
      left: 9px;
      stroke: #fff;
      fill: #fff;
      width: 30px;
      height: 30px;
      z-index: 6;
    }
  }
  @media screen and (min-width: 1025px) {
    &:hover,
    &.hover-map {
      > .about {
        background: $medium-grey;
        color: $white;
        .headline {
          .icon {
            fill: $white;
          }
        }
        .title-item-result {
          color: $white !important;
        }
        .quantity {
          svg {
            fill: $white;
          }
        }
        .ct--control-quantity {
          .value {
            color: $black;
          }
        }
      }
      .ui-slider {
        opacity: 1;
        transition: opacity 250ms $ease-out-quad;
      }
    }
  }

  .ui-slider {
    opacity: 0;
  }
  &.dashboard {
    .remove-item {
      position: absolute;
      right: 0;
      top: 0;
      background: $yellow;
      border: 0;
      box-shadow: 0;
      width: 50px;
      height: 50px;
      cursor: pointer;
      opacity: 0;
      z-index: 5;
      transition: opacity 250ms $ease-out-quad;
      .icon {
        width: 16px;
        height: 17px;
      }
    }
    &:hover {
      .pictures {
        &:after {
          opacity: 1;
        }
      }
      .remove-item {
        opacity: 1;
      }
    }
  }
  &.place,
  &.dashboard {
    .pictures {
      &:before {
        padding-bottom: 72%;
      }
    }
  }
  &.menu,
  &.cocktail {
    .pictures {
      &:before {
        padding-bottom: 57%;
      }
    }
  }
  &.place,
  &.menu,
  &.cocktail,
  &.dashboard {
    .pictures {
      position: relative;
      overflow: hidden;
      background: $black;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 0%;
      }
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        z-index: 3;
        opacity: 0;
        transition: opacity 250ms $ease-out-quad;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.65) 100%
        );
      }
      .picture {
        // position: absolute;
        // min-width: 101%;
        // min-height: 101%;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
        background-size: 101% auto;
        background-position: center center;
        background-repeat: no-repeat;
        &:first-child {
          z-index: 2;
          opacity: 1;
        }
        &.picture-btn {
          border: none;
          padding: 0;
          .play {
            position: absolute;
            z-index: 3;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .play-icon {
              background: #f5f4f3;
              padding: 15px;
              border-radius: 50%;
              height: 65px;
              width: 65px;
              margin-bottom: 20px;
              .icon {
                color: #222;
                font-size: 17px;
                width: 2em;
                height: 2em;
                padding-left: 8px;
              }
            }
            .play-text {
              @extend .title-2;
              font-size: 18px;
              font-weight: 300;
            }
            .play-icon,
            .play-text {
              color: #fff;
              z-index: 2;
            }
            &::before {
              background-color: rgba(85, 85, 85, 0.75);
              content: '';
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 2;
            }
          }
        }
      }
      .bullets {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        li {
          width: 9px;
          height: 9px;
          background: rgba(255, 255, 255, 0.2);
          display: inline-block;
          border-radius: 50%;
          margin: 0 1px;
          cursor: pointer;
          &.active {
            background: rgba(255, 255, 255, 1);
          }
        }
      }
      .ui-slider-item {
        position: absolute;
        bottom: 25px;
        right: 25px;
        z-index: 5;
        .btn-slider-item {
          position: relative;
          color: $white;
          border: 1px solid $white;
          padding: 7px 15px;
          margin-left: 15px;
          @extend .rawline-regular;
          font-size: 1.3rem;
        }
      }
      .arrow {
        position: absolute;
        z-index: 5;
        background: none;
        border: 0;
        top: 0;
        width: 50px;
        height: 100%;
        -webkit-appearance: none;
        cursor: pointer;
        // when element is a simple div, not a button
        display: flex;
        align-items: center;
        justify-content: center;
        &.left {
          transform: rotate(180deg);
          left: 0;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        &.right {
          right: 0;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
        }
        .icon {
          width: 15px;
          height: 26px;
          fill: $white;
        }
      }

      .rating {
        bottom: 20px;
        left: 10px;
        padding: 0 8px;
        position: absolute;
        z-index: 6;

        span {
          @extend .rawline-regular;
          color: #fff;
          font-size: 1.4rem;
          font-weight: bold;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.85);
        }

        svg {
          color: $yellow;
          display: inline-block;
          fill: $yellow;
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.4));
          height: 20px;
          margin-left: 1px;
          position: relative;
          top: 3px;
          width: 20px;
        }
      }

      .badges {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 6;

        .badge {
          @extend .rawline-regular;
          font-size: 1.4rem;
          display: inline-block;
          padding: 2px 8px 4px;

          &.badge-new {
            background-color: $yellow;
            .icon {
              margin-left: -4px;
              margin-bottom: -2px;
            }
          }

          &.badge-3d {
            background-color: transparentize($black, 0.5);
            color: $white;
          }

          &.badge-rse {
            background-color: $light-duck;
            color: $white;
            .icon {
              fill: $white;
              margin-left: -4px;
              margin-bottom: -4px;
              margin-top: -4px;
              height: 18px;
              width: 18px;
            }
          }

          &.badge-warning {
            background-color: $red;
            color: $white;
          }
        }
      }
    }

    .about {
      padding: 20px;
      .headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .leading {
          sup {
            text-transform: initial;
          }
        }
      }
      .quantity {
        display: flex;
        align-items: center;
        float: right;
        @extend .rawline-regular;
        font-size: 12px;
        .icon {
          margin-left: 7px;
          &.svg-listes_encart_people {
            width: 18px;
            height: 14px;
          }
        }
      }
      .title-item-result {
        margin-bottom: 5px;
        display: block;
        color: $black;
      }
    }
  }
  &.furniture,
  &.staff {
    .about {
      .cols {
        min-height: 71px;
      }
    }
  }
  // &.drink {
  // 	.product {
  // 		min-height: calc(100% - 110px);
  // 	}
  // 	.about {
  // 		height: 110px;
  // 	}
  // }
  &.drink,
  &.glass,
  &.furniture,
  &.staff {
    display: flex;
    flex-direction: column;
    .product {
      position: relative;
      border: 1px solid $dark-beige;
      padding: 25px 40px;
      flex-grow: 1;
      .cols {
        display: flex;
        align-items: center;
        min-height: 130px;
        margin-bottom: 20px;
        .pic {
          position: relative;
          width: 130px;
          text-align: center;
          overflow: hidden;
          &:before {
            content: '';
            width: 100%;
            display: block;
            padding-bottom: 110%;
            transition: all 250ms $ease-out-quad;
          }
          img {
            position: absolute;
            vertical-align: top;
            display: inline-block;
            max-height: 100%;
            //max-width: 100%;
            top: 50%;
            left: 50%;
            opacity: 1;
            transition: transform 250ms $ease-out-quad,
              opacity 250ms $ease-out-quad;
            transform-origin: center center;
            transform: translate(-50%, -50%);
          }
        }
        .right {
          display: inline-block;
          width: calc(100% - 150px);
          margin-left: 20px;
          .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: uppercase;
            margin-bottom: 10px;
            .region {
              display: flex;
              align-items: center;
              .icon {
                width: 17px;
                height: 18px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .about {
      padding: 20px;
      .cols {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .col {
          .unity,
          .price {
            display: block;
            text-transform: uppercase;
          }
          .unity {
            margin-bottom: 5px;
          }
          .title-filter {
            text-transform: uppercase;
            display: block;
            margin-bottom: 10px;
          }
          &.add {
            display: flex;
            align-items: flex-end;
            .time,
            .quantity {
              margin-right: 10px;
            }
            .btn-add-card {
              width: 140px;
              height: 50px;
              border: 0;
              background: $yellow;
              @include ct--button-action;
            }
          }
        }
      }
      .more {
        margin-top: 10px;
      }
    }
  }

  .choose-option {
    display: flex;
    border: 1px solid #ccc;
    width: 160px;
    height: 50px;
    overflow: hidden;
    background: $white;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    select {
      padding: 5px 50px 5px 20px;
      width: 100%;
      height: 100%;
      border: none;
      box-shadow: none;
      background: transparent;
      background-image: none;
      -webkit-appearance: none;
      position: relative;
      z-index: 5;
      // box-sizing:
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    select::-ms-expand {
      display: none;
    }
    .cursor {
      display: flex;
      width: 50px;
      height: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      .icon {
        width: 8px;
        height: 5px;
      }
    }
  }
}
