.ct.ct--double-col {
  text-align: left;
  margin-bottom: 115px;

  &.headline {
    .container {
      .cols {
        .col {
          &.col-content {
            .inner {
              .title-double-col {
                margin-top: 40px;
              }

              .section-title-double-col {
                margin-top: -10px;
                margin-bottom: -10px;
                line-height: 1.2;
                font-size: 1.8rem;
                font-family: $rawline;
                font-weight: normal;
                font-style: normal;
              }
            }
          }
        }
      }
    }
  }

  .container {
    .cols {
      .headline {
        color: $yellow;
        z-index: 10;
      }

      .col {
        &.col-content {
          display: flex;
          align-items: center;

          .inner {
            position: relative;

            .content-double-col {
              margin: 20px 0 40px;
            }
          }
        }

        &.col-picture {
          aspect-ratio: 1;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
