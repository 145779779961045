@import './404';

body {
  main {
    .page.error._404,
    .page.error.autoconnect {
      background-size: 80%;
      padding-top: 92px;

      > .content {
        padding: 50px 0;

        .container-big-number {
          height: 100px;
          margin-bottom: 30px;

          .big-number {
            font-size: 16rem;
          }
        }

        .title-error {
          font-size: 1.8rem;
        }

        .text-error {
          font-size: 1.5rem;
          margin: 30px 0 50px;
        }

        .links-error {
          margin: 50px auto 20px;

          .links-error-row {
            display: flex;
            min-width: 75vw;
            max-width: 1440px;

            > .ct {
              flex: 1 1 0;
              width: 0;
              margin: 5px;

              .about,
              .content {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
