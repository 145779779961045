@mixin dropdown-toggle($top: 20px) {
  content: '';
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: $top;
  margin-top: -2.5px;
  pointer-events: none;
}
