@import '../../variables/fonts';

.category-slider-wrapper {
  margin: 20px 50px 0;

  .category-slider-title {
    font-family: $montserrat;
    font-weight: 300;
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.2;
    padding: 0 15%;
  }

  .category-slider {
    margin-bottom: 90px;

    .category-slide {
      .category-slide-link-wrapper {
        background: transparent center center no-repeat;
        background-size: cover;
        color: #fff;
        text-align: center;
        width: 100%;
        height: 400px;
        display: block;
        padding: 120px 0;

        &::before {
          background-color: rgba(55, 55, 55, 0.55);
          content: '';
          display: block;
          flex: none;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          transition: background-color 0.2s ease;
        }

        &:hover:before {
          background-color: rgba(55, 55, 55, 0.7);
        }

        .category-slide-title {
          z-index: 2;
          position: relative;
          height: 100%;
          padding: 15px 0;
          font-family: $rawline;

          hr {
            width: 20px;
            z-index: 6;
            margin-top: 20px;
            margin-bottom: 20px;
          }

          .over-title {
            font-size: 25px;
            z-index: 6;
          }

          .under-title {
            font-size: 30px;
            font-weight: bold;
            z-index: 6;
            font-family: $vanitas;
            line-height: 30px;
          }
        }
      }
    }

    .splide__arrow {
      background: transparent;

      svg {
        fill: #fff;
        height: 10.2em;
        width: 10.2em;
      }
    }
  }
}
