.search-main {
  .search-list-enhanced {
    .filters {
      .popin-filter-search,
      .popin-all-filters,
      .popin-filter-capacity,
      .popin-filter-date {
        .cross-close {
          right: 20px;
        }
        .filter-actions {
          text-align: center;
          .clear-filters,
          .apply-filters {
            background: transparent;
            border: 1px solid transparent;
            color: #272727;
            cursor: pointer;
            display: inline-block;
            font-family: $montserrat !important;
            font-weight: 300;
            font-size: 13px;
            margin: 20px;
            height: 45px;
            line-height: 45px;
            padding: 0 6px;
            white-space: nowrap;
            .icon {
              margin-right: 3px;
              position: relative;
              top: 1px;
            }
          }
          .clear-filters {
            &:hover {
              color: lighten(#272727, 15%);
            }
            &:focus {
              border-bottom-color: lighten(#272727, 15%);
              color: lighten(#272727, 15%);
            }
            .icon {
              height: 20px;
              position: relative;
              top: 3px;
              width: 20px;
            }
          }
          .apply-filters {
            background: #fff;
            border-color: $yellow;
            padding: 0 32px;
            text-transform: uppercase;
            &:hover,
            &:focus {
              background-color: $yellow;
            }
          }
        }
      }
      hr {
        border: none;
        border-top: 1px solid $dark-beige;
        margin-bottom: 20px;
        margin-top: -5px;
      }
      .hidden {
        display: none;
      }
      .filter-section-title {
        display: block;
        font-family: $montserrat !important;
        font-size: 19px;
        font-weight: 300;
        margin-bottom: 20px;
        text-transform: uppercase;
        .icon {
          color: $light-duck;
          fill: $light-duck;
          font-size: 24px;
          margin-right: 5px;
          position: relative;
          top: 4px;
        }
      }
    }
  }
}
