.page.events {
  $font-size: 18px;

  .ct--header {
    .big-title-1 {
      color: $yellow;
      text-transform: uppercase;
    }
  }

  .introduction {
    margin: 60px;

    p {
      @extend .text-1;
      font-size: $font-size;
      line-height: 1.62;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .section {
    margin: 50px 20px 100px;

    .section-header {
      align-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 50px;

      .section-title-decorator {
        padding: 20px 0;
        position: relative;
        width: 55%;

        &:before {
          background: url('../../../img/patterns/pattern_blue_transparent.png')
            center center repeat;
          bottom: 0;
          content: '';
          height: 50%;
          left: -20px;
          position: absolute;
          width: 100%;
          z-index: 1;
        }

        .images {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 20px;

          img {
            display: block;
            max-width: 100%;
            z-index: 2;
          }
        }
      }

      .section-title {
        @extend .big-title-1;
        color: $yellow;
        font-size: 6.5rem;
        text-align: center;
        text-transform: uppercase;
        width: 45%;
      }

      &.section-header-inversed {
        flex-direction: row-reverse;

        .section-title-decorator {
          &:before {
            left: initial;
            right: -20px;
          }
        }
      }
    }

    .section-content {
      margin: 30px 50px;

      ul {
        li {
          display: inline-block;
          margin: 5px 0;
          width: 25%;

          a {
            @extend .text-1;
            color: $black;
            display: block;
            font-size: $font-size;
            padding: 5px 0;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
