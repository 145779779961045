.page.tos {
  .container-tos {
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: left;
    p {
      @extend .text-1;
      margin: 20px 0 20px 20px;
      em {
        font-weight: 700;
      }
      i {
        font-style: italic;
      }
    }
    h1 {
      @extend .title-1;
    }
    h2 {
      @extend .title-2;
      margin: 40px 0 20px;
    }
    h3 {
      @extend .title-3;
      text-decoration: underline;
      font-weight: 400;
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 1.8rem;
      text-transform: none;
    }
    ul {
      margin-top: -14px;
      margin-left: 40px;
      margin-bottom: 20px;
      list-style-type: disc;
      li {
        @extend .text-1;
        margin-bottom: 10px;
        ul {
          margin-top: 10px;
          list-style-type: circle;
        }
      }
    }
    ul.tos-nav {
      margin-left: -10px;
      margin-top: 6px;
      li {
        padding: 2px 16px;
        display: inline-block;
        border-left: 1px solid #ccc;
        &:first-child {
          border-left: none;
        }
        a,
        em {
          @extend .text-2;
        }
        em {
          font-weight: 600;
        }
      }
    }
  }
  .container {
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: left;
    .title-1 {
      margin-bottom: 20px;
    }
    & > .text-1 {
      font-size: 1.7rem;
    }
  }
  .tos-content {
    display: block;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
    box-shadow: 0 0 10px #ccc;
    margin: 10px 0 20px;
    background: #fff;
    padding: 20px 10px;
    p {
      @extend .text-2;
      em {
        font-weight: 700;
      }
      i {
        font-style: italic;
      }
      margin-bottom: 20px;
    }
    h2 {
      @extend .title-2;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    h3 {
      @extend .title-3;
      text-decoration: underline;
      font-weight: 400;
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 1.8rem;
      text-transform: none;
    }
    ul {
      margin-left: 40px;
      margin-bottom: 20px;
      list-style-type: circle;
      li {
        @extend .text-2;
        margin-bottom: 10px;
      }
    }
  }
}
