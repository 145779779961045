.page.team {
  .content-cgu {
    text-align: center;
    h1 {
      font-size: 8rem;
      margin: 0 0 30px;
    }
    p {
      margin-bottom: 20px;
    }
    .ct--button {
      margin-top: 10px;
    }
    iframe {
      margin-top: 40px;
    }
  }
}
