.hubspot-newsletter-widget {
  position: relative;
  width: 240px;
  height: 72px;
  display: block;
  div {
    display: inline-block !important;
  }
  input {
    width: calc(100% - 53px) !important;
    background: $light-beige;
    padding: 0;
    position: absolute;
    left: 0;
    &[type='submit'] {
      left: calc(100% - 51px);
      right: 0;
      width: 50px !important;
      background: $yellow;
      color: $yellow;
    }
  }
  &::after {
    display: block;
    position: absolute;
    visibility: visible;
    content: 'OK';
    right: 15px;
    top: 29px;
    color: $black;
  }
  label {
    display: none;
  }
  h3 {
    display: none;
  }
}

.ct.ct--newsletter {
  position: relative;
  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .icon {
      position: absolute;
      width: 850px;
      height: 850px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      fill: $newsletter-bg;
    }
  }
  .inner {
    position: relative;
    z-index: 2;
    padding: 180px 0;
    text-align: center;
    max-width: 380px;
    margin: 0 auto;
    display: block;
    .title-newsletter {
      margin-bottom: 30px;
      display: inline-block;
    }
    .content-newsletter {
      margin-bottom: 50px;
    }
    .input-newsletter {
      display: flex;
      height: 50px;
      input {
        width: calc(100% - 53px);
        height: auto;
        margin-right: 3px;
        border: 0;
        background: $light-beige;
        padding: 0 20px;
      }
      .btn-submit-newsletter {
        width: 50px;
        height: 50px;
        border: 0;
        background: $yellow;
        cursor: pointer;
      }
    }
  }
}
