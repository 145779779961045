.footer {
  position: relative;
  width: 100%;
  background-color: $medium-grey;

  .footer-wrapper {
    position: relative;
    width: 100%;

    .doublelist-footer {
      .list-footer {
        a {
          color: $white;
        }
      }
    }

    .newsletter-strat-footer {
      h2 {
        color: $white;
      }

      .social-strat-footer {
        a {
          color: $white;
        }
      }
      a.contact-link {
        position: relative;
        color: $white;
        font-size: 13px;
        margin-left: 6px;
        font-family: $rawline;
        line-height: 40px;

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          bottom: -4px;
          left: 0;
          background-color: $white;
          transform: scaleX(0);
          transform-origin: 100% 0;
          transition: transform 600ms ease-in-out;
        }

        &:hover {
          &::after {
            transform-origin: 0% 0;
            transform: scaleX(1);
          }
        }
      }
    }
  }

  .final-strat-footer {
    display: block;
    background-color: $black;
  }
}

// DESKTOP ONLY
@media screen and (min-width: 951px) {
  .footer {
    height: 301px;

    .footer-wrapper {
      position: relative;
      height: 100%;
      margin: 0 auto;
      padding-top: 80px;
      display: flex;
      justify-content: center;

      .logo-strat-footer {
        position: relative;
        display: inline-block;
        height: 160px;
        width: 241px;

        .icon {
          position: absolute;
          width: 192px;
          height: 128px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $white;
          fill: $white;
        }

        &:before {
          content: '';
          position: absolute;
          background-color: $light-duck;
          right: 0;
          height: 100%;
          width: 1px;
        }
      }

      .doublelist-footer {
        display: inline-block;
        vertical-align: top;
        padding-top: 10px;
        margin-left: 30px;

        .list-footer {
          vertical-align: top;
          column-count: 3;

          .list-contact-1 {
            .element-list-contact-1 {
              margin-bottom: 5px;
              padding-bottom: 5px;

              a {
                position: relative;

                &::after {
                  position: absolute;
                  content: '';
                  width: 100%;
                  height: 1px;
                  bottom: -4px;
                  left: 0;
                  background-color: $white;
                  transform: scaleX(0);
                  transform-origin: 100% 0;
                  transition: transform 600ms ease-in-out;
                }

                &:hover {
                  &::after {
                    transform-origin: 0% 0;
                    transform: scaleX(1);
                  }
                }
              }
            }
          }
        }
      }

      .newsletter-strat-footer {
        display: inline-block;
        vertical-align: top;
        margin-left: 40px;

        .title-newsletter {
          margin-bottom: 17px;
          display: block;
          color: $white;
        }

        input {
          border: none;
          height: 50px;

          &:first-of-type {
            padding-left: 25px;
            width: calc(100% - 55px);
            display: inline-block;
            vertical-align: top;
          }
        }

        .button-footer {
          color: #755a2b;
          background-color: #f4c657;
          width: 50px;
          //padding: 0 6% 0;
          display: inline-block;
          cursor: pointer;
          border: none;
          height: 50px;
          vertical-align: top;
        }

        .social-strat-footer {
          margin-top: 30px;

          .list-rs {
            .element-list-rs {
              display: inline-block;
              width: 21px;
              height: 21px;
              margin-right: 20px;

              .icon {
                width: 21px;
                height: 21px;
                fill: $white;
                color: $white;
              }
            }
          }
        }
      }
    }

    .final-strat-footer {
      height: 50px;
      position: relative;
      text-align: center;

      .list-contact-2 {
        display: inline-block;
        margin-top: 16px;
        .element-list-contact-2 {
          display: inline-block;
          position: relative;
          padding-left: 10px;
          padding-right: 10px;
          color: $white;
          &:before {
            content: '';
            position: absolute;
            left: -1px;
            background-color: $white;
            width: 1px;
            height: 100%;
          }
          &:first-child:before {
            content: none;
          }
          a {
            color: $white;
          }
        }
      }
    }
  }
}
// MOBILE ONLY
@media screen and (max-width: 950px) {
  .footer {
    .footer-wrapper {
      padding: 40px 6% 30px;
      .logo-strat-footer {
        position: relative;
        display: block;
        margin-bottom: 30px;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: $light-duck;
          bottom: -10px;
          left: 0;
        }

        .icon {
          width: 140px;
          height: 32px;
          fill: $white;
          color: $white;
        }
      }

      .doublelist-footer {
        display: block;
        vertical-align: top;

        .list-footer {
          column-count: 2;

          // &:first-child {
          //     margin-left: 0;
          // }

          .list-contact-1 {
            .element-list-contact-1 {
              margin-bottom: 15px;
              a {
                display: block;
                padding: 2px 0;
              }
            }
          }
        }
      }

      .newsletter-strat-footer {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .title-newsletter {
          margin-bottom: 15px;
          display: inline-block;
          color: $white;
        }

        .form-footer {
          padding: 8px 5%;
          width: 78%;
          margin-right: 2%;
          border-radius: 0;
        }

        // .button-footer {
        //     padding: 8px 3%;
        //     text-align: center;
        //     background-color: $yellow;
        //     display: inline-block;

        // }

        input {
          border: none;
          height: 50px;
          vertical-align: top;

          &:first-of-type {
            padding-left: 25px;
            width: calc(100% - 65px);
            display: inline-block;
            background-color: $white;
          }
        }

        .button-footer {
          position: relative;
          background-color: $yellow;
          max-width: 46px;
          width: 80px;
          padding: 8px 10px;
          text-align: center;
          border: 1px solid $yellow;
          display: inline-block;
          cursor: pointer;
          height: 50px;
          color: $bobby-brown;
          vertical-align: top;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .social-strat-footer {
        margin-left: -2px;
        .list-rs {
          .element-list-rs {
            display: inline-block;
            margin-right: 14px;

            a {
              display: block;
              padding: 2px;

              .icon {
                width: 16px;
                height: 16px;
                fill: $white;
                color: $white;
              }
            }
          }
        }
      }
    }

    .final-strat-footer {
      padding: 10px 6%;
      line-height: 1.8;

      .list-contact-2 {
        .element-list-contact-2 {
          display: inline-block;
          padding: 4px 0;
          font-size: 1.2rem;
          color: $white;

          &:after {
            content: '|';
            margin: 0 3px 0 6px;
          }

          &:last-of-type:after {
            display: none;
          }

          a {
            color: $white;
          }
        }
      }
    }
  }
}
