.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-danger {
  @extend .text-1;
  color: #b62c2c;
}

.pictures .picture {
  background-size: cover !important;
}

.rating {
  font-size: 16px;
}

.rating-well {
  display: inline-block;
  direction: rtl;
}

.rating input.rating {
  display: none !important;
}

.rating div.star {
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  display: inline-block;
  position: relative;
}

.rating div.star:hover {
  cursor: pointer;
}

.rating div.star:before {
  content: '\2606';
  padding-right: 5px;
  color: #999;
}

.rating div.star:hover:before,
.rating div.star:hover ~ div.star:before {
  content: '\2605';
  color: #e3cf7a;
}

.rating div.star-full {
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  display: inline-block;
  position: relative;
}

.rating div.star-full:before {
  content: '\2605';
  padding-right: 5px;
  color: #e3cf7a;
}

.rating div.star-empty {
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  display: inline-block;
  position: relative;
}

.rating div.star-empty:before {
  content: '\2606';
  padding-right: 5px;
  color: #999;
}

.rating div.fa-norm {
  font-size: 1em;
}

.rating div.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.rating div.fa-2x {
  font-size: 2em;
}

.rating div.fa-3x {
  font-size: 3em;
}

.rating div.fa-4x {
  font-size: 4em;
}

.rating div.fa-5x {
  font-size: 5em;
}

.rating-stars {
  display: inline-block;
  unicode-bidi: bidi-override;
  color: #888;
  font-size: 25px;
  height: 25px;
  width: auto;
  margin: 0;
  position: relative;
  padding: 0;

  .rating-stars-upper {
    color: $yellow !important;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
    left: 0;
    overflow: hidden;

    span {
      color: $yellow !important;
    }
  }

  .rating-stars-lower {
    padding: 0;
    display: flex;
    z-index: 0;

    span {
      color: #888 !important;
    }
  }
}

p.no-parameter {
  font-size: 18px;
  margin: 20px;
  text-align: center;
}

.mb-30 {
  margin-bottom: 30px;
}
