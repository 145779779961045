@import 'register';

.page.user.register {
  padding-top: 80px;

  .title-2-register {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .big-title-register {
    font-size: 2.4rem;
  }
  .container {
    max-width: 850px;
    min-height: 600px;
    padding: 45px 80px;
    .text-content {
      text-align: center;
      .headline {
        font-size: 1.5rem;
      }
    }
    .social-auth {
      .left {
        padding-right: 2%;
      }
      .right {
        padding-left: 2%;
      }
    }
    .register-account {
      form {
        flex-wrap: wrap;
        .c6 {
          width: 48%;
        }
        .container-btn-radio {
          width: 100%;
          button.send {
            width: 50%;
            margin-left: 25%;
          }
        }
      }
    }
  }
}
