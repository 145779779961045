@import 'brief-modal';

.selection-modal.edit-event-modal {
  .selection-modal-content {
    padding: 40px 20px 20px 220px;

    &:before {
      background: url('../../../../img/patterns/pattern_yellow_transparent.png');
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 200px;
    }
  }
}
