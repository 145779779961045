.page.user.guest {
  padding-bottom: 20px;
  font-family: 'Rawline';
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('../../../../../../../web/medias/img/desktop/pages/faq/faq-bg-split.png');
  background-size: 27%;

  .title-2-guest {
    font-family: 'Montserrat';
    font-weight: 300;
  }
  .ct--button {
    width: 100%;
    height: 50px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    .label-btn {
      margin: 0 auto;
    }
  }

  .big-title-guest {
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
    font-family: 'Montserrat';
    font-weight: 300;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $breadcrumb;
      margin-top: 30px;
    }
  }
  .container {
    margin: 0 auto;
    .text-content {
      .headline {
        margin-bottom: 20px;
        line-height: 20px;
      }
    }
    .guest-account {
      width: 100%;
      margin-top: 40px;

      form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .container-btn {
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;
          .mentions {
            display: block;
            font-size: 1.3rem;
            color: $black;
            margin-bottom: 20px;
          }
          .continue {
            width: 100%;
            height: 50px;
            background: #f4c657;
            margin-top: 30px;
            display: flex;
            align-items: center;
            padding: 0 40px;
            border: none;
            cursor: pointer;

            span {
              display: inline-block;
              margin: 0 auto;
              text-align: center;
              color: #755a2b;
            }
          }
          .link-guest {
            text-decoration: underline;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
