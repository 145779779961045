@import 'brief';

.page.brief {
  padding-top: 80px;
  padding-bottom: 80px;

  .ct--breadcrumb {
    margin: 20px 0 70px;

    .inner {
      padding: 20px 0 20px 4%;
    }
  }

  .big-title-contact {
    color: $yellow;
    text-align: center;
    margin-bottom: 80px;
  }

  .container {
    .sizing-1 {
      vertical-align: top;

      .first-row-contact {
        padding-left: 25px;
        height: 315px;

        .text-content-first-row {
          line-height: 4rem;
          margin: 0 auto;
          padding-top: 100px;
          max-width: 350px;

          span {
            font-weight: 600;
          }
        }
      }

      .second-row-contact {
        margin-left: span(0.2 of 5);
        height: 440px;
        background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
      }
    }

    .sizing-2 {
      vertical-align: top;
    }

    .sizing-3 {
      vertical-align: top;

      .row-background-contact {
        background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
        margin-top: 315px;
        height: 440px;
      }
    }
  }
}
