@import 'seminaire-landing';

.page.seminaire-landing {
  > .ct--header {
    .header {
      &:before {
        content: initial; // todo : apply globally ?
      }
    }
  }

  > .container {
    padding-top: 20px;
  }
}
