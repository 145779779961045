.page.user.transmission-request {
  width: 100%;
  min-height: 100vh;
  .top-bar-return {
    width: 100%;
    height: 65px;
    padding: 15px 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $breadcrumb;
    .back {
      svg {
        width: 10px;
        height: 19px;
      }
    }
    .title-top-bar {
      vertical-align: middle;
      font-size: 2rem;
      font-family: 'Montserrat';
      font-weight: 300;
      font-style: normal;
      color: $black;
      svg {
        margin-right: 20px;
      }
    }
  }
  .ct.ct--breadcrumb {
    .inner {
      padding: 15px 25px;
    }
  }
  .content-transmission {
    margin: 0 auto 60px;
    .title-content {
      font-family: 'Montserrat Ultra';
      font-weight: 200;
    }
    .text-content {
      font-family: 'Rawline';
      font-weight: 300;
      a {
        color: $black;
        text-decoration: underline;
      }
    }
  }
  .content-cgu {
    text-align: left;
    p {
      margin: 20px 0 20px 20px;
      font-size: 1.4rem !important;
    }
    h1 {
      margin: 0 0 30px;
    }
    h2 {
      margin: 40px 0 20px;
    }
  }
  .btn-primary {
    text-transform: uppercase;
  }
}
