.search-main {
  .search-list {
    .landing-result-intro {
      padding-left: 25px;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      content: ' ';
      z-index: 15;
      transition: opacity 250ms $ease-out-cubic;
      pointer-events: none;
      opacity: 0;
    }

    &.opacify:before {
      pointer-events: all;
      opacity: 1;
    }

    .filters-bar {
      z-index: 20;
      position: relative;
    }
  }

  .search-wrapper {
    /* Background */
    .ct--filter.ct--select-checkbox .filter-choice,
    .ct--filter.ct--select-slider .filter-choice,
    .ct--filter.ct--select-checkbox-multiple .filter-choice {
      @include ct--button-action($medium-beige, $black);
    }
    /* Rounded */
    .popin-filter-date,
    .popin-all-filters,
    .popin-filter-search,
    .popin-filter-capacity {
      .cross-close {
        position: absolute;
        right: 30px;
        top: 20px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        z-index: 1;

        svg {
          width: 16px;
          height: 16px;
          fill: $medium-grey;
          color: $medium-grey;
          cursor: pointer;
        }
      }

      .ct--filter.ct--select-checkbox .filter-choice,
      .ct--filter.ct--select-slider .filter-choice,
      .ct--filter.ct--select-checkbox-multiple .filter-choice {
        border-radius: 3px;
      }
    }

    .landing-section {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      padding-left: 25px;
      padding-right: 25px;
      .c6 {
        padding-bottom: 25px;
      }

      .landing-row-description {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
      }
      .landing-row-grid {
        display: flex;
        flex-wrap: wrap;
        order: 3;
        flex-basis: 60%;
        align-items: center;
        justify-content: center;
        max-width: 60%;
      }
    }

    .section-snapmag {
      margin-top: 40px;
      padding: 0 60px 60px;
      .landing-section-snapmag-title {
        margin-bottom: 50px;
      }
      .news-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .ct--news-item {
          width: calc(100% / 3);
          background: transparent;
          transition: background 250ms $ease-out-quad;
          margin-bottom: 20px;
          &:nth-child(2) {
            margin: 0 10px 20px;
          }
          .pic {
            position: relative;
            background: grey;
            overflow: hidden;
            &:before {
              content: '';
              padding-bottom: 67%;
              width: 100%;
              height: 0;
              display: block;
            }
            img {
              position: center;
              max-width: 50%;
              max-height: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .about {
            .title-news {
              text-align: left;
              font-size: 2rem;
            }
          }

          @media screen and (min-width: 951px) {
            &:hover {
              background: $medium-grey;
              .about {
                // background: $medium-grey;
                color: $white;

                a {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
