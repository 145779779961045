@import 'input-contact';

.row-input-contact {
  .input-contact {
    height: 50px;
  }
  .message-error {
    font-size: 1rem;
  }
}
