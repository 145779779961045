.ct.ct--select-search {
  position: relative;
  width: 100%;
  label {
    margin-bottom: 10px;
    display: block;
  }
  input {
    margin-bottom: 18px;
    height: 35px;
    width: 100%;
    // border: 1px solid #755a2b;
    background: $input-bg;
  }
}
