@import 'guest';

.page.user.guest {
  padding-top: 80px;

  .title-2-guest {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .link-guest {
    font-size: 1.3rem;
  }
  .big-title-guest {
    font-size: 2.4rem;
  }
  .container {
    max-width: 850px;
    padding: 45px 80px;
    .text-content {
      text-align: center;
      .headline {
        font-size: 1.5rem;
      }
    }
    .guest-account {
      form {
        flex-wrap: wrap;
        .row-input-account {
          width: 48%;
        }
        .container-btn {
          width: 50%;
          .continue {
            span {
              font-size: 1.3rem;
            }
          }
          .link-guest {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
