.button-facebook-wrapper {
  text-align: center;

  .button-facebook {
    color: $black;
    font-family: $montserrat;
    font-weight: 300;
    font-size: 1.3rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.button-linkedin,
.button-google {
  width: 100%;
  height: 50px;
  position: relative;
  padding: 0 40px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  color: $white;
  font-family: $montserrat;
  font-weight: 300;

  span {
    font-size: 1.3rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 0 auto;

    svg {
      width: 21px;
      height: 20px;
      fill: $white;
      margin-right: 10px;
      position: relative;
    }
  }
}

.button-linkedin {
  background-color: $linkedin;

  &:hover {
    background-color: darken($linkedin, 6%);
    transition: background-color 0.2s ease;
  }

  span {
    svg {
      top: -3px;
    }
  }
}

.button-google {
  background-color: $google;

  &:hover {
    background-color: darken($google, 6%);
    transition: background-color 0.2s ease;
  }

  span {
    svg {
      top: -1px;
    }
  }
}

.separator {
  display: block;
  background-color: $breadcrumb;
  width: 100%;
  height: 1px;
  margin: 30px 0;
}

.section-additional-info {
  margin-bottom: 20px;
  line-height: 20px;
  font-size: 1.5rem;
}

.error-message {
  font-size: 1.5rem;
  visibility: visible;
  opacity: 1;
  display: block;
  width: 100%;
  color: #b62c2c;
  margin-bottom: 15px;
}

.social-connect-title {
  font-size: 2rem;
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: 300;
  text-align: center;
}

.social-connect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-connect-item {
  max-width: 50%;
  padding-right: 15px;
}

.row-input-registration-no-js {
  margin-bottom: 15px;
}
