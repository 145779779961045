@import 'event';

.page.event {
  .subtitle {
    margin-top: 31px;
    margin-bottom: 31px;
  }

  .generic-subtitle {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .spacing {
    width: 100%;
    margin: 100px 0;
  }

  .text-row {
    font-size: 1.75rem;
    line-height: 2.2rem;
    padding-bottom: 55px;
    padding-top: 40px;
    text-align: center;
    &.with-title {
      padding-top: 5px;
      h2 {
        margin-bottom: 20px;
      }
    }
  }

  .form-row-rent {
    margin-top: 100px;
    margin-bottom: 80px;

    #formulaire {
      position: relative;
      top: -150px;
    }

    .form-row-rent-title {
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 55px;
    }

    .form-row-rent-content {
      .form-row-rent-content-left-column {
        padding-top: 50px;
        vertical-align: top;
      }

      .form-row-rent-content-right-column {
        vertical-align: top;

        .hs-form {
          box-shadow: #ddd 2px 2px 8px;
          background-color: #fff;
          padding: 40px;
        }
      }
    }
  }

  .event-pattern-wrapper {
    padding-left: span(1.5 of 12);
    padding-right: span(1.5 of 12);
    .pattern-header {
      position: relative;
      background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_01.jpg');
      margin-top: -98px;
      z-index: 10;
      text-align: center;
      padding: 40px 140px;

      .text-pattern-header {
        color: $white;
        margin-bottom: 20px;
      }

      .icon-wrapper-event {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 10px;
        margin: 0 auto;

        .icon-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          position: absolute;
          bottom: 30px;
          left: 50%;
          transition: bottom 0.4s $ease-out-cubic;
          animation: arrow-down-move 1.5s infinite linear alternate;

          .header-arrow-down-sup {
            display: inline-flex;
            align-items: center;
            background: transparent;
            .icon {
              width: 9px;
              height: 7px;
              fill: $white;
              color: $white;
            }
          }

          .header-arrow-down-sub {
            display: inline-flex;
            align-items: center;
            background: transparent;
            .icon {
              width: 9px;
              height: 7px;
              fill: $white;
              color: $white;
            }
          }
        }
        @keyframes arrow-down-move {
          0% {
            bottom: 30px;
          }
          15% {
            bottom: 25px;
          }
          30% {
            bottom: 30px;
          }
        }
      }
    }
  }

  .ct--breadcrumb {
    margin: 0;
    .inner {
      padding: 20px 0 20px 12.5%;
    }
  }

  .button-wrapper {
    text-align: center;
    margin-top: 40px;
  }

  .our-places-row {
    overflow: hidden;
    .title-our-places {
      color: $yellow;
      margin: 0 auto 0 span(4.5 of 12);
      text-transform: uppercase;
    }
    .following-content-our-place:first-of-type {
      margin-top: 100px;
    }

    .following-content-our-place {
      display: block;
      margin-top: 100px;
      margin-right: -10%;
      &:nth-child(1) {
        margin-top: 0;
      }

      .ct--double-col-slider.left {
        .container {
          .cols {
            .col-right {
              overflow: initial;
              .slider {
                margin-top: 0;

                &::after {
                  top: -15%;
                }
              }
            }
          }
        }
      }
    }

    .button-wrapper {
      text-align: center;
      margin: 100px 0 90px;

      .button-our-places {
        display: inline-block;
      }
    }
  }

  .our-benefits-row {
    background-color: $light-beige;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;

    .title-our-benefits {
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 63px;
    }

    .subtitle-our-benefits {
      font-size: 3rem;
      line-height: 1.5;
      margin-bottom: 54px;
    }

    .description-our-benefits {
      margin-top: -30px;
      margin-bottom: 60px;
    }

    .subtitle-animation {
      &h3 {
        text-align: center;
      }
    }

    .text-inside-row {
      font-size: 1.75rem;
      line-height: 2.2rem;
      text-align: center;

      &.with-title {
        padding-top: 5px;
        h2 {
          margin-bottom: 20px;
        }
      }
    }

    .components-wrapper {
      // padding: 0 span(0.70 of 10);

      .ct--item-result {
        text-align: left;
        vertical-align: top;
        margin-bottom: 20px;

        &:nth-child(2n + 1) {
          margin-right: 25px;
        }
      }
    }

    .subtitle-animation-place {
      text-align: center;

      .subtitle-animation {
        font-size: 3rem;
        line-height: 1.5;
        margin-top: 130px;
        margin-bottom: 54px;
      }
    }
  }

  .faq-row {
    margin: 85px auto 50px;
    width: 80%;
    .landing-section-faq {
      margin-top: 0;
      padding-bottom: 0;
      .landing-section-faq-title {
        margin-bottom: 75px;
      }
    }
  }

  .new-service-row {
    padding: 100px 0;
  }
}
