@import '../../../variables/colors';

.page.user.register {
  button.send {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 300;
  }
  &.events {
    ul {
      font-size: 10px;
      li {
        margin-bottom: 10px;
        a {
          text-decoration: none;
          color: black;
          font-size: 14px;
        }
      }
    }
  }
  padding-bottom: 20px;
  font-family: 'Rawline';
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('../../../../../../../web/medias/img/desktop/pages/faq/faq-bg-split.png');
  background-size: 27%;

  .title-2-register {
    font-family: 'Montserrat';
    font-weight: 300;
  }
  .ct--button {
    width: 100%;
    height: 50px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    .label-btn {
      margin: 0 auto;
    }
  }

  .big-title-register {
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
    font-family: 'Montserrat';
    font-weight: 300;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $breadcrumb;
      margin-top: 30px;
    }
  }
  .container {
    margin: 0 auto;
    .text-content {
      .headline {
        margin-bottom: 20px;
        line-height: 20px;
      }
    }
    .register-account {
      width: 100%;
      margin-top: 40px;

      form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        &.hidden-labels {
          .label {
            @include sr-only;
          }
        }

        .container-btn-radio {
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;

          .mentions {
            display: block;
            font-size: 1.3rem;
            color: $black;
            margin-bottom: 20px;
          }
          .button-radio {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 5px;

            .label {
              color: $black;
              display: block;
              width: 100%;
              position: relative;
              font-size: 1.3rem;
              margin-bottom: 0;
              margin-left: 15px;
              &::before,
              &::after {
                content: ' ';
              }
            }
            /* Base for label styling */
            [type='radio']:not(:checked),
            [type='radio']:checked {
              position: absolute;
              left: -9999px;
            }
            [type='radio']:not(:checked) + label,
            [type='radio']:checked + label {
              position: relative;
              padding-left: 20px;
              cursor: pointer;
            }

            /* radio aspect */
            [type='radio']:not(:checked) + label:before,
            [type='radio']:checked + label:before {
              content: ' ';
              position: absolute;
              left: 0;
              top: 2px;
              width: 12px;
              height: 12px;
              border: 1px solid $breadcrumb;
              background: $white;
              border-radius: 50%;
            }
            /* checked mark aspect */
            [type='radio']:not(:checked) + label:after,
            [type='radio']:checked + label:after {
              content: ' ';
              position: absolute;
              left: 2px;
              top: 4px;
              height: 8px;
              width: 8px;
              border-radius: 50%;
              background: $dark-duck;
              transition: all 0.2s;
            }
            /* checked mark aspect changes */
            [type='radio']:not(:checked) + label:after {
              display: none;
            }
            [type='radio']:checked + label:after {
              display: block;
            }
          }
          .send {
            width: 100%;
            height: 50px;
            background: #f4c657;
            margin-top: 30px;
            display: flex;
            align-items: center;
            padding: 0 40px;
            border: none;
            cursor: pointer;

            span {
              display: inline-block;
              margin: 0 auto;
              text-align: center;
              color: #755a2b;
              font-size: 1.3rem;
            }

            @include ct--button-action;
          }
        }
      }
    }
  }
}

.sgp-body {
  .page.user.register {
    .container {
      .register-account {
        form {
          .sgp-notice {
            margin-top: -20px;
            margin-bottom: 40px;
            padding: 20px;
            text-align: center;
            background: transparentize($yellow, 0.5);
          }
          .container-btn-radio {
            margin-top: 0;
          }
        }
      }
    }
  }
}
