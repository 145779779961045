@import 'key-figures';

.ct.ct--key-figures {
  .container {
    .title-key-figures {
      line-height: 1.1;
    }
    .figures {
      .figure {
        margin-bottom: 30px;
        min-width: 250px;
        .number-figure {
          height: 62px;
          margin-bottom: 30px;
          .inner-big-number {
            line-height: 11rem;
            display: block;
            font-size: 11rem;
          }
          @supports (-ms-ime-align: auto) {
            .inner-big-number {
              line-height: 9rem;
            }
          }
          @supports (-ms-accelerator: true) {
            .inner-big-number {
              line-height: 9rem;
            }
          }
        }
        .title-figure {
          margin-bottom: 20px;
          text-transform: uppercase;
        }
      }
    }
  }
  @media screen and (max-width: 1025px) {
    .container {
      .title-key-figures {
        padding-bottom: 25px;
      }
      .inner {
        .figures {
          margin: 30px 0 10px !important;
        }
      }
    }
  }
}
