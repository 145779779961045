@import './layout';

body {
  strong {
    font-weight: 900;
  }
  font-size: 1.4rem;
  main {
    // overflow-x: hidden;
    .page {
      &.show,
      &.search-list {
        min-height: calc(100vh - 75px);
      }
      .container {
        font-size: 0;
      }

      /*********************
			Virtual scroll
			*********************/
      &.is-scrolling {
        .scrollable {
          will-change: transform;
        }
      }

      &.y-scroll {
        .scrollable {
          width: 100%;
        }
        .vs-scroll-view {
          width: 1px;
        }
      }
      &.x-scroll {
        .scrollable {
          height: 100%;
        }
        .vs-scroll-view {
          height: 1px;
        }
      }

      &.is-native-scroll {
        .vs-scroll-view {
          position: absolute;
          top: 0;
          left: 0;
          visibility: hidden;
        }
      }
      &.is-virtual-scroll {
        .vs-scrollbar {
          position: absolute;
          z-index: 20;
          display: block;
          background-color: #ccc;
          cursor: pointer;
          transform: scale3d(1, 1, 1);
          transition: transform 300ms $ease-out-cubic;

          .vs-scrolldrag {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            background-color: #999;
            cursor: grab;
          }
          &.vs-vertical {
            top: 0;
            right: 0;
            width: 10px;
            height: 100%;

            &:hover {
              transform: scale3d(2, 1, 1);
            }
            .vs-scrolldrag {
              width: 100%;
            }
          }
          &.vs-horizontal {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 10px;

            &:hover {
              transform: scale3d(1, 2, 1);
            }
            .vs-scrolldrag {
              height: 100%;
            }
          }
        }
        &.is-dragging {
          cursor: grabbing;

          .vs-scrollbar,
          .vs-scrolldrag {
            cursor: grabbing !important;
          }
          .vs-scrollbar {
            &.vs-vertical {
              transform: scale3d(2, 1, 1);
            }
            &.vs-horizontal {
              transform: scale3d(1, 2, 1);
            }
          }
        }
      }
    }
  }
}
