.page.contact {
  .container {
    .sizing-2 {
      .brief-form {
        padding: 40px;
        box-shadow: #ddd 2px 2px 8px;
      }

      .contact-form {
        .form-contact-place {
          label {
            color: $yellow;
            font-weight: bold;
            text-transform: uppercase;
          }

          .form-control {
            background-color: $white;
            border-color: $white;
          }

          .has-error {
            .form-control {
              border-color: #b62c2c;
            }

            .message-error {
              font-family: $rawline;
            }
          }

          hr {
            margin: 30px auto 25px;
            border: none;
            border-bottom: 2px solid darken($medium-beige, 5%);
          }

          .subject-message {
            display: none;
            margin-bottom: 20px;

            &.show {
              display: block;
            }
          }
        }
      }
    }

    .text-legal {
      margin-top: 50px;
      font-size: 1.25rem;
      font-style: italic;
    }
  }

  .contact-wrapper {
    position: relative;
  }
}
