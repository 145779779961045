#admin-panel {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  min-width: 180px;
  max-width: 320px;
  background-color: #222;
  font-family: Arial, Helvetica, sans-serif;
  &.dev {
    // move panel above symfony debug toolbar
    bottom: 42px;
    left: 5px;
  }
  * {
    box-sizing: border-box;
    vertical-align: baseline;
    letter-spacing: normal;
    width: auto;
  }
  .admin-panel-block-separator {
    border-top: 1px solid #444;
  }
  .admin-panel-block {
    display: block;
    width: 100%;
    white-space: nowrap;
    > button {
      background-color: transparent;
      border: none;
      width: 100%;
      text-align: left;
    }
    > a,
    > button,
    > span {
      display: block;
      height: 36px;
      padding: 0 18px 0 12px;
      font-size: 13px;
      line-height: 36px;
      color: #f5f5f5;
      text-transform: lowercase;
      border-left: 5px solid transparent;
      border-left-color: lighten(#444, 10%);
      &.hidden {
        display: none !important;
      }
      svg {
        display: inline-block;
        position: relative;
        top: 3px;
        margin-right: 6px;
        width: 15px;
        height: 15px;
        color: #f5f5f5;
        fill: #f5f5f5;
      }
    }
    > a,
    > button {
      &:hover {
        background-color: #444;
        cursor: pointer;
      }
    }
    &.danger {
      > a,
      > button,
      > span {
        border-left-color: darken(#e60400, 5%);
        &:hover {
          background-color: #b0413e;
        }
      }
    }
    &.success {
      > a,
      > button,
      > span {
        border-left-color: darken(#4f805d, 5%);
        &:hover {
          background-color: #4f805d;
        }
      }
    }
    &.info {
      > a,
      > button,
      > span {
        border-left-color: darken(#a46a1f, 5%);
        &:hover {
          background-color: #a46a1f;
        }
      }
    }
  }
  .tippy-backdrop,
  .tippy-popper,
  .tippy-content,
  .tippy-tooltip {
    min-width: 600px !important;
    max-width: 600px !important;
    width: 600px !important;
  }
}
