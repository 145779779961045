@import '~flatpickr/dist/flatpickr.css';

input[type='text'].calendar-filter-date {
  display: none;
  width: 235px;
}

// SP Fix
.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-calendar {
  @extend .rawline-regular;
  font-size: 1.3rem;
  width: 100%;
  box-shadow: none;
  text-transform: uppercase;
  width: 255px;

  .flatpickr-current-month {
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .montserrat-light;
    font-size: 1.8rem;
    padding-top: 4px;

    .cur-month {
      font-weight: initial;
      margin-left: 20px;
    }

    input.cur-year {
      font-weight: initial;
    }

    .numInputWrapper {
      width: 70px;
      margin-top: -1px;
    }
  }

  span.flatpickr-weekday {
    font-size: 1rem;
  }

  .flatpickr-weekdays,
  .dayContainer {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .flatpickr-days {
    width: auto;
  }

  .flatpickr-day {
    border-radius: 0;
    border: 1px solid #e0dcd8;
    margin: 2px 1px;
    color: $black;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    flex-basis: auto;

    &.flatpickr-disabled {
      background: rgba(224, 220, 216, 0.5);
      color: rgba(39, 39, 39, 0.4);
      cursor: not-allowed;
    }

    &.prevMonthDay,
    &.nextMonthDay {
      border-color: transparentize(#e6e6e6, 0.25);
      color: transparentize($black, 0.5);
    }

    &.today {
      background: rgba(244, 198, 87, 0.4);

      &:hover {
        border: 1px solid #e0dcd8;
      }
    }

    &.selected {
      background: $yellow;

      &:hover {
        border: 0;
      }
    }
  }
}
