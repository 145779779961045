// buttons

.add-to-selection,
.remove-from-selection {
  display: block;
  white-space: nowrap;
  height: 40px;
  padding: 0 18px;
  font-size: 13px;
  line-height: 40px;
  text-transform: uppercase;
  border: none;
  background-color: $yellow;
  color: $black;
  font-family: $montserrat;

  &:hover {
    background-color: darken($yellow, 15%);
    color: darken($black, 15%);
    cursor: pointer;
  }

  svg {
    color: $black;
    fill: $black;
    font-size: 13px;
    margin-right: 4px;
    position: relative;
    top: 1px;
  }

  &.hidden {
    display: none !important;
  }
}

.remove-from-selection {
  background-color: $black;
  color: $white;

  &:hover {
    background-color: lighten($black, 5%);
    color: $white;
  }

  svg {
    color: $white;
    fill: $white;
    font-size: 14px;
    top: 3px;
  }
}

// list ui

.selection-list-helper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 6;
}

// show ui

.selection-show-helper {
  margin-right: 12px;
}
