$white: #ffffff;
$medium-grey: #373737;
$dropdown-grey: #434343;
$light-grey: #a8a8a8;
$black: #272727;
$red: #b62c2c;
$dark-duck: #0d5878;
$light-duck: #168891;
$light-duck-accessible: #147e86;
$yellow: #f4c657;
// $yellow-hover:	#dbbe7a;
$light-beige: #fff8f0;
$medium-beige: #eee5da;
$dark-beige: #e0dcd8;
// $bobby-brown: #755a2b;
$bobby-brown: #47381f;
$walter-white: #ffffff;
$newsletter-bg: #fdf4e9;
$input-bg: #fdf4e9;
$breadcrumb: #bfbcb9;
$basket-li: #83807d;
$basket-li-accessible: #74716e;
$no-picto: #b62c2c;
$yes-picto: #168991;
$star-off: #d6d0ca;
$star-on: #f4c657;
$linkedin: #1175b1;
$google: #e94235;
