.search-main {
  .enhanced-range-filter {
    .wrapper-range-slider {
      margin: 30px 10px 0;
      .range-slider {
        &.noUi-horizontal {
          .noUi-base {
            .noUi-origin {
              .noUi-handle {
                .noUi-tooltip {
                  bottom: -22px;
                  font-size: 13px;
                }
                &.noUi-handle-lower {
                  .noUi-tooltip {
                    left: -10px;
                    transform: unset;
                  }
                }
                &.noUi-handle-upper {
                  .noUi-tooltip {
                    left: unset;
                    right: -10px;
                    transform: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
