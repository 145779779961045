@import 'navbar-search';
@import '../../variables/colors';
@import '../../variables/eases';

.ct.ct--navbar-search {
  position: fixed;
  width: 100%;
  height: 80px;
  background: $black;
  color: $white;
  top: 0;
  display: flex;
  padding: 0 25px;
  align-items: center;
  z-index: 100;
  // justify-content: flex-end;

  .logo {
    margin-right: 50px;
    .icon {
      width: 212px;
      height: 47px;
      fill: $white;
    }
  }
  .items {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: auto;
    li {
      height: 100%;
      margin-right: 20px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
      a {
        position: relative;
        display: flex;
        align-items: center;
        color: $white;
        text-transform: uppercase;
        height: 100%;
        &.active {
          &:before {
            opacity: 1;
          }
        }
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 5px;
          background: $yellow;
          bottom: 0;
          left: 0;
          opacity: 0;
        }
        .icon {
          fill: $white;
          width: 15px;
          height: 24px;
          margin-right: 10px;
        }
        &.entertainment {
          .icon {
            width: 24px;
          }
        }
        &.drink {
          .icon {
            width: 19px;
          }
        }
        &.staff {
          .icon {
            width: 24px;
          }
        }
      }
    }
  }
  .sub-list-nav {
    justify-self: flex-end;

    .menuburger-my-account,
    .menuburger-my-cart,
    .menuburger-my-selections {
      width: 18px;
      height: 18px;
      position: relative;
      display: inline-block;
      margin-right: 25px;

      .account-sub-navbar {
        position: relative;
        width: 18px;
        height: 18px;
        background-color: transparent;
        display: inline-block;
        z-index: 10;
        cursor: pointer;

        .icon {
          color: $yellow;
          fill: $yellow;
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
    }

    .menuburger-my-cart,
    .menuburger-my-selections {
      .quantity {
        @extend .rawline-bold;
        align-items: center;
        background: $light-duck;
        border-radius: 50%;
        color: $white;
        display: flex;
        font-size: 0.9rem;
        height: 17px;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        right: -12px;
        top: -10px;
        width: 17px;
      }
    }

    .menuburger-my-selections {
      svg {
        animation: ring 3.5s 10s ease-in-out;
      }

      .quantity {
        background: $red;
      }
    }

    .menuburger-icon {
      position: relative;
      width: 15px;
      height: 15px;
      background-color: black;
      display: inline-block;
      z-index: 5;
      cursor: pointer;

      .icon {
        color: $yellow;
        stroke: $yellow;
        fill: $yellow;
        width: 15px;
        height: 15px;
        vertical-align: middle;
      }
    }
  }
}

.sgp-body {
  .ct.ct--navbar-search .logo .icon {
    aspect-ratio: 112/63;
    width: auto;
    height: 50px;
  }

  .ct.ct--navbar-search {
    .sub-list-nav {
      .cta-wrapper {
        display: inline-block;
        padding-right: 80px;

        a {
          display: flex;
          align-items: center;
          padding: 10px;
          border: 1px solid $yellow;
          color: $yellow;
          text-transform: uppercase;
          @extend .montserrat-light;
          font-size: 1.3rem;
          transition: color 400ms $ease-in-out-quad,
            background 400ms $ease-in-out-quad;

          .icon {
            fill: $yellow;
            font-size: 1rem;
            margin-left: 10px;
            transition: fill 400ms $ease-in-out-quad;
          }

          &:hover {
            background: $yellow;
            color: $black;

            .icon {
              fill: $black;
            }
          }
        }
      }
    }
  }
}
