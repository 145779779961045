body {
  &.modal-open {
    overflow: hidden;
    width: 100%;
    height: 100%;
    &.platform-ios {
      position: fixed;
    }
  }
}

.selection-modal-manager {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: 9990;
  overflow-x: hidden;
  overflow-y: auto;

  .selection-modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9990;
  }

  .selection-modal {
    position: relative;
    width: calc(100% - 2 * 1.75rem);
    max-width: 1024px;
    pointer-events: none;
    display: flex;
    align-items: center;
    margin: 1.75rem auto;
    min-height: calc(100% - 3.5rem);
    z-index: 9999;

    .selection-modal-content {
      padding: 10px;
      position: relative;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      outline: 0;
      align-items: center;
      display: flex;
      justify-content: center;

      .additional-cost-mention {
        padding-top: 15px;
        font-style: italic;
        font-size: 11px;
      }
    }

    .close-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 2rem;
      position: absolute;
      right: 30px;
      top: 30px;

      svg {
        fill: #ccc;
      }
    }

    .visual,
    .functional {
      padding: 50px 70px;
      float: left;
      width: 50%;
      text-align: center;
    }

    .visual {
      padding-right: 35px;

      .img-wrapper {
        margin-bottom: 20px;
        position: relative;
        top: -10px;

        &:after {
          background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
            center center repeat;
          bottom: -20px;
          content: '';
          height: 100%;
          left: -20px;
          position: absolute;
          width: 100%;
          z-index: 1;
        }

        img {
          max-width: 100%;
          position: relative;
          z-index: 2;
        }
      }
    }

    .functional {
      padding-left: 35px;
    }

    .full {
      padding: 50px 70px;
    }

    .content {
      text-align: center;

      h2 {
        line-height: 1.25;

        small {
          display: block;
          font-size: 1.5rem;
          margin-top: 10px;
        }

        &:after {
          background: $yellow;
          content: '';
          display: block;
          height: 5px;
          margin: 25px auto 35px;
          width: 20%;
        }

        &.infos {
          &:after {
            background: $light-duck;
          }
        }
      }

      p {
        line-height: 1.5;
        margin-bottom: 35px;
      }

      .inputs-line {
        display: block;
        width: 100%;
        text-align: center;

        .input-wrapper {
          display: inline-block;
          width: 80px;
          text-align: center;
          position: relative;

          &:first-child {
            margin-right: 40px;
          }

          label {
            font-family: $montserrat;
            display: block;
            font-weight: 400;
            font-size: 1rem;
            text-transform: uppercase;
          }

          input {
            border: 1px solid #bfbcb9;
            display: block;
            width: 100%;
            text-align: center;
            position: relative;
            line-height: 3rem;
            font-size: 1.5rem;
            font-family: $montserrat;
            padding-left: 20px;
          }

          .input-suffix {
            position: absolute;
            color: $black;
            font-size: 1.5rem;
            display: inline-block;
            height: auto;
            width: auto;
            right: 18px;
            bottom: 0.92rem;
            z-index: 2;
            font-family: $montserrat;
          }
        }
      }
    }

    .cta {
      border: 1px solid $yellow;
      background-color: $yellow;
      color: $black;
      cursor: pointer;
      font-family: $montserrat;
      font-size: 13px;
      height: 40px;
      line-height: 40px;
      padding: 0 18px;
      text-transform: uppercase;
      white-space: nowrap;

      &:hover {
        background-color: darken($yellow, 10%);
        border-color: darken($yellow, 10%);
        color: darken($black, 15%);
      }

      &:disabled {
        opacity: 0.75;
      }

      &.cta-clear {
        background-color: #fff;
        border: 1px solid $yellow;

        &:hover {
          background-color: darken(#fff, 10%);
        }
      }

      &.cta-infos {
        border-color: $light-duck;
        background-color: $light-duck;
        color: #fff;

        &:hover {
          background-color: darken($light-duck, 10%);
        }

        &.cta-clear {
          background-color: #fff;
          border: 1px solid $light-duck;
          color: $black;

          &:hover {
            background-color: darken(#fff, 10%);
          }
        }
      }

      svg {
        color: $black;
        fill: $black;
        font-size: 13px;
        margin-right: 4px;
        position: relative;
        top: 1px;
      }
    }

    input.quantity-input {
      border: none;
      border-bottom: 1px dashed #aaa;
      font-size: 3.5rem;
      font-weight: 300;
      text-align: center;
      color: #888;
      width: 80px;
      font-family: $montserrat;
    }

    &.authentication-required-modal {
      width: 640px;

      .cta {
        margin: 0 20px;
      }
    }

    &.confirm-clear-selection-modal,
    &.only-places-selected-modal,
    &.merge-selections-modal {
      width: 860px;

      .cta {
        margin: 0 20px;
      }
    }

    &.place-added-modal {
      .cta-clear {
        margin-bottom: 10px;
      }
    }

    &.add-place-modal {
      .place-infos {
        p {
          margin-bottom: 20px;
        }
        ul {
          display: inline-block;
          padding-left: 20px;
          margin-bottom: 30px;
          li {
            text-align: left;
          }
        }
        label {
          @extend .text-1;
        }
      }
    }

    &.add-menu-modal {
      p {
        margin-bottom: 20px;
      }

      .radio-wrapper {
        ul {
          display: inline-block;
          margin: -5px;

          li {
            text-align: left;

            label {
              @extend .text-1;
              color: $black;
              margin-bottom: 0;
              padding: 5px;

              input {
                margin-right: 6px;
                position: relative;
                top: 1px;
              }
            }
          }
        }
      }

      .menu-infos {
        margin-bottom: 20px;
        text-align: center;

        .quantity-info,
        .price-info {
          display: inline-block;
          width: 45%;
        }
      }

      .slider-wrapper {
        .vue-nouislider {
          margin-bottom: 20px;
        }
      }

      .form-actions {
        margin-top: 40px;
        border-top: 1px solid #eee;
        padding-top: 20px;

        button {
          display: inline-block;
          margin: 0 10px;
        }
      }
    }

    &.merge-selections-modal {
      .content {
        ul {
          margin-bottom: 35px;
          li {
            display: block;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
