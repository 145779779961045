@import '../../variables/colors';
@import '../../mixins/dropdown_toggle';

.page.homepage-index {
  .section-filter-home {
    position: relative;
    margin: -150px 0 0;
    color: $white;
    z-index: 2;

    &.fixed {
      position: fixed;
      top: 90px;
      width: 100%;
      margin-top: 0;
      z-index: 10;
      background: #0b5878;

      .container {
        .inner {
          background: none;
          padding: 10px 90px;

          .filters-home {
            .row {
              margin-bottom: 0;
              .filters {
                .filter {
                  label {
                    height: 0;
                    margin-bottom: 0;
                    opacity: 0;
                  }
                }
              }
            }
          }
        }
      }

      .more-home {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
    }

    .container {
      .inner {
        background: #1f5f73
          url('../../../../../../web/medias/img/generics/pattern/se_pattern_01.jpg')
          center center repeat;
        margin: 0 auto;
        padding: 35px 90px 40px;
        transition: all 0.2s ease-in;

        .filters-home {
          .row {
            margin-bottom: 15px;
            display: flex;

            form {
              width: 100%;
              display: flex;
            }

            .filters {
              display: flex;
              width: calc(100% - 70px);

              .filter {
                @extend .nav-3;
                width: calc(100% / 4);
                margin-right: 10px;
                color: $black;

                label {
                  color: $yellow;
                  margin: 0 0 10px;
                  padding: 0 0 0 10px;
                  letter-spacing: 0.1rem;
                  text-transform: uppercase;
                  transition: all 0.2s ease-in;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                $control-height: 50px;

                // select filter

                .choices {
                  .choices__inner {
                    min-height: $control-height;

                    .choices__list--single {
                      display: inline-block;
                      padding: 8px 4px;
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      .choices__item {
                        font-weight: bold;

                        &.choices__placeholder {
                          opacity: 1;
                        }
                      }
                    }
                  }
                }

                // number... generic filters

                .form-control {
                  min-height: $control-height;
                  font-weight: bold;
                }

                // capacity filter

                &.capacity-filter {
                  .dropdown {
                    position: relative;

                    .dropdown-toggle {
                      position: relative;
                      display: block;
                      width: 100%;
                      padding: 0 13px;
                      background-color: #fff;
                      border: 1px solid #e8e8e8;
                      border-radius: 0;
                      font-family: $rawline;
                      color: $black;
                      text-align: left;
                      cursor: pointer;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-transform: lowercase;
                      white-space: nowrap;

                      &::after {
                        @include dropdown-toggle($top: 50%);
                      }
                    }

                    .dropdown-menu {
                      display: none;
                      position: absolute;
                      bottom: 50px;
                      top: auto;
                      width: 100%;
                      padding: 10px;
                      border-radius: 0;
                      background-color: #fff;

                      .wrapper-range-slider {
                        margin: 30px 10px 15px;

                        .range-slider {
                          .noUi-tooltip {
                            bottom: 20px;
                          }
                        }
                      }

                      &.to-bottom {
                        bottom: auto;
                        top: 50px;
                        border-bottom: 1px solid #c2c2c2;
                        border-left: 1px solid #c2c2c2;
                        border-right: 1px solid #c2c2c2;
                        border-top: none;
                      }
                    }

                    .filter-submit {
                      display: none;
                      width: 100%;
                      color: #47381f;
                      background-color: $yellow;
                      border: 1px solid $yellow;
                      padding: 0 15px;
                      cursor: pointer;
                      transition: color 0.15s ease-in-out,
                        background-color 0.15s ease-in-out;

                      &:hover {
                        color: #241c10;
                        background-color: #f2bc3a;
                      }

                      .icon {
                        width: 10px;
                        height: 10px;
                        fill: #47381f;
                        transition: fill 0.15s ease-in-out;
                      }
                    }

                    &.open {
                      .dropdown-menu,
                      .filter-submit {
                        display: block;
                      }

                      .dropdown-toggle {
                        display: none;
                      }
                    }
                  }
                }

                // calendar filter

                &.calendar-filter {
                  position: relative;
                  z-index: 1;

                  // date

                  .flatpickr-wrapper {
                    position: relative;
                    z-index: 1;
                    display: block;

                    .flatpickr-alt-input {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-transform: lowercase;
                      white-space: nowrap;
                    }

                    .flatpickr-calendar {
                      position: absolute;
                      bottom: 50px;
                      top: auto;
                      width: 100%;
                      padding: 10px;
                      border-radius: 0;
                      border-left: 1px solid #c2c2c2;
                      border-right: 1px solid #c2c2c2;
                      border-top: 1px solid #c2c2c2;

                      &.to-bottom {
                        bottom: auto;
                        top: 50px;
                        border-bottom: 1px solid #c2c2c2;
                        border-top: none;
                      }

                      &:before,
                      &:after {
                        display: none;
                      }

                      .flatpickr-months {
                        .flatpickr-prev-month {
                          left: 8px;
                          top: 8px;
                        }

                        .flatpickr-next-month {
                          right: 8px;
                          top: 8px;
                        }
                      }

                      .flatpickr-days {
                        .dayContainer {
                          .flatpickr-day {
                            width: calc(100% / 7 - 4px);
                            height: 36px;
                          }
                        }
                      }
                    }
                  }

                  // periods

                  select {
                    display: none;
                  }

                  .choices {
                    position: absolute;
                    bottom: 0;
                    z-index: 0;
                  }
                }
              }
            }

            .btn-search {
              margin-top: auto;
              font-size: 1.3rem;
              padding: 0 20px;
              text-transform: uppercase;
              border: 0;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              height: 50px;
              @include ct--button-action;

              .icon {
                height: 1.3em;
                width: 1.3em;
              }
            }
          }
        }

        .more-home {
          text-align: center;
          transition: opacity 0.25s ease-in, height 0.25s ease-in;

          p {
            margin: 60px 0 30px;
            font-size: 2.5rem;
            line-height: 1.2;

            a {
              color: $white;
            }
          }
        }
      }
    }
  }
}
