@import '../../variables/colors';
@import '../../mixins/dropdown_toggle';

$choices-bg-color: #fff;
$choices-border-radius: 0;
$choices-border-radius-item: 0;
$choices-font-size-lg: 13px;
$choices-font-size-md: 13px;
$choices-font-size-sm: 13px;
$choices-keyline-color: #e8e8e8;
$choices-primary-color: $light-duck;
@import '~choices.js/src/styles/choices';

.search-main,
.homepage-index {
  .choices {
    font-family: 'Rawline', sans-serif; // todo : replace by variable
    width: 100%;

    // common

    .choices__inner {
      font-weight: normal;
      min-height: 40px;
      padding-right: 32px;
    }

    .choices__list {
      font-weight: normal;

      .choices__item {
        .icon {
          margin-right: 4px;
          position: relative;
          top: 2px;
        }
      }

      &.choices__list--dropdown {
        &.is-active {
          z-index: 9999;
        }

        .choices__item--selectable {
          padding-right: 10px;
        }
      }
    }

    // multiple

    &[data-type*='select-multiple'] {
      // force multi select to look like single select
      &:after {
        @include dropdown-toggle;
      }

      .choices__inner {
        cursor: pointer;

        .choices__list.choices__list--multiple {
          .choices__placeholder {
            display: inline-block;
            padding: 4px 16px 4px 4px;
            width: 100%;
          }
        }

        .choices__input {
          display: none;
        }
      }
    }

    // single

    &[data-type*='select-one'] {
      .choices__input {
        position: relative;
        font-size: 13px;
      }
    }

    .choices__list.choices__list--multiple {
      .choices__item {
        border: none;
        font-size: 13px;
        line-height: 1.25;
      }
    }

    .choices__list.choices__list--dropdown {
      .choices__list {
        .choices__group {
          .choices__heading {
            text-transform: uppercase;
          }
        }

        .choices__item {
          line-height: 1.25;
          word-break: initial;
        }
      }
    }
  }
}

.platform-ios {
  .search-main {
    .choices {
      .choices__list {
        &.choices__list--dropdown {
          &.is-active {
            margin-top: -2px; // f*** hack fos ios safari
          }
        }
      }
    }
  }
}
