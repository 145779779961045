@import 'validation-request';
.page.user.validation-request {
  padding: 80px 0;

  .validation {
    .content-validation {
      padding: 7% 15%;
      .title-content {
        font-size: 3.5rem;
      }
      .subtitle {
        font-size: 2rem;
      }
      .text-content {
        font-size: 1.5rem;
        line-height: 22px;
        margin: 30px 0 50px;
      }
    }
  }
}
