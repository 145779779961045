html {
  @include full-relative();
}
html {
  font-size: 62.5%;
}
body {
  position: relative;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }

  .marker {
    background-image: url('../../../../../../web/medias/img/generics/mapbox-icon.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 30px;
    height: 45px;
    cursor: pointer;
  }

  main {
    position: relative;
    z-index: 5;
    width: 100%;

    .page {
      position: relative;
      z-index: 10;
      width: 100%;
      min-height: 100%;

      .scrollable {
        position: relative; // absolute on Smooth.init()
        top: 0;
        left: 0;
        z-index: 15;
        display: block;
        width: 100%;
      }

      /*********************
			Loader
			*********************/
      [data-src],
      [data-background] {
        opacity: 0;
        transition: opacity 300ms linear;

        &.media-loaded {
          opacity: 1;
        }
      }
    }
  }
}

/*********************
*********************/
.absolute {
  @include full-absolute();
}
.relative {
  @include full-relative();
}

*,
*::before,
*::after {
  outline: none;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

.clear {
  clear: both;
  width: 100%;
  height: 0;
  float: none;
  line-height: 0;
}
.clearfix::after {
  content: '';
  @extend .clear;
}

.ui-lg {
  display: block;
}
.ui-sm {
  display: none !important;
}
@media screen and (max-width: 950px) {
  .ui-lg {
    display: none !important;
  }
  .ui-sm {
    display: block !important;
  }
}
