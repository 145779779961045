.page.user.validation-request {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('../../../../../../../web/medias/img/desktop/pages/faq/faq-bg-split.png');
  background-size: 35%;
  margin: 0 auto;

  .validation {
    width: 100%;
    height: 100%;
    .ct.ct--breadcrumb {
      .inner {
        padding: 15px 25px;
      }
    }
    .content-validation {
      margin: 0 auto;
      .title-content {
        font-family: 'Montserrat Ultra';
        font-weight: 200;
      }
      .subtitle {
        font-family: 'Montserrat';
        font-weight: 100;
      }
      .text-content {
        font-family: 'Rawline';
        font-weight: 300;
        a {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }
}
