.ct.ct--key-figures {
  .container {
    .inner {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .figures {
        margin: 65px 0;
        width: 100%;
        display: block;
        .figure {
          text-align: center;
          padding: 0 30px;
          vertical-align: top;
          .number-figure {
            position: relative;
            color: $yellow;
            overflow: hidden;
            .inner-big-number {
              vertical-align: text-bottom;
            }
          }
          .title-figure {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
