@import '../../../variables/colors';

.page.login {
  font-family: 'Rawline';

  .title-2-login {
    font-family: 'Montserrat';
    font-weight: 300;
  }
  .link-login {
    text-decoration: underline;
    color: $black;
  }
  .ct--button {
    width: 100%;
    height: 50px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    .label-btn {
      margin: 0 auto;
    }
  }
  button.connexion {
    font-family: 'Montserrat';
    font-weight: 300;
  }

  .big-title-login {
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
    font-family: 'Montserrat';
    font-weight: 300;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $breadcrumb;
      margin-top: 30px;
    }
  }

  .container-col {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .new-account {
      .headline {
        margin-bottom: 20px;
        line-height: 20px;
      }
      .advantages-account {
        list-style: disc;
        margin-left: 15px;
        line-height: 17px;
      }
      .link-login {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
    }
    .connexion-account {
      padding: 30px 0 30px 130px;

      form {
        &.hidden-labels {
          .label {
            @include sr-only;
          }
        }
        .message-error {
          font-size: 1.5rem;
          font-family: $rawline;
        }
        .connexion {
          width: 100%;
          height: 50px;
          background: #f4c657;
          margin-top: 30px;
          display: flex;
          align-items: center;
          padding: 0 40px;
          border: none;
          cursor: pointer;

          span {
            display: inline-block;
            margin: 0 auto;
            text-align: center;
            color: #755a2b;
            font-size: 1.3rem;
          }

          @include ct--button-action;
        }
      }
      .separator {
        display: block;
        background-color: $breadcrumb;
        width: 100%;
        height: 1px;
        margin: 30px 0;
      }
      .button-linkedin {
        margin-bottom: 15px;
      }
      .button-google {
        margin-bottom: 20px;
      }
    }
  }
}

.sgp-body {
  .page.user.login {
    .connexion-account {
      form {
        .sgp-notice {
          margin-top: -10px;
          margin-bottom: 20px;
          padding: 20px;
          text-align: left;
          background: transparentize($yellow, 0.5);
        }
      }
    }
  }
}
