%text-1 {
  font-family: 'Rawline', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.9rem;
}

%text-1-mobile {
  @extend %text-1;
  font-size: 1.1rem;
  line-height: 1.5rem;
}
