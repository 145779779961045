.logos-slider {
  .splide__list {
    align-items: center;

    .splide__slide {
      img {
        max-width: 120px;
        max-height: 40px;
      }
    }
  }
}
