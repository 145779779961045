@import '../../variables/colors';
@import './search';

.search-main {
  margin-top: 80px;
  .search-wrapper {
    position: relative;
    display: flex;
    min-height: 600px;
    .search-result {
      .landing-section {
        .c6 {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
      .last-news {
        .news-wrapper {
          .about {
            padding: 10px;
            h3 {
              margin-top: 0;
            }
            a {
              text-align: center;
              font-size: 20px;
            }
          }
        }
      }
      width: calc(100% - 330px);
      position: relative;

      .search-list {
        margin-top: 65px;
        &.no-filters {
          margin-top: 0;
          .filters-bar {
            min-height: 0;
            display: none;
          }
          .sorts-bar {
            min-height: 0;
            display: none;
          }
        }
        .header-search {
          position: relative;
          width: 100%;
          height: 270px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 50px;
          background-position: center center;
          background-size: cover;
          &.header-search-animation {
            background-image: url('../../../../../../web/medias/img/desktop/pages/search/entertainment-header.jpg');
          }
          &.header-search-drink {
            background-image: url('../../../../../../web/medias/img/desktop/pages/search/drink-header.jpg');
          }
          &.header-search-menu {
            background-image: url('../../../../../../web/medias/img/desktop/pages/search/traiteur-header.jpg');
          }
          &.header-search-equipment {
            background-image: url('../../../../../../web/medias/img/desktop/pages/search/furniture-header.jpg');
          }
          &.header-search-staff {
            background-image: url('../../../../../../web/medias/img/desktop/pages/search/staff-header.jpg');
          }
          &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(55, 55, 55, 0.5);
          }
          .inner {
            position: relative;
            z-index: 5;
            text-align: center;
            color: $white;
            .headtitle {
              text-transform: uppercase;
              display: block;
              margin-bottom: 7px;
            }
          }
        }
        .filters-bar {
          position: fixed;
          top: 80px;
          width: calc(100% - 330px);
          @media screen and (min-width: 1600px) {
            width: 1270px;
          }
          background-color: $white;
          min-height: 65px;
          height: auto;
          border-bottom: 1px solid $dark-beige;
          padding: 0 20px;
          z-index: 20;
          .filters {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            height: 100%;
            .btn-category,
            .btn-filter {
              position: relative;
              display: flex;
              align-items: center;
              height: 100%;
              padding: 0 10px;
              border: 1px solid #e8e8e8;
              color: $black;
              @extend .rawline-regular;
              font-size: 1.3rem;
              //margin-right: 25px;
              @include ct--button-action($white, $black);
              &.active {
                background: $medium-beige;
              }
              .filter-quantity {
                @extend .rawline-bold;
                font-size: 0.9rem;
                position: absolute;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                right: -5px;
                top: -5px;
                background: $light-duck;
                color: $white;
                display: none;
                align-items: center;
                justify-content: center;
                line-height: 1px;
                &.show {
                  display: flex;
                }
              }
            }
            .left-content {
              position: relative;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              white-space: nowrap;
              .btn-filter {
                margin-right: 15px;
              }
              .filter {
                height: 35px;
                margin-bottom: 12px;
                position: relative;
                .ct--select-checkbox,
                .ct--select-checkbox-multiple,
                .ct--select-slider {
                  width: 210px;
                  margin-right: 10px;

                  .filter-choice {
                    position: absolute;
                    top: 0;
                    width: 100%;
                  }

                  .range-slider {
                    margin: 20px 0;
                  }

                  .filter-select-choice {
                    z-index: 1;
                    position: absolute;
                    top: 35px;
                    .generic-label {
                      display: none;
                    }
                  }
                }
              }
              .ct--select-checkbox-multiple {
                .select-choice,
                .all-choices {
                  &:hover {
                    background: $dark-beige;
                  }
                }
              }
            }
            .right-content {
              display: flex;
              align-items: center;
              text-align: center;
              align-self: center;
              order: 1;
              .filter {
                margin-bottom: 12px;
                margin-right: 15px;
                &:nth-last-child(1) {
                  margin-right: 0;
                }
                .btn-filter {
                  position: relative;
                  border: 1px solid $medium-beige;
                  height: 35px;
                  color: $black;
                  background-color: $white;
                  display: flex;
                  padding: 0 15px;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  .icon {
                    width: 13px;
                    height: 14px;
                  }
                  @include ct--button-action($white, $black);
                  &.date-hour,
                  &.all-filters {
                    .icon {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
            .popin-filter-search {
              position: absolute;
              right: 5px;
              top: 100%;
              width: 330px;
              max-height: calc(100vh - 150px);
              overflow-y: auto;
              @media screen and(max-width: 1280px) {
                width: 100%;
                right: 0;
                left: 0;
              }
              // height: 210px;
              z-index: 50;
              background: $white;
              display: none;
              padding: 40px 30px;
              border-right: 1px solid $dark-beige;
              border-left: 1px solid $dark-beige;
              border-bottom: 1px solid $dark-beige;
              // display: block;
              &.show {
                display: block;
              }
              .generic-label {
                @extend .rawline-regular;
                font-size: 1.5rem;
              }
              .btn-submit-search {
                justify-content: center;
                width: 100%;
                margin: 20px 0 0;
              }
              .underline {
                display: block;
                margin-top: 20px;
                font-size: 1.3rem;
                text-align: center;
                color: $black;
                text-decoration: underline;
              }
            }
            .popin-all-filters {
              position: absolute;
              right: 5px;
              top: 100%;
              width: 330px;
              max-height: calc(100vh - 150px);
              overflow-y: auto;
              @media screen and(max-width: 1280px) {
                width: 100%;
                right: 0;
                left: 0;
                .filter,
                .ct--button {
                  max-width: 390px !important;
                  margin-left: auto !important;
                  margin-right: auto !important;
                }
                .ct--button {
                  display: block;
                  .label-btn {
                    display: block;
                    line-height: 50px;
                  }
                }
              }
              // height: 356px;
              z-index: 20;
              background: $white;
              padding: 40px 30px;
              border-right: 1px solid $dark-beige;
              border-left: 1px solid $dark-beige;
              border-bottom: 1px solid $dark-beige;
              display: none;
              .underline {
                display: block;
                margin-top: 20px;
                @extend .rawline-regular;
                font-size: 1.3rem;
                text-align: center;
                color: $black;
                text-decoration: underline;
              }
              &.show {
                display: block;
              }
              .generic-label {
                @extend .rawline-regular;
                font-size: 1.5rem;
                display: block;
                margin-bottom: 10px;
              }
              .filter {
                position: relative;
                margin-bottom: 40px;
                &.hidden {
                  display: none;
                }
                &.range-filter {
                  &:before {
                    display: none;
                  }
                }
                &:before {
                  position: absolute;
                  content: '';
                  width: 100%;
                  height: 1px;
                  left: 0;
                  bottom: -20px;
                  background: $dark-beige;
                }
                .ct--filter {
                  .filter-choice {
                    height: 25px;
                    background: $medium-beige;
                    @include ct--button-action($medium-beige, $black);
                    .response-filter {
                      font-size: 13px;
                      overflow: visible;
                    }
                  }
                  .select-choice-overflow {
                    max-height: 100px;
                  }
                  .select-choice,
                  .head-choice {
                    background: $medium-beige;
                    padding: 5px 15px;
                    // font-size: 1rem;
                    line-height: initial;
                    @include ct--button-action($medium-beige, $black);
                    label {
                      // font-size: 1rem;
                      line-height: auto;
                    }
                  }
                  .head-choice {
                    span {
                      font-weight: bold;
                    }
                  }
                  .select-choice {
                    &:hover {
                      background: $light-beige;
                    }
                  }
                }
              }
              .btn-submit-all-filters {
                justify-content: center;
                width: 100%;
                margin: 20px 0;
              }
              // &.show {
              // display: bock;
              // }
            }
          }
        }
        .ct--breadcrumb {
          padding: 10px 25px 0;
          margin: 0;
          .inner {
            padding: 10px 0 25px;
          }
        }
        .title-page-result {
          padding: 0 25px;
          margin-bottom: 25px;
        }
        .sorts-bar {
          position: relative;
          z-index: 5;
          display: flex;
          padding: 0 25px;
          margin-bottom: 20px;
          justify-content: space-between;
          .result-number {
            padding-top: 10px;
          }
          .sort {
            .ct--select-checkbox {
              width: 140px;
              height: 25px;
              .generic-label {
                display: none;
              }
              .filter-choice {
                height: 25px;
                font-size: 1rem;
              }
              .filter-select-choice {
                .select-choice {
                  padding: 10px 15px;
                }
              }
              .label {
                font-size: 1.2rem;
                line-height: 1.5rem;
                padding-right: 15px;
              }
            }
          }
        }
        // custom no result block
        .search-no-result {
          display: flex;
          align-items: center;
          .search-no-result-left,
          .search-no-result-right {
            padding: 50px;
            width: 50%;
          }
          .search-no-result-left {
            .title-1 {
              margin-bottom: 20px;
            }
            .text-1 {
              line-height: 1.5;
              margin-bottom: 20px;
            }
          }
          .search-no-result-right {
            text-align: center;
            .img-wrapper {
              position: relative;
              display: inline-block;
              width: 80%;
              &:after {
                content: '';
                background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
                  center center repeat;
                position: absolute;
                top: -20px;
                bottom: 20px;
                left: 20px;
                right: -20px;
                z-index: 1;
              }
              img {
                max-width: 100%;
                z-index: 2;
                position: relative;
              }
            }
          }
        }
        .no-results {
          padding: 25px;
          @extend .rawline-regular;
          font-size: 1.5rem;
          text-align: center;
          width: 100%;
        }
        .all-results {
          padding: 0 25px;
          display: flex;
          flex-wrap: wrap;
          .ct--item-result,
          .ct--item-result-custom {
            width: calc(50% - 12px);
            margin-bottom: 15px;
            &:nth-child(odd) {
              margin-right: 24px;
            }
          }
        }
        .pagination {
          @extend .rawline-regular;
          font-size: 1.3rem;
          // margin: 40px 0 80px 0;
          margin: 40px auto 80px;
          .pages-numbers {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
              width: 48px;
              height: 48px;
              border: 1px solid $medium-beige;
              margin: 0 1px;
              &.dots {
                border: none;
                &:hover {
                  background: none;
                }
              }
              &.page-next,
              &.page-prev {
                .icon {
                  width: 5px;
                  height: 8px;
                }
              }
              &.page-prev {
                .icon {
                  transform: rotate(180deg);
                }
              }
              &.current,
              &:hover {
                background: $medium-beige;
              }
              a,
              span {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $black;
              }
            }
          }
          .about-results {
            margin: 20px 0;
            text-align: center;
          }
        }
      }
    }
    .search-sidebar {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $light-beige;
      width: 330px;
      height: calc(100%);
      // height: 100%;
      // top: 0;
      z-index: 30;
      &:before {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        z-index: 15;
        background: rgba(55, 55, 55, 0.5);
        transition: opacity 0.4s $ease-out-cubic,
          visibility 0.4s $ease-out-cubic;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
      }
      &.basket-submenu-open {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
      &.fixed {
        position: fixed;
        top: 80px;
        margin-right: 0;
        height: calc(100vh - 80px);
        @media screen and (min-width: 1600px) {
          right: 50%;
          margin-right: -800px;
        }
      }
      > .headline {
        position: relative;
        z-index: 20;
        background: $medium-beige;
        height: 65px;
        width: 100%;
        > ul {
          display: flex;
          justify-content: space-around;
          height: 100%;
          > li {
            // position: relative;
            display: flex;
            text-transform: uppercase;
            height: 100%;
            align-items: center;
            cursor: pointer;
            // position: relative; @todo
            .label-1 {
              position: relative;
              height: 100%;
              display: flex;
              align-items: center;
              &:before {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                bottom: -8px;
                left: 50%;
                margin-left: -7px;
                border-style: solid;
                border-width: 8px 7.5px 0;
                border-color: $medium-beige transparent transparent transparent;
                display: none;
              }
            }
            &.active {
              .label-1:before {
                display: block;
              }
            }
            &.your-demand {
              span {
                position: relative;
                .quantity {
                  @extend .rawline-bold;
                  font-size: 0.9rem;
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  border-radius: 50%;
                  right: -17px;
                  top: 15px;
                  background: $light-duck;
                  color: $white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
              .arrow {
                position: absolute;
                top: 27px;
                right: 9px;
                transform-origin: center;
                transition: transform 0.4s $ease-out-cubic;
                &.is-open {
                  transform: rotate(180deg);
                }
                svg {
                  height: 10px;
                  width: 10px;
                  fill: $medium-grey;
                }
              }
              .basket-options-dropdown {
                //display: none;
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background-color: $medium-beige;
                overflow: hidden;
                max-height: 0;
                transition: max-height 0.5s $ease-out-cubic;
                .basket-option {
                  position: relative;
                  display: block;
                  height: 65px;
                  line-height: 65px;
                  padding: 0 25px;
                  cursor: pointer;
                  &:hover {
                    background-color: darken($medium-beige, 10);
                  }
                  &:first-child {
                    .basket-option-label {
                      border-top: 1px solid $dark-beige;
                    }
                  }
                  &:last-child {
                    .basket-option-label {
                      border-bottom: none;
                    }
                  }
                  .basket-option-label {
                    display: block;
                    font-size: 1.3rem;
                    font-family: $rawline;
                    text-transform: none;
                    color: $medium-grey;
                    border-bottom: 1px solid $dark-beige;
                  }
                  .icon {
                    position: absolute;
                    right: 25px;
                    top: 20px;
                    width: 20px;
                    height: 20px;
                    fill: $medium-grey;
                    color: $medium-grey;
                  }
                  svg {
                    fill: $medium-grey;
                  }
                }
              }
            }
          }
        }
      }
      .response-sidebar {
        position: relative;
        height: calc(100% - 65px);
        border-left: 1px solid $dark-beige;
        // border-left: solid 1px #e0dcd8;
        .card {
          // display: none;
          height: 100%;
          .ct--map {
            height: 100%;
          }
          .map-container {
            width: 100%;
            height: 100%;
            &.fallback {
              .fallback-container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 75%;
                text-align: center;
                p {
                  color: #83807d;
                }
              }
            }
            .marker {
              height: 40px;
              width: 40px;
              background-color: $yellow;
              background-size: cover;
              border: 3px solid $yellow;
              border-radius: 50px;
              transition: border-color 0.15s ease, height 0.15s ease,
                width 0.15s ease;
              &.active,
              &:hover {
                border-color: #efad10;
                height: 44px;
                width: 44px;
              }
            }
          }
        }
        .filter-items {
          padding: 25px 25px 45px;
          height: calc(100% - 105px);
          overflow-y: auto;
          background: $white;
          .filter-item {
            margin-bottom: 15px;
            a {
              color: $black;
              &.underline {
                text-decoration: underline;
              }
            }
            &.center {
              text-align: center;
            }
            .title-sidebar-filter {
              @extend .rawline-regular;
              font-size: 1.5rem;
              display: block;
              margin-bottom: 10px;
            }
            .generic-label {
              display: inline-block;
              @extend .rawline-regular;
              font-size: 1.5rem;
              line-height: 1.25;
              margin-bottom: 15px;
              color: inherit;
              .bullet {
                background-color: transparentize($yellow, 0.4);
                color: $bobby-brown;
                border-radius: 50%;
                display: inline-block;
                font-size: 0.8em;
                height: 20px;
                width: 20px;
                line-height: 18px;
                text-align: center;
                margin-right: 2px;
                position: relative;
                top: -2px;
              }
            }
            .ct--select-slider {
              .generic-label {
                margin-bottom: 25px;
                display: block;
              }
            }
            &.line-calendar {
              .calendar-filter-date {
                display: block;
                width: 100%;
                height: 35px;
                background: #fdf4e9 !important;
                @extend .rawline-regular;
                background: $medium-beige !important;
                font-size: 1.3rem;
                font-weight: bold;
              }
              .flatpickr-wrapper {
                display: block;
                .flatpickr-calendar {
                  width: 100%;
                  padding: 0 10px;
                }
              }
              .multi-day {
                display: block;
                margin-top: 20px;
                @extend .rawline-regular;
                font-size: 1.3rem;
                text-align: center;
              }
            }
            &.hour-line {
              position: relative;
              margin-top: 40px;
              &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background: $dark-beige;
                left: 0;
                top: -15px;
              }
            }
            .line-from-to {
              position: relative;
              display: flex;
              margin-top: 30px;
              &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background: $dark-beige;
                left: 0;
                top: -15px;
              }
              .col {
                width: calc(50% - 5px);
                &:nth-child(1) {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .basket {
          // position: relative;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 10;
          .is-empty {
            .empty-container {
              display: block;
            }
            .basket-container {
              display: none;
            }
          }
          .empty-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 75%;
            display: none;
            text-align: center;
            .empty-title {
              font-family: $montserrat;
              font-size: 2rem;
              line-height: 2.4rem;
              font-weight: 300;
              font-style: normal;
              color: $black;
              display: block;
              padding-bottom: 20px;
            }
            .empty-description {
              font-family: $rawline;
              font-size: 1.3rem;
              line-height: 1.7rem;
              color: $basket-li-accessible;
            }
          }
          .basket-container {
            height: 100%;
            display: block;
            .basket-result {
              height: 100%;
            }
          }
        }
        .basket-items {
          padding: 15px;
          height: calc(100% - 105px);
          overflow-y: auto;
          .basket-item {
            background: $white;
            padding: 25px;
            margin-bottom: 10px;
            &.place {
              .details {
                ul {
                  li {
                    font-size: 1.1rem;
                  }
                }
              }
            }
            .headline {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
              &:hover {
                .remove-place {
                  display: flex;
                }
              }
              .col {
                .type {
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
                  .icon {
                    width: 11px;
                    height: 17px;
                    margin-right: 10px;
                  }
                }
                .name {
                  display: block;
                }
                .price {
                  display: block;
                }
                &.left {
                  width: 80px;
                }
                &.right {
                  text-align: right;
                }
              }
              .edit-event {
                position: relative;
                top: 2px;
                height: 14px;
                width: 14px;
                padding: 0;
                border: 0;
                cursor: pointer;
                background-color: transparent;
                align-items: center;
                justify-content: center;

                .icon {
                  color: $basket-li;
                  height: 12px;
                  width: 12px;
                }

                &:hover {
                  .icon {
                    color: #666;
                  }
                }
              }
              .remove-place {
                position: relative;
                height: 14px;
                width: 14px;
                padding: 0;
                border: 0;
                cursor: pointer;
                display: none;
                // display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                .icon {
                  width: 10px;
                  height: 10px;
                  fill: $basket-li;
                }
              }
            }
            .details {
              ul {
                li {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  color: $basket-li;
                  width: 100%;
                  padding: 5px 0;
                  border-top: 1px dotted $basket-li;
                  font-size: 1.3rem;
                  line-height: 1.4rem;
                  span {
                    &.item-name {
                      // width: 125px;
                      padding-right: 15px;
                    }
                    strong {
                      display: block;
                      font-size: 1.1rem;
                      margin-top: 3px;
                    }
                    .icon {
                      width: 9px;
                      height: 12px;
                      fill: $basket-li;
                      margin-left: 3px;
                      &.svg-panier_picto_bouteille {
                        width: 7px;
                        height: 16px;
                      }
                      &.svg-panier_picto_clock {
                        width: 11px;
                        height: 11px;
                      }
                    }
                    &.quantity-col {
                      display: flex;
                      align-items: center;
                    }
                  }
                  .right {
                    display: flex;
                    align-items: center;
                    .quantity-item {
                      display: flex;
                      align-items: center;
                      &:nth-of-type(2) {
                        margin-left: 10px;
                      }
                      .controllers {
                        width: 15px;
                        height: 30px;
                        background: $white;
                        border: 1px solid $basket-li;
                        margin: 0 7px 0 5px;
                        display: none;
                        button {
                          position: relative;
                          height: 14px;
                          width: 100%;
                          padding: 0;
                          border: 0;
                          cursor: pointer;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          span {
                            position: absolute;
                            width: 7px;
                            height: 1px;
                            left: 50%;
                            top: 50%;
                            margin-left: -3.5px;
                            margin-top: -0.5px;
                            background: $basket-li;
                          }
                          &.more-quantity {
                            border-bottom: 1px solid $basket-li;
                            span {
                              &:nth-child(1) {
                                transform: rotate(90deg);
                                transform-origin: center center;
                              }
                            }
                          }
                        }
                      }
                    }
                    .duration-time {
                      display: flex;
                      align-items: center;
                      .icon {
                        margin-top: 3px;
                      }
                    }
                  }
                  .remove-item {
                    position: relative;
                    height: 14px;
                    width: 14px;
                    padding: 0;
                    border: 0;
                    cursor: pointer;
                    background-color: transparent;
                    align-items: center;
                    justify-content: center;
                    margin-left: 5px;
                    .icon {
                      width: 10px;
                      height: 10px;
                      fill: $basket-li;
                    }
                    &:hover {
                      .icon {
                        fill: #666;
                      }
                    }
                  }
                }
              }
            }
            .additional-infos {
              color: #83807d;
              font-family: $rawline;
              font-size: 1.2rem;
              margin-top: 4px;
            }
            .services {
              margin-top: 20px;
              .title-services {
                display: block;
                margin-bottom: 15px;
              }
              ul {
                li {
                  display: flex;
                  justify-content: space-between;
                  color: $black;
                  width: 100%;
                  padding: 2px 0;
                  font-size: 1.1rem;
                }
              }
            }
          }
          .vouncher-item {
            display: flex;
            background: $white;
            padding: 25px;
            margin-bottom: 10px;
            align-items: center;
            .icon {
              width: 29px;
              height: 18px;
              margin-right: 15px;
            }
          }
          .delivery-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 25px 0;
            padding: 0 10px;
            .col {
              display: flex;
              align-items: center;
              .icon {
                width: 37px;
                height: 18px;
                margin-right: 15px;
              }
            }
          }
          .clear-selection {
            text-align: center;
            margin: 20px auto;
            .clear-selection-button {
              @extend .text-1;
              background-color: transparent;
              border-color: transparent;
              color: #444;
              cursor: pointer;
              font-size: 1.25rem;
              .icon {
                position: relative;
                top: 1px;
                margin-right: 3px;
              }
              &:hover {
                color: #000;
              }
            }
          }
        }
        .total-basket,
        .total-options {
          padding: 25px;
          border-top: 1px solid $dark-beige;
          height: auto;
          min-height: 105px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.1);
          background: #fff8f0;
          .about {
            display: flex;
            justify-content: space-between;
            .col {
              display: flex;
              flex-direction: column;
              text-transform: uppercase;
              &:nth-last-child(1) {
                text-align: right;
              }
              span {
                margin-top: 5px;
                color: $basket-li;
              }
              .js-options-subprice {
                margin-top: 5px;
                color: $basket-li;
              }
            }
          }
          .btn-submit-basket,
          .btn-add-item {
            width: 100%;
            margin-top: 15px;
            justify-content: center;
          }
          .btn-submit-basket {
            background: $yellow;
            line-height: 1.25;
            padding: 10px;
          }
          .btn-submit-basket-new {
            height: auto;
            line-height: 1.5;
            padding: 10px 18px;
            width: 100%;
          }
        }
        &.no-cta {
          .basket-items {
            height: 100%;
          }
          .total-basket {
            display: none;
          }
        }
      }
    }
  }
}
