@import 'faq';

.page.faq {
  position: relative;
  padding-top: 200px;
  text-align: center;
  background-position: right top;
  background-repeat: no-repeat;
  background-image: url('../../../../../../web/medias/img/desktop/pages/faq/faq-bg-split.png');
  background-size: 426px 840px;

  .ct--breadcrumb {
    position: absolute;
    top: 115px;
    left: 25px;
    text-align: left;
    width: 100%;
    // width: auto;
  }

  &.transmission-request {
    .ct--breadcrumb {
      top: 140px;
    }
  }

  .big-title-faq {
    text-align: center;
    color: $yellow;
    margin-bottom: 100px;
  }

  .sup-big-title {
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    text-align: center;

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $yellow;
      }
    }
  }

  .sub-big-title {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
    display: inline-block;
    text-align: center;
  }

  .container {
    text-align: left;

    .wrapper-expand {
      margin-bottom: 30px;

      .expand {
        height: 0;
        transition: height 500ms ease-in-out;
        overflow: hidden;

        .inner-expand {
          padding-top: 25px;
          padding-bottom: 30px;
          > p,
          > ul li {
            @extend .text-1;
            a {
              color: $yellow;
            }
          }
          > ul li {
            display: block;
            margin-left: span(0.5 of 8);
            position: relative;

            &::before {
              position: absolute;
              content: '';
              left: -8px;
              width: 4px;
              height: 1px;
              background: $yellow;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .topic-row {
      padding-top: 45px;
      padding-bottom: 85px;
      .wrapper-expand {
        &.open {
          .title-topic {
            svg {
              transform: rotate(180deg);
            }
          }
        }
        .title-topic {
          position: relative;
          padding-right: 40px;
          cursor: pointer;
          font-size: 2.3rem;
          svg {
            position: absolute;
            right: 0;
            top: 4px;
            width: 16px;
            height: 16px;
            fill: $medium-grey;
            transform-origin: center center;
            transition: transform 0.4s $ease-out-cubic;
          }
        }
      }
    }

    .ask-row {
      margin-top: 30px;
      padding-left: span(1);
      padding-right: span(1);
      .title-ask-row {
        font-size: 2.6rem;
        padding: 35px 0;
        border-bottom: solid 1px $dark-beige;
        border-top: solid 1px $dark-beige;
      }
    }

    .topic-row {
      padding-top: 45px;

      .know-more {
        margin-top: 25px;
      }
    }

    .personal-row {
      padding-left: span(1);
      padding-right: span(1);
      .title-personal-row {
        font-size: 2.6rem;
        padding: 35px 0;
        border-top: solid 1px $dark-beige;
        border-bottom: solid 1px $dark-beige;
      }
    }
  }
}
