.ct.ct--navbar
  .first-row-navbar
  .first-list-navbar
  .first-list-nav
  .elements-first-list-nav.admin {
  $color: darken($yellow, 5%);
  a,
  svg {
    color: $color;
  }
  a::after {
    background-color: $color;
  }
}
