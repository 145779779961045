.generic-modal-manager {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: 9990;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  .generic-modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9990;
    pointer-events: auto;
  }

  .generic-modal {
    position: relative;
    width: 1024px;
    pointer-events: none;
    display: flex;
    align-items: center;
    max-width: 90%;
    margin: 1.75rem auto;
    min-height: calc(100% - 3.5rem);
    z-index: 9999;
    .generic-modal-content {
      padding: 10px;
      position: relative;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      outline: 0;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .close-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 2rem;
      position: absolute;
      right: 30px;
      top: 30px;

      svg {
        fill: #ccc;
      }
    }

    .visual,
    .functional {
      padding: 50px 70px;
      float: left;
      width: 50%;
      text-align: center;
    }

    .visual {
      padding-right: 35px;

      .img-wrapper {
        position: relative;
        img {
          max-width: 100%;
          position: relative;
          z-index: 2;
        }
      }
    }

    .functional {
      padding-left: 35px;
    }

    .full {
      padding: 50px 70px;
    }

    .content {
      text-align: center;

      h2 {
        line-height: 1.25;

        small {
          display: block;
          font-size: 1.5rem;
          margin-top: 10px;
        }

        &:after {
          background: $yellow;
          content: '';
          display: block;
          height: 5px;
          margin: 25px auto 35px;
          width: 20%;
        }

        &.infos {
          &:after {
            background: $light-duck;
          }
        }
      }

      p {
        line-height: 1.5;
        margin-bottom: 35px;
      }
    }

    .cta {
      border: 1px solid $yellow;
      background-color: $yellow;
      color: $black;
      cursor: pointer;
      font-family: $montserrat;
      font-size: 13px;
      height: 40px;
      line-height: 40px;
      padding: 0 18px;
      text-transform: uppercase;
      white-space: nowrap;

      &:hover {
        background-color: darken($yellow, 10%);
        border-color: darken($yellow, 10%);
        color: darken($black, 15%);
      }

      &:disabled {
        opacity: 0.75;
      }

      &.cta-clear {
        background-color: #fff;
        border: 1px solid $yellow;

        &:hover {
          background-color: darken(#fff, 10%);
        }
      }

      &.cta-infos {
        border-color: $light-duck;
        background-color: $light-duck;
        color: #fff;

        &:hover {
          background-color: darken($light-duck, 10%);
        }

        &.cta-clear {
          background-color: #fff;
          border: 1px solid $light-duck;
          color: $black;

          &:hover {
            background-color: darken(#fff, 10%);
          }
        }
      }

      svg {
        color: $black;
        fill: $black;
        font-size: 13px;
        margin-right: 4px;
        position: relative;
        top: 1px;
      }
    }
  }
}
