button.ct.ct--button {
  border: none;
  background: transparent;
}

.ct.ct--button {
  position: relative;
  background: $yellow;
  height: 50px;
  padding: 0 40px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  @include ct--button-action;

  &.arrow {
    display: inline-flex;
    align-items: center;
    color: $bobby-brown;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $light-duck;
      z-index: 1;
      transform: scaleX(0);
      transform-origin: 100% 0%;
      transition: transform 350ms $ease-in-out-quad;
    }

    .label-btn {
      position: relative;
      z-index: 2;
      text-align: left;
      margin-right: 25px;
      transition: color 350ms $ease-in-out-quad;
      line-height: 1.3;
    }

    .arrow {
      position: relative;
      z-index: 2;
      transform: translateX(0);
      transition: transform 250ms $ease-in-out-quad;

      .icon {
        height: 10px;
        width: 5px;
        fill: $bobby-brown;
        transition: fill 350ms $ease-in-out-quad;
      }
    }

    &.arrow-blue {
      background: $light-duck;
      color: $white;

      &:before {
        background: $yellow;
      }

      .label-btn {
        color: $white;
      }

      .arrow {
        .icon {
          fill: $white;
        }
      }

      &:hover {
        .label-btn {
          color: $bobby-brown;
        }

        .arrow {
          .icon {
            fill: $bobby-brown;
          }
        }
      }
    }
  }

  &.text {
    display: inline-flex;
    align-items: center;
    background: transparent;
    padding: 0;
    height: 30px;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background: $yellow;
      bottom: 0;
      left: 0;
      transform: scaleX(1) translateZ(0);
      transform-origin: 0% 100%;
      backface-visibility: hidden;
      transition: background 350ms $ease-in-out-quad 0ms,
        transform 350ms $ease-in-out-quad 0ms;
    }

    .label-btn {
      @extend .montserrat-regular;
      text-align: left;
      color: $yellow;
      margin-right: 30px;
      font-size: 1.2rem;
      transition: color 350ms $ease-in-out-quad;
      backface-visibility: hidden;
    }

    .arrow {
      color: $yellow;
      transition: transform 350ms $ease-in-out-quad 0ms;

      .icon {
        height: 10px;
        width: 21px;
        fill: $yellow;
        transition: fill 350ms $ease-in-out-quad 0ms;
      }
    }
  }

  &.blue-steel {
    @include ct--button-action($light-duck, $white, lighten);
  }

  .label-btn {
    display: inline-block;
    vertical-align: middle;
    color: $bobby-brown;
    @extend .montserrat-light;
    font-size: 1.3rem;
  }

  &.cta {
    border: 2px solid $yellow;
    background-color: $yellow;
    color: $black;
    cursor: pointer;
    font-family: $montserrat;
    font-size: 13px;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
      background-color: darken($yellow, 10%);
      border-color: darken($yellow, 10%);
      color: darken($black, 15%);
    }

    &:disabled {
      opacity: 0.75;
    }

    &.cta-clear {
      background-color: #fff;
      border: 1px solid $yellow;

      &.cta-yellow-hover:hover {
        background-color: $yellow;
      }

      &:hover {
        background-color: darken(#fff, 10%);
      }
    }

    &.cta-infos {
      border-color: $light-duck;
      background-color: $light-duck;
      color: #fff;

      &:hover {
        background-color: darken($light-duck, 10%);
      }
    }

    svg {
      color: $black;
      fill: $black;
      font-size: 13px;
      margin-right: 4px;
      position: relative;
      top: 1px;
    }
  }
}

.cta-spacer {
  margin-top: 40px;
}
