@import 'item-result';

.ct.ct--item-result {
  // FIX TABLET for listing pages
  &.drink {
    @media screen and (max-width: 1115px) {
      width: 100% !important;
      margin: 0 auto 15px !important;
    }
  }
  &.cocktail,
  &.place,
  &.menu {
    @media screen and (max-width: 1024px) {
      width: 100% !important;
      margin: 0 auto 15px !important;
    }
  }
  &.furniture,
  &.staff {
    @media screen and (max-width: 1280px) {
      width: 100% !important;
      margin-right: 0 !important;

      .choose-option {
        width: 100%;
      }
    }
  }
}

.ct.ct--item-result-custom {
  border: 5px solid #fff;
  cursor: pointer;
  position: relative;
  z-index: 1;
  .picture {
    aspect-ratio: 508/366;
    background: url('./../../../img/pages/location-de-salle/paris/app-my-snapevent.jpg')
      center center no-repeat;
    background-size: cover;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(55, 55, 55, 0.55);
      transition: background-color 0.2s ease;
    }
    .picture-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      span {
        margin: 0 auto;
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.15) 1px 4px 4px;
        z-index: 10;
        color: $white;
        @extend .vanitas-bold;
        font-size: 4.5rem;
        display: block;
        small {
          display: block;
          font-size: 3rem;
        }
      }
    }
    .picture-link {
      position: absolute;
      bottom: 20px;
      right: 20px;
      text-transform: uppercase;
      color: $yellow;
      font-family: $montserrat;
      .arrow {
        color: $yellow;
        fill: $yellow;
        font-size: 2rem;
        margin-left: 15px;
        position: relative;
        top: 5px;
      }
    }
    svg.svg-jo {
      display: block;
      width: 80px;
      aspect-ratio: 1020/495;
      position: absolute;
      height: auto;
      top: 20px;
      right: 20px;
      fill: #fff;
    }
  }
  .content {
    color: $black;
    line-height: 1.25;
    padding: 20px 20px 25px;
  }
  &:hover {
    .picture:before {
      background-color: rgba(55, 55, 55, 0.75);
    }
    .content {
      background-color: $medium-grey;
      color: #fff;
    }
  }
}
