@import 'zones';

#page-zones {
  %pattern {
    background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
      center center repeat;
    content: '';
    height: 66%;
    position: absolute;
    width: 66%;
    z-index: 1;
  }

  padding-top: 90px;

  .section-arrondissements,
  .section-departements {
    padding: 60px 0;
  }

  h1 {
    font-size: 7rem;
  }

  h2 {
    font-size: 6rem;
  }

  .zones-wrapper {
    position: relative;
    z-index: 2;

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9) {
      &:after {
        @extend %pattern;
        right: -25px;
        top: -25px;
      }
    }

    &:nth-child(3),
    &:nth-child(7),
    &:nth-child(11) {
      &:after {
        @extend %pattern;
        left: -25px;
        top: -25px;
      }
    }

    .ct--zone-item {
      z-index: 2;
    }
  }

  .section-departements {
    .zones-wrapper {
      &:last-child {
        &:after {
          width: calc(100% / 3);
        }
      }
    }
  }

  .button-wrapper {
    .button-our-places {
      display: inline-block;
    }
  }
}
