@import 'search-enhanced';
@import '../../mixins/dropdown_toggle';

.search-main {
  .search-wrapper {
    .search-result {
      .search-list-enhanced {
        .filters-bar {
          border-bottom-color: $white;
          font-family: $rawline;
          .filters {
            align-items: unset;
            padding: 12px 0 1px;
            .left-content {
              .filter {
                height: 40px;
                margin-right: 10px;
                .control-label {
                  display: none; // todo : replace by text-indent
                }
                &.category-filter {
                  .choices__placeholder {
                    opacity: 1;
                  }
                }
                .btn-filter {
                  &.capacity-filter {
                    border-color: #e8e8e8;
                    cursor: pointer;
                    margin-right: 0;
                    padding-right: 40px;
                    width: 100%;
                    &:focus {
                      border-color: #c2c2c2;
                    }
                    &:hover {
                      background-color: #fff;
                    }
                    &::after {
                      @include dropdown-toggle($top: 50%);
                    }
                  }
                  &.date-filter {
                    border-color: #e8e8e8;
                    cursor: pointer;
                    margin-right: 0;
                    padding-right: 30px;
                    width: 100%;
                    .js-date-label {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      max-width: 155px;
                      display: block;
                    }
                    &:focus {
                      border-color: #c2c2c2;
                    }
                    &:hover {
                      background-color: #fff;
                    }
                    &::after {
                      @include dropdown-toggle($top: 50%);
                    }
                  }
                }
              }
            }
            .right-content {
              .filter {
                .btn-filter {
                  border-color: #e8e8e8;
                  font-family: $montserrat;
                  font-weight: 300;
                  font-size: 13px;
                  height: 40px;
                  .icon {
                    color: $light-duck;
                    width: 15px;
                    height: 15px;
                  }
                  &:focus {
                    border-color: #c2c2c2;
                  }
                  &.all-filters {
                    .icon {
                      color: $light-duck;
                      fill: $light-duck;
                    }
                  }
                }
              }
            }
            .popin-filter-search {
              background: transparent;
              border: none;
              display: none;
              padding: 20px 10px;
              right: 0;
              .inner {
                position: relative;
                background: $white;
                border: 1px solid $dark-beige;
                padding: 40px 30px 30px;
                &:before {
                  content: '';
                  background-color: $white;
                  display: block;
                  position: absolute;
                  top: -15px;
                  right: 15px;
                  width: 30px;
                  height: 30px;
                  border-top: 1px solid $dark-beige;
                  border-left: 1px solid $dark-beige;
                  transform: rotate(45deg);
                }
              }
              .generic-label,
              .control-label {
                @extend .rawline-regular;
                font-size: 1.5rem;
                margin-bottom: 15px;
              }
              .form-control {
                width: 100%;
              }
              .filter-actions {
                margin-top: 30px;
                .clear-filters,
                .apply-filters {
                  display: block;
                  margin: 0;
                  width: 100%;
                }
                .clear-filters {
                  margin-top: 10px;
                }
              }
            }
            .popin-filter-date {
              background: transparent;
              border: none;
              display: none;
              padding: 10px;
              position: absolute;
              left: 475px;
              top: 100%;
              width: 316px;
              z-index: 50;
              &.show {
                display: block;
              }
              .btn-submit-search {
                justify-content: center;
                width: 100%;
                margin: 20px 0 0;
              }
              .underline {
                display: block;
                margin-top: 20px;
                font-size: 1.3rem;
                text-align: center;
                color: $black;
                text-decoration: underline;
              }
              .inner {
                position: relative;
                background: $white;
                border: 1px solid $dark-beige;
                padding: 10px 20px 20px;
                height: 305px;
                min-height: 305px;
                max-height: 305px;
                &:before {
                  content: '';
                  background-color: $white;
                  display: block;
                  position: absolute;
                  top: -15px;
                  left: 132px;
                  width: 30px;
                  height: 30px;
                  border-top: 1px solid $dark-beige;
                  border-left: 1px solid $dark-beige;
                  transform: rotate(45deg);
                }
              }
              .generic-label,
              .control-label {
                @extend .rawline-regular;
                font-size: 1.5rem;
                margin-bottom: 15px;
              }
              .form-control {
                width: 80px;
                margin-right: 12px;
                appearance: none;
                text-align: center;
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                  appearance: none;
                }
              }
              .filter-actions {
                margin-top: 30px;
                .apply-filters {
                  display: block;
                  margin: 0;
                  width: 100%;
                }
                .clear-filters {
                  margin-top: 10px;
                }
              }
              .flatpickr-input {
                display: none;
              }
              .expanded-choice-list {
                margin-top: 10px;
                label {
                  display: block;
                  padding: 16px 20px;
                  background: #fff;
                  border: 1px solid #e8e8e8;
                  margin: 0 0 6px;
                  cursor: pointer;
                  &:hover,
                  &:active,
                  &:focus {
                    background: #f9f9f9;
                  }
                  input {
                    display: none;
                  }
                }
              }
            }
            .popin-filter-capacity {
              background: transparent;
              border: none;
              display: none;
              padding: 20px 10px;
              right: 0;
              position: absolute;
              left: 80px;
              top: 100%;
              width: 260px;
              max-height: calc(100vh - 150px);
              overflow-y: auto;
              @media screen and(max-width: 1280px) {
                width: 100%;
                right: 0;
                left: 0;
              }
              z-index: 50;
              &.show {
                display: block;
              }
              .btn-submit-search {
                justify-content: center;
                width: 100%;
                margin: 20px 0 0;
              }
              .underline {
                display: block;
                margin-top: 20px;
                font-size: 1.3rem;
                text-align: center;
                color: $black;
                text-decoration: underline;
              }
              .inner {
                position: relative;
                background: $white;
                border: 1px solid $dark-beige;
                padding: 40px 30px 30px;
                &:before {
                  content: '';
                  background-color: $white;
                  display: block;
                  position: absolute;
                  top: -15px;
                  left: 100px;
                  width: 30px;
                  height: 30px;
                  border-top: 1px solid $dark-beige;
                  border-left: 1px solid $dark-beige;
                  transform: rotate(45deg);
                }
              }
              .generic-label,
              .control-label {
                @extend .rawline-regular;
                font-size: 1.5rem;
                margin-bottom: 15px;
              }
              .form-control {
                width: 80px;
                margin-right: 12px;
                appearance: none;
                text-align: center;
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                  appearance: none;
                }
              }
              .filter-actions {
                margin-top: 30px;
                .clear-filters,
                .apply-filters {
                  display: block;
                  margin: 0;
                  width: 100%;
                }
                .clear-filters {
                  margin-top: 10px;
                }
              }
            }
            .popin-all-filters {
              background: transparent;
              border: none;
              display: none;
              left: 0;
              padding: 20px 10px;
              right: 0;
              width: 100%;
              @media screen and(max-width: 1280px) {
                left: 0;
                right: 0;
                width: 100%;
              }
              .inner {
                position: relative;
                background: $white;
                border: 1px solid $dark-beige;
                padding: 20px 20px 40px;
                &:before {
                  content: '';
                  background-color: $white;
                  display: block;
                  position: absolute;
                  top: -15px;
                  right: 100px;
                  width: 30px;
                  height: 30px;
                  border-top: 1px solid $dark-beige;
                  border-left: 1px solid $dark-beige;
                  transform: rotate(45deg);
                }
                .row {
                  display: flex;
                  margin-bottom: 20px;
                  width: 100%;
                  &.mb-0 {
                    margin-bottom: 0;
                  }
                  .col-4,
                  .col-5,
                  .col-6,
                  .col-7,
                  .col-8,
                  .col-12 {
                    display: inline-block;
                    padding-right: 20px;
                    &:last-child {
                      padding-right: 0;
                    }
                  }
                  .col-4 {
                    width: calc(100% / (12 / 4));
                  }
                  .col-5 {
                    width: calc(100% / (12 / 5));
                  }
                  .col-6 {
                    width: calc(100% / (12 / 6));
                  }
                  .col-7 {
                    width: calc(100% / (12 / 7));
                  }
                  .col-8 {
                    width: calc(100% / (12 / 8));
                  }
                  .col-12 {
                    width: 100%;
                  }
                }
              }
              &.popin-all-filters-small {
                left: auto;
                width: 330px;
                .inner {
                  &:before {
                    right: 100px;
                  }
                }
              }
              &.popin-all-filters-standalone {
                .inner {
                  &:before {
                    right: 35px;
                  }
                }
              }
              .generic-label,
              .control-label {
                @extend .rawline-regular;
                font-size: 1.5rem;
                display: block;
                margin-bottom: 10px;
              }
              .control-label {
                margin-bottom: 15px;
              }
              .filter-actions {
                .clear-filters,
                .apply-filters {
                  margin-bottom: 0;
                }
              }
              .filter {
                .ct--filter {
                  .select-choice,
                  .head-choice {
                    label {
                      line-height: unset;
                    }
                  }
                }
              }
              .calendar-filter-date {
                height: 40px !important;
              }
            }
          }
        }
        .sorts-bar {
          .sort {
            min-width: 140px;
          }
        }
      }
    }
  }
}
