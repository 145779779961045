@import 'grid';

.container {
  @include container(1600px);
}

@for $i from 1 through $columns {
  .m#{$i} {
    margin-left: percentage($i / $columns);
  }
  .c#{$i} {
    width: percentage($i / $columns);
    display: inline-block;
    @for $j from 1 through $columns {
      .c#{$j} {
        width: percentage($j / $columns) * ($columns / $i);
        @for $k from 1 through $columns {
          .c#{$k} {
            width: percentage($k / $columns) * ($columns / $j);
          }
          .m#{$k} {
            margin-left: percentage($k / $columns) * ($columns / $j);
          }
        }
      }
      .m#{$j} {
        margin-left: percentage($j / $columns) * ($columns / $i);
      }
    }
  }
}

.c#{$columns} {
  display: block;
}
