.ct.ct--double-col-slider {
  text-align: left;
  &.left {
    .container {
      .cols {
        .col.col-picture {
          .slider {
            margin-top: span(1 of 5);
          }
        }
      }
    }
  }
  .container {
    .cols {
      display: flex;
      align-items: stretch;
      .col {
        &.col-content {
          display: flex;
          align-items: center;
          .inner {
            position: relative;
            .content-double-col {
              margin: 20px 0 40px;
            }
          }
        }
        &.col-picture {
          position: relative;
          overflow: hidden;

          &.full-pic {
            .slider {
              &:before {
                padding-bottom: 70%;
              }
              .inner {
                background: $black;
                img {
                  min-width: 100%;
                  min-height: 100%;
                }
              }
              .arrow {
                position: absolute;
                z-index: 5;
                background: none;
                border: 0;
                top: 50%;
                width: 15px;
                height: 26px;
                padding: 0;
                margin-top: -13px;
                -webkit-appearance: none;
                cursor: pointer;
                &.left {
                  transform: rotate(180deg);
                  left: 30px;
                }
                &.right {
                  right: 30px;
                }
                .icon {
                  width: 15px;
                  height: 26px;
                  fill: $white;
                  transform: translateX(0);
                  transition: transform 250ms $ease-out-quad;
                }
                &:hover {
                  .icon {
                    transform: translateX(5px);
                  }
                }
              }
              .bullets {
                li {
                  width: 9px;
                  height: 9px;
                  margin: 0 2px;
                }
              }
            }
          }

          .headline {
            color: $yellow;
            position: absolute;
            left: 0;
            z-index: 5;
            text-transform: uppercase;
          }
          .slider {
            position: relative;

            &:hover {
              .inner::before {
                // Deactivating square gradient for now
                opacity: 0;
                // opacity: 1;
              }
              .inner::after {
                opacity: 0;
                // opacity: 1;
              }
              .bullets::before {
                opacity: 0;
                // opacity: 1;
              }
            }
            &:before {
              content: '';
              width: 100%;
              height: 0;
              display: block;
              padding-bottom: 100%;
            }
            &:after {
              position: absolute;
              content: '';
              width: 77%;
              height: 77%;
              background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
                center center repeat;
              z-index: 1;
              top: -span(1 of 5);
              right: -span(1 of 5);
            }
            .inner {
              position: absolute;
              width: 100%;
              height: 100%;
              background: $medium-grey;
              top: 0;
              left: 0;
              z-index: 2;
              overflow: hidden;

              &::before,
              &::after {
                content: ' ';
                width: 20%;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 9;
                opacity: 0;
                transition: ease-in-out 0.3s;
              }
              &::before {
                background: linear-gradient(
                  to left,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.45) 100%
                );
                left: 0;
              }
              &::after {
                background: linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.45) 100%
                );
                right: 0;
              }
              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                &:nth-child(1) {
                  opacity: 1;
                }
              }
            }
            .bullets {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              height: 14%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 5;
              &::before {
                content: ' ';
                background: linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.45) 100%
                );
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 9;
                opacity: 0;
                transition: ease-in-out 0.3s;
              }
              li {
                width: 12px;
                height: 12px;
                background: rgba(255, 255, 255, 0.2);
                display: inline-block;
                border-radius: 50%;
                margin: 0 3px;
                cursor: pointer;
                position: relative;
                z-index: 10;
                &.active {
                  background: rgba(255, 255, 255, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
