.wrapper-range-slider {
  margin-bottom: 35px;
}
.range-slider {
  background: $medium-beige;
  height: 5px;
  border: 0;
  .noUi-handle {
    width: 14px;
    height: 14px;
    background: $light-duck;
    border-radius: 50%;
    box-shadow: none;
    border: 0;
    top: -5px;
    cursor: pointer;
    &:before,
    &:after {
      display: none;
    }
  }
  .noUi-connect {
    background: $light-duck;
  }
  .noUi-tooltip {
    bottom: -20px;
    border: 0;
    @extend .rawline-regular;
    font-size: 1.1rem;
    background: none;
  }
}

html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -8px;
}
