.section-snapmag {
  padding: 65px 0 130px;
  .last-news {
    .container {
      .inner {
        text-align: center;
        .title-last-news {
          color: $yellow;
          padding-top: 30px;
          text-align: center;
          vertical-align: top;
          text-transform: uppercase;
        }
        .about-last-news {
          padding: 0 40px 80px;
          vertical-align: top;
          text-align: left;
          .about-strong {
            display: block;
            margin-bottom: 30px;
          }
        }

        .btn-see-more {
          text-align: center;
          margin-top: 70px;
        }
      }
    }
  }
}
