.big-title-header {
  @extend .vanitas-bold;
  font-size: 7rem;
}

.big-title-1 {
  @extend .vanitas-bold;
  font-size: 10rem;
}

.big-title-2 {
  @extend .vanitas-bold;
  font-size: 5rem;
}

.keyword {
  @extend .vanitas-bold;
  font-size: 10rem;
}

.keyword-2 {
  @extend .vanitas-bold;
  font-size: 8rem;
}

.big-number {
  @extend .vanitas-regular;
  font-size: 16rem;
}

.title-1 {
  @extend .montserrat-ultra-light;
  font-size: 3.5rem;
}

.title-1-5 {
  @extend .montserrat-ultra-light;
  font-size: 2.5rem;
}

.title-2 {
  @extend .montserrat-light;
  font-size: 2.4rem;
}

.title-2-5 {
  @extend .montserrat-light;
  font-size: 2.6rem;
}

.title-3 {
  @extend .montserrat-light;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.title-4 {
  @extend .montserrat-light;
  font-size: 2rem;
}

.title-5 {
  @extend .montserrat-light;
  font-size: 1.5rem;
}

.text-1 {
  @extend .rawline-regular;
  font-size: 1.5rem;
  line-height: 1.9rem;
}

.text-2 {
  @extend .rawline-regular;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.text-3 {
  @extend .rawline-regular;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.text-strong {
  font-weight: bold;
}

.nav-1 {
  @extend .rawline-bold;
  font-size: 1.3rem;
}

.nav-2 {
  @extend .rawline-bold;
  font-size: 0.9rem;
}

.nav-3 {
  @extend .rawline-bold;
  font-size: 1.3rem;
}

.hat-1 {
  @extend .montserrat-light;
  font-size: 2rem;
}

.hat-2 {
  @extend .rawline-bold;
  font-size: 1.3rem;
}

.hat-3 {
  @extend .rawline-regular;
  font-size: 1.3rem;
}

.hat-4 {
  @extend .rawline-regular;
  font-size: 1.1rem;
}

.hat-4 {
  @extend .rawline-regular;
  font-size: 1.1rem;
}

.basket-1 {
  @extend .rawline-semi-bold;
  font-size: 1.3rem;
  text-transform: uppercase;
}

.basket-2 {
  @extend .rawline-semi-bold;
  font-size: 1.3rem;
}

.basket-3 {
  @extend .rawline-semi-bold;
  font-size: 1.1rem;
}

.basket-4 {
  @extend .rawline-semi-bold;
  font-size: 2rem;
}

.basket-5 {
  @extend .rawline-bold;
  font-size: 0.9rem;
}

.filter-1 {
  @extend .rawline-regular;
  font-size: 1.3rem;
}

.filter-2 {
  @extend .rawline-regular;
  font-size: 1rem;
}

.price-sheet {
  @extend .rawline-semi-bold;
  font-size: 1.8rem;
  white-space: nowrap;
  small {
    font-size: 1.4rem;
    font-weight: normal;
  }
}

.cta-1 {
  @extend .montserrat-regular;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.cta-2 {
  @extend .montserrat-light;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

.help-tooltip-fixed {
  margin-top: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  text-align: center;
  font-family: 'Rawline', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.help-tooltip-fixed.hidden {
  transition: all 0.3s ease-out;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}

ul.dotted-list {
  li {
    list-style-type: disc;
    margin-left: 20px;
  }
}
