$radius: 3px;

.toaster-center {
  display: block;
  position: fixed;
  height: auto;
  z-index: 10000;

  .toast {
    @extend .montserrat-regular;
    background-color: #fff;
    color: #000;
    font-size: 12px;
    box-sizing: border-box;
    margin-bottom: 20px;
    transition: 500ms $ease-out-expo all;
    position: relative;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    box-shadow: 0 0 40px -10px #000;
    border-radius: $radius;
    pointer-events: none;
    text-align: center;

    &.show {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      pointer-events: all;
    }

    .toast-header {
      background-color: $yellow;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
      position: relative;

      .toast-icon svg {
        color: $black;
        fill: $black;
        margin: 30px auto;
        height: 60px;
        width: auto;
      }

      .close {
        background: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 25px;
        top: 25px;
      }
    }

    .toast-body {
      background-color: $white;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
      color: $black;
      line-height: 1.6;
      padding: 30px 40px;

      .toast-title {
        font-size: 1.35rem;
        font-weight: bold;
        margin-bottom: 20px;

        &:after {
          background: $yellow;
          content: '';
          display: block;
          height: 2px;
          margin: 10px auto 0;
          width: 10%;
        }
      }
      .toast-button {
        margin-top: 20px;
        text-align: center;
        .cta {
          border: none;
          background-color: $yellow;
          color: $black;
          cursor: pointer;
          font-family: $montserrat;
          font-size: 13px;
          height: 40px;
          line-height: 40px;
          padding: 0 18px;
          text-transform: uppercase;
          white-space: nowrap;

          &:hover {
            background-color: darken($yellow, 10%);
            color: darken($black, 15%);
          }

          &:disabled {
            opacity: 0.75;
          }

          &.cta-clear {
            background-color: #fff;
            border: 1px solid $yellow;
            &:hover {
              background-color: darken(#fff, 10%);
            }
          }

          &.cta-infos {
            background-color: $light-duck;
            color: #fff;
            &:hover {
              background-color: darken($light-duck, 10%);
            }
          }

          svg {
            color: $black;
            fill: $black;
            font-size: 13px;
            margin-right: 4px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}
