@import 'rent';

.page.rent {
  .subtitle {
    margin-bottom: 30px;
  }

  .button-wrapper {
    margin-top: 40px;
  }

  // formulaire

  .form-row-rent {
    margin-top: 100px;

    #formulaire {
      position: relative;
      top: -150px;
    }

    .form-row-rent-title {
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 55px;
    }

    .form-row-rent-content {
      .form-row-rent-content-left-column {
        padding-top: 50px;
        vertical-align: top;
      }

      .form-row-rent-content-right-column {
        vertical-align: top;

        .hs-form {
          box-shadow: #ddd 2px 2px 8px;
          background-color: #fff;
          padding: 40px;
        }
      }
    }
  }

  .reference-row-rent {
    margin-top: 115px;

    #referencement {
      position: relative;
      top: -150px;
    }

    .title-reference-row {
      color: $yellow;
      margin-left: span(2 of 12);
      text-transform: uppercase;
      margin-bottom: 55px;
    }

    .content-place-reference-row {
      .left-reference-column {
        vertical-align: middle;
      }

      .right-reference-column {
        vertical-align: top;
        margin-top: 31px;

        .reference-text {
          max-width: 435px;
          margin-bottom: 30px;
        }
      }
    }
  }

  .service-row-rent {
    margin-top: 115px;
    padding-bottom: 165px;

    .title-service-row {
      color: $yellow;
      margin-left: span(7 of 12);
      text-transform: uppercase;
      margin-bottom: 55px;
    }

    .left-service-column {
      vertical-align: middle;

      .service-text {
        span {
          font-weight: 900;
        }
      }
    }

    .right-service-column {
      vertical-align: middle;

      .img-right-service {
        margin-left: span(1 of 6);
        width: 100%;
      }
    }
  }

  .organisation-row-rent {
    padding-top: 93px;
    background-color: $light-beige;
    padding-bottom: 118px;

    .title-organisation-row {
      color: $yellow;
      text-transform: uppercase;
      margin-left: span(1.8 of 12);
      margin-bottom: 72px;
    }

    .left-place-organisation-column {
      position: relative;
      width: span(4 of 12);
      display: inline-block;
      vertical-align: top;

      .img-left-organisation {
        position: absolute;
        width: 87%;
        top: 0;
      }
    }

    .right-place-organisation-column {
      margin-left: span(0.3 of 7);

      .organisation-text {
        span {
          font-weight: 900;
        }
      }

      .organisation-list {
        margin-top: 23px;
        margin-bottom: 23px;

        .organisation-list-elements {
          margin-left: 14px;
          position: relative;

          &::before {
            position: absolute;
            content: '';
            width: 5px;
            height: 1px;
            background-color: $black;
            left: -10px;
            top: 11px;
          }
        }
      }
    }
  }

  .security-row-rent {
    padding-top: 146px;

    .title-security-row {
      margin-left: span(4.5 of 12);
      color: $yellow;
      margin-bottom: 73px;
      text-transform: uppercase;
    }

    .up-security-row {
      padding-left: span(0.2 of 8);

      .know-more {
        text-decoration: none;
        color: $black;
        transition: color 600ms ease-in-out;

        &:hover {
          color: $yellow;
        }
      }
    }

    .down-security-row {
      margin-top: 78px;
      position: relative;
      vertical-align: top;

      .image-place-security {
        position: relative;
        z-index: 2;
        width: span(7 of 8);
        margin-left: span(0.5 of 8);

        &::before {
          position: absolute;
          content: '';
          z-index: 1;
          width: span(4.5 of 8);
          height: span(7 of 8);
          bottom: 0;
          left: -span(1 of 8);
          display: block;
          background-image: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png');
        }

        &:after {
          content: '';
          width: 100%;
          height: 0;
          display: block;
          padding-bottom: 60%;
        }

        .element-after-image-place-security {
          position: absolute;
          width: 100%;
          z-index: 2;
          top: 0;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .new-concept-rent {
    position: relative;
    padding-top: 280px;
    padding-bottom: 190px;
    background-color: $white;
    text-align: center;
    background-image: url('../../../../../../web/medias/img/desktop/pages/about-us/about-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: span(7 of 12);
    margin-top: -80px;

    .text-new-concept-rent {
      text-align: center;

      .title-text-new-concept-rent {
        max-width: span(9 of 12);
        display: block;
        margin: 0 auto 30px;
      }

      .text-content-new-concept-rent {
        max-width: span(4.5 of 12);
        display: block;
        margin: 0 auto 40px;
      }

      .button-new-concept-rent {
        background-color: $yellow;
        width: auto;
        height: 50px;
        text-align: center;
        display: inline-block;
      }
    }
  }

  .reference-list {
    max-width: 435px;

    .reference-elements-list {
      position: relative;
      margin-left: 14px;

      &::before {
        position: absolute;
        content: '';
        width: 5px;
        height: 1px;
        background-color: $black;
        left: -10px;
        top: 11px;
      }
    }
  }
}
