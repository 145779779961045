.ct.ct--control-quantity {
  position: relative;
  width: 75px;
  height: 50px;
  border: 1px solid $breadcrumb;
  display: flex;
  .value {
    width: 50px;
    height: 100%;
    border-right: 1px solid $breadcrumb;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    background: $white;
    .response-value {
      position: relative;
      border: none;
      margin-top: 4px;
      width: 30px;
      text-align: right;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &:after {
      position: relative;
      content: attr(data-type);
      display: inline-block;
    }
  }
  .controllers {
    width: 25px;
    height: 100%;
    background: $white;
    button {
      position: relative;
      height: 24px;
      width: 100%;
      padding: 0;
      border: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      span {
        position: absolute;
        width: 7px;
        height: 1px;
        left: 50%;
        top: 50%;
        margin-left: -3px;
        margin-top: -0.5px;
        background: $breadcrumb;
      }
      &.more-quantity {
        border-bottom: 1px solid $breadcrumb;
        span {
          &:nth-child(1) {
            transform: rotate(90deg);
            transform-origin: center center;
          }
        }
      }
    }
  }
}
