@import '../../variables/colors';

.page.seminar {
  .row-introduction {
    padding: 40px 0;

    .inner {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .row-introduction-title {
      line-height: 1.2;
    }

    .row-introduction-content {
      margin: 40px 0;
    }

    .row-introduction-icons {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      width: 100%;
      margin-bottom: 60px;

      li {
        text-align: center;

        .icon {
          height: 8rem;
          width: 8rem;
          display: block;
          margin: 0 auto 10px;
        }
      }
    }
  }

  .logos-slider {
    margin: 120px 0 -50px;
  }

  .row-form {
    margin: 100px 0;

    #formulaire {
      position: relative;
      top: -230px;
    }

    .row-form-content {
      padding-top: 40px;
      vertical-align: top;

      .row-form-content-title {
        line-height: 1.1;
        margin-bottom: 30px;
      }

      .row-form-content-content {
        margin-bottom: 20px;
      }
    }

    .row-form-form {
      vertical-align: top;

      .hs-form {
        box-shadow: #ddd 2px 2px 8px;
        background-color: #fff;
        padding: 40px;
      }
    }
  }
}
