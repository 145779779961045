@import 'articles';

.section-snapmag {
  .last-news {
    .container {
      .inner {
        .about-last-news {
          .about-strong {
            line-height: 35px;
          }
        }
        .news-wrapper {
          display: flex;
          justify-content: space-between;
          text-align: left;
          .ct--news-item {
            width: calc(100% / 3);
            &:nth-child(2) {
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}
