@import '../../variables/colors';

.content-block {
  padding: 20px 0;
  margin-bottom: 50px;

  .no-break {
    white-space: nowrap;
  }

  .block-header {
    @extend .keyword;
    display: block;
    padding: 0 10% 0 0;
    margin-bottom: 40px;
    font-size: 6rem;
    text-align: left;
    text-transform: none;
    color: $yellow;
  }

  .block-content {
    .block-content-content {
      h2,
      h3 {
        @extend .title-1;
        margin-bottom: 40px;
        line-height: 35px;
      }

      h4 {
        @extend .title-1;
        margin-bottom: 15px;
        font-size: 2rem;
        line-height: 1.25;
      }

      p,
      ul {
        @extend .text-1;
        margin-bottom: 30px;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      ul {
        padding-left: 20px;
        list-style-type: initial;

        li {
          margin-bottom: 6px;
        }
      }

      p + ul {
        margin-top: -15px;
      }
    }

    .cta-wrapper {
      &.multiple-ctas {
        .ct--button {
          margin: 0 25px;
        }
      }
    }
  }

  // blocks

  &.text-image-block,
  &.text-place-block,
  &.text-video-block,
  &.text-form-block,
  &.text-contact-form-block {
    .block-header {
      text-align: center;
      padding: 0 10%;
    }

    .block-content {
      margin-top: 10px;
      display: flex;
      align-items: stretch;
      justify-content: center;

      .block-content-content {
        display: block;
        margin-top: 30px;
      }
    }
  }

  &.text-image-block {
    .block-content {
      .block-content-image {
        .block-content-image-wrapper {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .block-content-content {
        display: flex;
        align-items: center;
      }
    }

    // options

    &.with-image-pattern {
      .block-content-image-wrapper {
        position: relative;

        img {
          position: relative;
          z-index: 2;
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: -30px;
          z-index: 1;
          left: -30px;
          background-image: url('../../../img/patterns/pattern_blue_transparent.png');
        }
      }

      &.text-image-right-block {
        .block-content-image-wrapper {
          &:after {
            bottom: -30px;
            left: unset;
            right: -30px;
          }
        }
      }
    }
  }

  &.text-place-block {
    .block-content {
      .block-content-place {
        .ct--item-result {
          &:before {
            aspect-ratio: 800/576;
            height: auto;
          }
        }
      }

      .block-content-place {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &.text-video-block {
    .block-content {
      .block-content-video {
        display: flex;
        flex-direction: column;
        justify-content: center;

        iframe {
          max-width: 100%;
          aspect-ratio: 16/9;
        }
      }
    }
  }

  &.text-trends-block {
    .block-content {
      margin-bottom: 20px;
    }

    .section-trends {
      margin-bottom: 100px;

      .container {
        .inner {
          padding-top: 0;
        }
      }
    }

    .cta-wrapper {
      text-align: center;
    }
  }

  &.text-form-block {
    #formulaire {
      position: relative;
      top: -160px;
    }

    .logos-slider {
      margin: -30px 0 40px;
    }

    .block-content {
      .block-content-content {
        .block-content-content-wrapper {
          h2 {
            @extend .title-1;
            margin-bottom: 40px;
          }

          h3 {
            @extend .text-1;
            font-weight: bold !important;
          }
        }
      }
    }

    .hs-form {
      box-shadow: #ddd 2px 2px 8px;
      background-color: #fff;
      padding: 40px;
    }
  }

  &.text-contact-form-block {
    #formulaire {
      position: relative;
      top: -160px;
    }

    .logos-slider {
      margin: -30px 0 40px;
    }

    .block-content {
      .block-content-form {
        padding: 40px;
        position: relative;

        %pattern {
          background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
            center center repeat;
          content: '';
          height: 66%;
          position: absolute;
          width: 66%;
          z-index: 1;
        }

        &:after {
          @extend %pattern;
          height: 75%;
          width: 100%;
          right: -40px;
          bottom: -40px;
        }

        .contact-form {
          background: #fff8f0;
          vertical-align: top;
          position: relative;
          padding: 30px 40px;
          z-index: 2;

          .form-contact-place {
            label {
              color: $yellow;
              font-weight: bold;
              text-transform: uppercase;
            }

            .form-control {
              background-color: $white;
              border-color: $white;
            }

            .has-error {
              .form-control {
                border-color: #b62c2c;
              }

              .message-error {
                font-family: $rawline;
              }
            }

            hr {
              margin: 30px auto 25px;
              border: none;
              border-bottom: 2px solid darken($medium-beige, 5%);
            }

            .subject-message {
              display: none;
              margin-bottom: 20px;

              &.show {
                display: block;
              }
            }
          }

          .text-legal {
            margin-top: 20px;
            font-size: 1.2rem;
            a {
              color: inherit;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .hs-form {
      box-shadow: #ddd 2px 2px 8px;
      background-color: #fff;
      padding: 40px;
    }
  }

  &.text-collection-block {
    &.bg-color-emphasis {
      padding-bottom: 60px;
    }

    .block-content-collection {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .block-content-collection-row {
        display: flex;
        min-width: 75vw;
        max-width: 1440px;
        gap: 20px;

        > .ct {
          flex: 1 1 0;
          width: 0;
          margin: 0;
          border: 0;

          .about,
          .content {
            text-align: left;
          }
        }
      }

      .cta-wrapper {
        margin-top: 50px;
        text-align: center;
      }
    }
  }

  &.text-faq-block {
    .faq {
      .faq-item {
        h2 {
          @extend .title-2;
          margin-bottom: 15px;
        }
      }
    }
  }

  &.linked-pages-block {
    .block-header {
      text-align: center;
      padding: 0 10%;
    }
  }

  // common options

  &.bg-color-emphasis {
    padding: 60px 0 80px;
    background-color: $light-beige;

    + .bg-color-emphasis {
      margin-top: -50px;
    }
  }
}
