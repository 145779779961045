.popins {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: $white;
  font-size: 10px;
  top: 0;
  left: 0;
  &.show {
    display: block;
  }
  .popin {
    .headline {
      position: relative;
      display: flex;
      height: 75px;
      border-bottom: 1px solid $dark-beige;
      align-items: center;
      justify-content: center;
      .title {
        text-align: center;
        display: block;
      }
      .close {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        .icon {
          width: 12px;
          height: 12px;
        }
      }
    }
    .content-popin {
      padding: 30px 7% 25px;
      max-height: calc(100vh - 75px);
      overflow-y: auto;
    }
  }
}
