@import '../../../variables/colors';
@import '../../../variables/fonts';

.page.error._404,
.page.error.autoconnect {
  width: 100%;
  min-height: calc(100vh - 350px);
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('../../../../../../../web/medias/img/desktop/pages/faq/faq-bg.png');
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .content {
    text-align: center;

    .container-big-number {
      overflow: hidden;
      position: relative;
      color: $yellow;

      .big-number {
        font-family: $vanitas;
      }
    }

    .title-error {
      text-transform: uppercase;
      font-family: $montserrat;
      font-weight: 300;
    }

    .text-error {
      font-family: $rawline;
      font-weight: 300;
      line-height: 1.5;
    }
  }

  b {
    font-weight: bold;
  }

  form.autoconnect-form {
    input,
    .cta {
      display: block;
      text-transform: uppercase;
      line-height: 50px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
