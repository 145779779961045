.ct.ct--breadcrumb {
  .inner {
    li {
      display: inline-block;
      font-size: 12px;
      a {
        color: #7b766f;
        &:hover {
          color: $black;
        }
      }
    }
    li + li {
      padding-left: 0.5rem;
      &::before {
        display: inline-block;
        padding-right: 0.5rem;
        content: '/';
      }
    }
  }
}
