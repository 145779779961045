$error-color: #b62c2c;

.generic-form {
  .form-group {
    @extend .rawline-regular;
    display: block;
    margin-bottom: 15px;
    &.form-group-50 {
      width: 50%;
    }
    &.form-group-33 {
      width: 33%;
    }
    &.form-group-25 {
      width: 25%;
    }
  }
  .form-control {
    @extend .rawline-regular;
    display: block;
    width: 100%;
    font-size: 1.5rem;
    height: 48px;
    padding: 0 20px;
    color: $black;
    background-color: $input-bg;
    border: 1px solid $input-bg;
    &::placeholder {
      font-size: 14px;
      color: $basket-li;
      font-family: $rawline;
      font-weight: normal;
    }
  }
  textarea.form-control {
    height: auto;
    min-height: 100px;
    padding: 12px 20px;
    line-height: 1.5;
  }
  label.label {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-family: $rawline;
    font-weight: normal;
    font-style: normal;
    color: $black;
    margin-bottom: 8px;
    display: block;
    &.required:after {
      content: '*';
      font-size: 1.5rem;
      left: 3px;
      top: 2px;
      font-weight: bold;
    }
  }
  label.label-check {
    margin-bottom: 8px;
    input {
      vertical-align: middle;
      &.input-radio {
        border-radius: 100%;
      }
    }
    .checkmark {
      margin-left: 12px;
      vertical-align: middle;
    }
  }
  button.btn-submit {
    @extend .montserrat-light;
    -webkit-appearance: button;
    border: none;
    cursor: pointer;
    position: relative;
    height: 50px;
    padding: 0 40px;
    text-align: center;
    align-items: center;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.3rem;
    margin-right: 10px;
    text-transform: uppercase;
    @include ct--button-action;
  }
  .message-error {
    font-size: 1rem;
    font-family: Montserrat;
    display: block;
    width: 100%;
    color: $error-color;
    margin-bottom: 15px;
  }
  .has-error {
    .label {
      color: $error-color;
    }
    .form-control {
      border-color: $error-color;
    }
    .message-error {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
  select.form-control {
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23777CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }
}
