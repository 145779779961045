#page-zones {
  .ct--breadcrumb {
    margin: 0;
  }

  h1,
  h2 {
    color: $yellow;
    margin-bottom: 80px;
    text-align: left;
    text-transform: uppercase;
  }

  .button-wrapper {
    text-align: center;
    margin: 20px 0;
  }

  .section-departements {
    background-color: #fff8f0;
  }
}
