#hellosign-confirmation-page {
  .content-cgu {
    text-align: center;
    h1 {
      font-size: 8rem;
    }
    p {
      margin: 40px 0;
    }
  }
}
