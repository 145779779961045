.page.homepage-index {
  .section-header-wraper {
    background-color: $light-beige;
  }

  .section-header {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;

    .title-header {
      text-align: center;

      span {
        display: block;
      }
    }

    .contents {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;

      .content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        .title-header {
          color: $white;
          display: block;
          text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.45);
        }
      }
    }

    .pictures {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: #8b9498;
      background-size: cover;
      background-position: center center;
      .pic {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.15);
        }
      }
      $images: 1, 2, 3, 4, 5, 6, 7;
      @each $image in $images {
        &.header-#{$image} {
          background-image: url('./../../../img/home/header/header-#{$image}.blurry.jpg');
          .pic {
            background-image: url('./../../../img/home/header/header-#{$image}.jpg');
          }
        }
      }
    }
  }

  .section-key-figures {
    background: $light-beige;
    padding: 100px 0;
  }

  .logos-slider {
    margin: 40px 0 0;
  }

  .section-zoom {
    position: relative;
    width: 100%;

    .wrapper-section-zoom {
      position: relative;
      padding: 165px 0 90px;
      z-index: 3;
      text-align: center;
      overflow: hidden;

      &:before {
        position: absolute;
        content: '';
        z-index: 1;
        background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_01.jpg')
          center center repeat;
        top: 0;
      }

      &:after {
        position: absolute;
        content: '';
        z-index: 2;
        top: 0;
        background: linear-gradient(
          to bottom,
          rgba(80, 164, 168, 0.7) 0%,
          rgba(125, 185, 232, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8050a4a8', endColorstr='#007db9e8', GradientType=0); /* IE6-9 */
      }

      .inner {
        position: relative;
        z-index: 5;

        .title-zoom {
          text-align: center;
          color: $yellow;
          text-transform: uppercase;
        }
      }

      .col-content {
        color: $white;
      }
    }
  }

  .section-more {
    position: relative;
    margin-top: 80px;
    margin-bottom: 100px;
    padding: 60px 0 0;

    &:before {
      position: absolute;
      content: '';
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: $light-beige;
    }

    .ct--double-col,
    .ct--double-col-slider {
      position: relative;
      z-index: 2;
    }
  }

  #customers {
    display: block;
    position: relative;
    top: -210px;
    visibility: hidden;
  }
}
