.ct.ct--slider-item,
.ct.ct--item-result {
  .lazyload-slider {
    background-image: url('../../../img/loader.nowebp.png') !important;
    background-color: #272727 !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: auto !important;
  }
}
