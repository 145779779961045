@import 'navbar';

.ct.ct--navbar {
  position: fixed;
  width: 100%;
  z-index: 60;
  background: rgba(39, 39, 39, 0.7);
  min-height: 90px;
  // Navbar
  .first-row-navbar {
    padding-top: 30px;
    padding-bottom: 10px;
    transform-origin: 100% 0;
    transition: margin-top 600ms ease-in-out;
    margin-top: 0;
    position: relative;
    z-index: 5;
    float: left;

    &.show {
      &::after {
        pointer-events: auto;
        opacity: 0.5;
      }
    }

    .first-list-navbar {
      float: right;
      .first-list-nav {
        display: block;
        margin-right: span(0.5 of 12);
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        float: right;

        .elements-first-list-nav {
          display: inline-block;
          margin-right: 30px;
          width: 135px;
          color: $yellow;

          &.blog {
            width: 80px;
          }

          &.phone {
            width: 190px;
            font-size: 16px;
          }

          a {
            position: relative;
            text-decoration: none;
            color: $yellow;

            &::after {
              position: absolute;
              content: '';
              width: 100%;
              height: 1px;
              bottom: -4px;
              left: 0;
              background-color: $yellow;
              transform: scaleX(0);
              transform-origin: 100% 0;
              transition: transform ease-in-out 0.3s;
            }

            &:hover {
              &::after {
                transform-origin: 0% 0;
                transform: scaleX(1);
              }
            }
          }

          &.cart {
            .quantity {
              @extend .rawline-bold;
              align-items: center;
              background: $light-duck;
              border-radius: 50%;
              color: $white;
              display: flex;
              font-size: 0.9rem;
              height: 17px;
              justify-content: center;
              left: 14px;
              pointer-events: none;
              position: absolute;
              top: -11px;
              width: 17px;
            }
          }
        }
      }
    }

    li {
      background-color: transparent;
      display: inline-block;
      cursor: pointer;
      position: relative;
      margin-bottom: 0;
      z-index: 10;

      .icon {
        margin-right: 10px;
        color: $yellow;
        fill: $yellow;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-top: -6px;
      }
    }

    .phone {
      width: 20px;
      height: 20px;
      margin-bottom: 0;
    }

    .account {
      width: 20px;
      height: 20px;
    }

    .menuburger-icon {
      width: 20px;
      height: 20px;
    }
  }

  .sub-row-navbar {
    padding: 15px 0 10px;
    width: 100%;
    vertical-align: bottom;
    position: relative;
    z-index: 3;

    .logonavbar {
      margin-left: span(0.5 of 12);
      width: 275px;
      display: inline-block;
      vertical-align: bottom;
      position: relative;
      z-index: 1;
      margin-top: -1px;

      @media screen and (max-width: 1136px) {
        position: absolute;
        top: -30px;

        .full-logo {
          display: none;
        }

        .min-logo {
          display: block;
        }
      }

      .icon {
        width: 275px;
        height: 63px;
        fill: $white;
        color: $white;
      }

      .full-logo {
        display: inline-block;
      }

      .min-logo {
        display: none;
      }
    }

    .second-list-navbar {
      color: $white;
      display: inline-block;
      float: right;
      vertical-align: bottom;
      position: relative;
      z-index: 5;
      margin-right: span(0.5 of 12);

      .sub-list-nav {
        display: inline-block;
        padding-top: 25px;
        margin-right: 0;

        .sub-list-nav-elements {
          display: inline-block;
          margin-right: 30px;
          vertical-align: top;
          position: relative;
          z-index: 2;

          .arrow {
            vertical-align: top;
            margin-left: 10px;

            .icon {
              width: 10px;
              height: 10px;
              color: $white;
              fill: $white;
              stroke: $white;
            }
          }

          a {
            position: relative;
            color: white;

            &::after {
              position: absolute;
              content: '';
              width: 105%;
              height: 5px;
              bottom: -15px;
              left: -3px;
              background-color: $yellow;
              transform: scaleX(0);
              transform-origin: 100% 0;
              transition: transform ease-in-out 0.3s;
            }

            &:hover {
              &::after {
                transform-origin: 0% 0;
                transform: scaleX(1);
              }
            }
          }
        }

        .menuburger-my-cart,
        .menuburger-my-account,
        .menuburger-my-selections,
        .menuburger-icon {
          svg {
            display: inline-block;
            height: 20px;
            width: 20px;
            color: $yellow;
            fill: $yellow;
          }
        }

        .menuburger-my-cart,
        .menuburger-my-selections,
        .menuburger-my-account {
          margin: 0 15px 0 5px;
          a {
            display: inline-block;
            height: 18px;
            padding: 0 3px;
            color: $yellow;
            ::after {
              width: auto;
              right: -3px;
            }
          }
        }

        .menuburger-my-cart,
        .menuburger-my-selections {
          .quantity {
            @extend .rawline-bold;
            align-items: center;
            background: $light-duck;
            border-radius: 50%;
            color: $white;
            display: flex;
            font-size: 0.9rem;
            height: 17px;
            justify-content: center;
            pointer-events: none;
            position: absolute;
            right: -8px;
            top: -10px;
            width: 17px;
          }
        }

        .menuburger-my-selections {
          svg {
            animation: ring 3.5s 10s ease-in-out;
          }

          .quantity {
            background: $red;
          }
        }

        .menuburger-my-cart {
          svg {
            margin-bottom: -5px;
            vertical-align: baseline;
          }
        }

        .menuburger-icon {
          margin: 0 0 0 10px;
          cursor: pointer;
        }
      }
    }

    a {
      color: $white;

      &:hover {
        color: $yellow;
      }
    }
  }
}

.ct--navbar,
.ct--navbar-search,
.ct--navbar-account {
  //Menu Burger
  @import './navbar.burger.desktop';

  .sub-row-navbar {
    &::before {
      transform: scaleX(1);
    }

    @media screen and (max-width: 1136px) {
      .logonavbar {
        width: 59px;
        height: 59px;
        top: 23px;

        .icon {
          width: 59px;
          height: 59px;
        }

        .full-logo {
          display: none;
        }

        .min-logo {
          display: block;
        }
      }
    }

    .second-list-navbar {
      .sub-list-nav {
        margin-right: 0;
        .menuburger-icon {
          top: -3px;
        }
        .dropdown {
          display: inline-block;
          .dropdown-trigger {
            background: none;
            border: none;
            font-size: 1.3rem;
            text-transform: uppercase;
            font-family: $rawline;
            font-weight: 600;
            color: #fff;
            margin-top: -1px;
            padding: 0;
            height: 16px;
            line-height: 16px;
            max-height: 16px;
            min-height: 16px;
            appearance: none;
            cursor: pointer;
            .icon {
              fill: white;
              height: 8px;
              width: 8px;
              margin-left: 4px;
            }
          }
          .dropdown-content {
            display: none;
          }
          &:hover {
            .dropdown-content {
              min-width: calc(100% + 30px);
              padding: 35px 0 0;
              position: absolute;
              display: block;
              margin-left: -15px;
              ul {
                display: list-item;
                background: rgba(39, 39, 39, 0.7);
                padding-bottom: 5px;
                li {
                  float: none;
                  display: block;
                  a {
                    padding: 12px 15px;
                    white-space: nowrap;
                    display: block;
                    transition: color 200ms ease-in;
                    &:hover:after {
                      display: none;
                    }
                    &:hover {
                      color: $yellow;
                    }
                  }
                  &:first-child a {
                    padding-top: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sgp-body {
  .ct.ct--navbar .sub-row-navbar .logonavbar .icon {
    aspect-ratio: 112/63;
    width: auto;
    height: 63px;
  }
}
