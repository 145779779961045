@import 'dashboard';

.page.dashboard {
  font-size: 0;
  display: block;
  padding-top: 83px;

  .dash-nav {
    display: inline-block;
    vertical-align: top;
    padding-top: 41px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 165px;
    background-color: $light-beige;

    .name {
      margin-bottom: 30px;
      position: relative;

      .connect {
        display: inline-block;

        .icon {
          width: 21px;
          height: 21px;
        }
      }

      .name-text {
        display: inline-block;
        margin-left: 10px;
      }

      .disconnect {
        position: absolute;
        display: inline-block;
        right: 0;

        .icon {
          width: 21px;
          height: 21px;
        }
      }
    }

    .nav-list {
      .element-list-nav {
        padding: 15px 0 15px 10px;
        border-bottom: 1px solid $breadcrumb;

        &:first-child {
          border-top: 1px solid $breadcrumb;
        }

        a {
          text-decoration: none;
          color: $black;
          transition: font 300ms ease-in-out;
          font-weight: normal;

          &:hover {
            transition: font 10ms ease-in-out;
            font-weight: bold;
          }

          &.select-nav {
            font-weight: 900;
          }
        }
      }
    }

    .need-help {
      margin-top: 35px;
    }

    .text-contact {
      margin-top: 10px;
    }

    .number-contact {
      margin-top: 15px;

      .icon {
        width: 21px;
        height: 21px;
        margin-right: 12px;
        vertical-align: middle;
        display: inline-block;
      }

      .text-number-contact {
        vertical-align: middle;
        display: inline-block;
      }
    }
  }

  .dash-wrapper {
    font-size: 0;
    display: inline-block;
    margin-bottom: 50px;

    .dash-content {
      display: inline-block;
      padding: 25px;

      .half-block {
        padding: 33px 41px;
        border: 1px solid $dark-beige;
        vertical-align: top;
        width: calc(50% - 12.5px);
        display: inline-block;
        margin-bottom: 25px;
        min-height: 186px;

        a {
          color: $black;
          text-decoration: none;
        }

        &:nth-child(2n + 1) {
          margin-right: 25px;
        }
      }

      .full-block {
        padding: 33px 41px;
        border: 1px solid $dark-beige;
        width: 100%;
      }

      .title-block {
        margin-bottom: 10px;
      }

      .block-list {
        .block-list-element {
          a {
            text-decoration: none;
            color: $black;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background-color: $black;
              transform: scaleX(0);
              bottom: -2px;
              left: 0;
              transform-origin: 0% 0%;
              transition: transform 400ms ease-in;
            }

            &:hover {
              &::after {
                transform: scaleX(1);
              }
            }
          }
        }
      }

      .personnal {
        margin-bottom: 25px;

        .title-personnal {
          vertical-align: middle;
          display: inline-block;
        }

        .modify-link {
          float: right;
          vertical-align: middle;

          .icon {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 12px;
          }

          a {
            text-decoration: none;
            color: $black;
          }
        }

        .personnal-content {
          border-top: 1px solid $dark-beige;
          margin-top: 27px;
          margin-bottom: 23px;
          padding-top: 18px;

          .left-col {
            width: 50%;
            display: inline-block;

            .icon {
              width: 35px;
              height: 35px;
              vertical-align: middle;
              margin-right: 12px;
            }

            .list-personnal {
              vertical-align: middle;
              display: inline-block;

              .element-list-personnal {
                &:first-child {
                  font-weight: 900;
                }
              }
            }
          }

          .right-col {
            padding-left: 40px;
            display: inline-block;
            vertical-align: top;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 1px;
              background-color: $dark-beige;
              opacity: 0.8;
            }

            .list-personnal {
              vertical-align: top;
              display: block;
              margin-bottom: 25px;

              &:last-child {
                margin-bottom: 0;
              }

              .element-list-personnal {
                &:first-child {
                  font-weight: 900;
                }
              }
            }

            .form-input {
              display: block;
              margin-top: 10px;
              padding: 15px 20px;
              min-width: 340px;
              background-color: $light-beige;
              border: none;
            }
          }
        }
      }

      .newsletter {
        margin-bottom: 25px;

        .ct--button {
          min-width: 210px;
          position: relative;

          span {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .newsletter-content {
          border-top: 1px solid $dark-beige;
          margin-top: 27px;
          margin-bottom: 23px;
          padding-top: 18px;

          .icon {
            width: 35px;
            height: 25px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            stroke: $black;
          }

          .text-newsletter {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .password {
        margin-bottom: 25px;

        .ct--button {
          min-width: 210px;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .password-content {
          border-top: 1px solid $dark-beige;
          padding-top: 30px;
          margin-top: 27px;

          .text-password {
            margin-bottom: 30px;
          }

          .form-password {
            padding-bottom: 5px;

            .input-text,
            input[type='password'] {
              display: block;
              margin-bottom: 10px;
              width: 395px;
              background-color: $input-bg;
            }

            .subtitle {
              margin-bottom: 20px;
              display: block;
            }
          }
        }
      }

      .personnal-data {
        position: relative;
        margin-bottom: 25px;

        .ct--button {
          margin-top: 10px;
          min-width: 210px;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .title-personnal-data {
          display: inline-block;
          margin-bottom: 24px;
        }

        .cancel {
          display: inline-block;
          margin-right: 10px;
          float: right;

          .icon {
            display: inline-block;
            width: 10px;
            height: 10px;
            vertical-align: middle;
            margin-right: 10px;
          }

          .text-cancel {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            color: $black;
          }
        }

        .under-full-block {
          padding-top: 27px;
          border-top: 1px solid $dark-beige;

          .col-left {
            display: inline-block;
            width: span(3 of 9);
            vertical-align: top;
            margin-right: span(2 of 9);

            .icon-place {
              display: inline-block;

              .icon {
                width: 35px;
                height: 35px;
              }
            }

            .under-full-content-text {
              display: inline-block;
              margin-left: 15px;

              span {
                font-weight: 900;
              }
            }
          }

          .col-right {
            display: inline-block;
            width: span(4 of 9);
            vertical-align: top;
            border-left: 1px solid $dark-beige;
            padding-left: 40px;

            .personnal-form {
              padding-top: 12px;

              .input-personnal {
                // color: $black;
                // margin-bottom: 8px;
                // display: block;
                // text-transform: none;
              }

              .input-box {
                width: 100%;
                margin-bottom: 18px;
                background-color: $input-bg;
              }
            }
          }
        }
      }

      .account-type {
        margin-bottom: 25px;

        .ct--button {
          min-width: 210px;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .account-content {
          border-top: 1px solid $dark-beige;
          padding-top: 30px;
          margin-top: 20px;

          .text-account {
            margin-bottom: 30px;
          }

          .button-radio {
            &:last-of-type {
              margin-bottom: 30px;
            }
          }
        }
      }

      .event-table {
        margin-bottom: 25px;

        .event-content {
          border-top: 1px solid $dark-beige;
          padding-top: 30px;
          margin-top: 27px;

          .event-table {
            width: 100%;
            text-align: left;

            a {
              text-decoration: none;
              color: $black;
            }

            .title-row {
              padding-bottom: 0;

              .title-row-content {
                height: 30px;
                text-overflow: ellipsis;
              }
            }

            .sub-row {
              padding-top: 0;
              padding-bottom: 0;
              border-top: solid 1px $dark-beige;
              vertical-align: middle;
              height: 40px;
              text-overflow: ellipsis;
            }

            .title-name {
              width: 25%;
            }

            .title-date {
              width: 25%;
            }

            .title-place {
              width: 25%;
            }

            .title-price {
              width: 24%;
            }

            .row-icon {
              vertical-align: middle;

              .icon {
                width: 21px;
                height: 21px;
              }
            }
          }
        }
      }

      .empty {
        min-height: 323px;
      }
    }
  }
}
