@import 'contact';

.page.contact {
  %pattern {
    background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
      center center repeat;
    content: '';
    height: 66%;
    position: absolute;
    width: 66%;
    z-index: 1;
  }

  padding-top: 80px;
  padding-bottom: 80px;

  .ct--breadcrumb {
    margin: 20px 0 70px;

    .inner {
      padding: 20px 0 20px 4%;
    }
  }

  .big-title-contact {
    color: $yellow;
    margin-bottom: 55px;
  }

  .container {
    .sizing-1 {
      vertical-align: top;

      .first-row-contact {
        .text-content-first-row {
          line-height: 1.75;

          &.text-legal {
            margin-top: 80px;
          }

          a {
            color: $yellow;
            font-weight: bold;

            &:hover {
              color: darken($yellow, 10%);
            }
          }
        }
      }
    }

    .sizing-2 {
      vertical-align: top;
      position: relative;

      &:after {
        @extend %pattern;
        height: 75%;
        width: 100%;
        right: -40px;
        bottom: -40px;
      }

      .contact-form {
        min-height: 940px;
        background: #fff8f0;
        vertical-align: top;
        position: relative;
        padding: 30px 40px;
        z-index: 2;
      }
    }
  }
}
