.ct--news-item {
  cursor: pointer;
  background: transparent;
  transition: background 250ms $ease-out-quad;
  .pic {
    position: relative;
    background: transparent;
    overflow: hidden;
    &:before {
      content: '';
      padding-bottom: 67%;
      width: 100%;
      height: 0;
      display: block;
    }
    img {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .about {
    padding: 35px;

    .info {
      color: $yellow;
      display: flex;
      justify-content: space-between;
      @extend .montserrat-light;
      font-size: 1.1rem;
      .category {
        text-transform: uppercase;
      }
    }
    .title-news {
      margin: 20px 0 15px;
      a {
        color: $black;
      }
    }
  }

  @media screen and (min-width: 951px) {
    &:hover {
      background: $medium-grey;
      .about {
        color: $white;

        a {
          color: $white;
        }
      }
    }
  }
}
