$flagsImagePath: '~intl-tel-input/build/img/';
@import '~intl-tel-input/src/css/intlTelInput';
@import '../../placeholders/texts.placeholders';

.iti {
  display: block;
  .iti__flag-container {
    .iti__country-list {
      .iti__country {
        .iti__dial-code {
          @extend %text-1;
        }
      }
    }
  }
}

.html-mobile {
  .iti {
    .iti__flag-container {
      .iti__country-list {
        .iti__country {
          .iti__dial-code {
            @extend %text-1-mobile;
          }
        }
      }
    }
  }
}
