.ct.ct--double-col-slider-companies {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 60px;

  &.left {
    .container {
      .cols {
        .col.col-picture {
          .slider {
            margin-top: span(1 of 5);
          }
        }
      }
    }
  }

  .container {
    .cols {
      display: flex;
      align-items: stretch;

      .col {
        &.col-content {
          display: flex;
          align-items: center;

          .inner {
            position: relative;
            z-index: 1;

            .section-title-double-col {
              margin-top: -10px;
              margin-bottom: -10px;
              line-height: 1.2;
              font-size: 1.8rem;
              font-family: $rawline;
              font-weight: normal;
              font-style: normal;
            }

            .content-double-col {
              margin: 20px 0 40px;
            }
          }
        }

        &.col-picture {
          position: relative;
          overflow: hidden;

          .headline {
            color: $yellow;
            position: absolute;
            left: 0;
            z-index: 5;
            text-transform: uppercase;
          }

          .slider {
            position: relative;
            background-color: $black;

            &:hover {
              .inner::before {
                opacity: 0;
              }

              .inner::after {
                opacity: 0;
              }

              .bullets::before {
                opacity: 0;
              }
            }

            &:before {
              content: '';
              width: 100%;
              height: 0;
              display: block;
              padding-bottom: 100%;
            }

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background: url('../../../../../../web/medias/img/generics/pattern/se_pattern_02.png')
                center center repeat;
              z-index: 1;
              top: -80px;
              right: -80px;
            }

            .inner {
              position: absolute;
              width: 100%;
              height: 100%;
              background: $black;
              top: 0;
              left: 0;
              z-index: 2;
              overflow: hidden;

              &::before,
              &::after {
                content: ' ';
                width: 20%;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 9;
                opacity: 0;
                transition: ease-in-out 0.3s;
              }

              .slide {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 86%;
                opacity: 0;
                display: flex;
                flex-wrap: wrap;
                padding: 10px;

                &:first-child {
                  opacity: 1;
                }

                .row {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  height: 33.3%;

                  .cell {
                    width: 50%;
                    padding: 45px;
                    text-align: center;
                    display: flex;
                    justify-content: center; /* align horizontal */
                    align-items: center; /* align vertical */
                    img {
                      height: auto;
                      max-width: 100%;
                    }
                  }
                }
              }
            }

            .bullets {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              height: 14%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 5;

              &::before {
                content: ' ';
                background: linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.45) 100%
                );
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 9;
                opacity: 0;
                transition: ease-in-out 0.3s;
              }

              li {
                width: 15px;
                height: 15px;
                background: rgba(255, 255, 255, 0.2);
                display: inline-block;
                border-radius: 50%;
                margin: 0 10px;
                cursor: pointer;
                position: relative;
                z-index: 10;

                &.active {
                  background: rgba(255, 255, 255, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
