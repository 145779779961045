.page.charte-qualite {
  .header {
    .first-title {
      small {
        display: block;
        font-size: 6rem;
      }
    }
  }

  .intro-row {
    margin: 80px 0;
    padding: 0 80px;
    text-align: center;

    h2 {
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 80px;
    }

    p {
      font-size: 2rem;
      line-height: 1.5;
    }

    p:first-child {
      margin-bottom: 10px;
    }
  }

  .numbers-row {
    display: flex;
    padding: 0 80px;
    text-align: center;

    .number {
      color: $yellow;
      display: block;
    }

    .number-desc {
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  .reference-row-rent {
    .content-place-reference-row {
      .left-reference-column {
        img {
          width: 100%;
        }
      }

      .right-reference-column {
        margin-top: auto;
        padding-left: 4%;
        vertical-align: middle;

        .reference-text {
          margin-bottom: 15px;
        }
      }
    }
  }

  .animations-row-rent {
    .animations-row-title {
      color: $yellow;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .service-row-rent {
    padding-bottom: 0;

    .left-service-column {
      padding-right: 4%;

      .service-text {
        margin-bottom: 15px;
      }
    }

    .right-service-column {
      .img-right-service {
        margin-left: 0;
      }
    }
  }

  .partner-row {
    background-color: $light-beige;
    margin-top: 100px;
    padding: 75px;

    .partner-row-content {
      display: flex;
      align-items: center;

      .partner-row-title {
        color: $yellow;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      .partner-row-content-text {
        margin-bottom: 15px;
      }

      .partner-row-content-right {
        text-align: right;

        img {
          max-width: 66%;
        }
      }
    }
  }

  .new-concept-rent {
    background: none;
    margin: 80px auto;
    padding: 75px;

    .text-new-concept-rent {
      .text-content-new-concept-rent {
        max-width: none;
      }
    }
  }

  .reference-list {
    .reference-elements-list {
      line-height: 1.5;
    }
  }

  .reference-list {
    max-width: 435px;

    .reference-elements-list {
      position: relative;
      margin-left: 14px;

      &::before {
        position: absolute;
        content: '';
        width: 5px;
        height: 1px;
        background-color: $black;
        left: -10px;
        top: 11px;
      }
    }
  }
}
