.short-tooltip {
  @extend .rawline-bold;
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: $light-duck;
  margin-bottom: -1px;
  border: none;
  padding: 0;

  &::after {
    content: '?';
    color: #fff;
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.tippy-iOS {
  cursor: pointer !important;
  -webkit-tap-highlight-color: transparent;
}

.tippy-popper {
  pointer-events: none;
  max-width: calc(100vw - 10px);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform;
}

.tippy-arrow {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  padding: 5px 9px 6px;
}

.tippy-tooltip {
  @extend .rawline-regular;
  position: relative;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  background-color: $light-duck;
  transition-property: visibility, opacity, transform;
  outline: 0;

  &[data-interactive][data-state='visible'] {
    pointer-events: auto;
  }

  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }

  &[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }

  &[data-placement^='top'] {
    & > .tippy-arrow {
      border-width: 8px 8px 0;
      border-top-color: $light-duck;
      margin: 0 3px;
      transform-origin: 50% 0;
      bottom: -7px;
    }

    & > .tippy-backdrop {
      transform-origin: 0 25%;

      &[data-state='visible'] {
        transform: scale(1) translate(-50%, -55%);
      }

      &[data-state='hidden'] {
        transform: scale(0.2) translate(-50%, -45%);
      }
    }
  }

  &[data-placement^='bottom'] {
    & > .tippy-arrow {
      border-width: 0 8px 8px;
      border-bottom-color: $light-duck;
      margin: 0 3px;
      transform-origin: 50% 7px;
      top: -7px;
    }

    & > .tippy-backdrop {
      transform-origin: 0 -50%;

      &[data-state='visible'] {
        transform: scale(1) translate(-50%, -45%);
      }

      &[data-state='hidden'] {
        transform: scale(0.2) translate(-50%);
      }
    }
  }

  &[data-placement^='left'] {
    & > .tippy-arrow {
      border-width: 8px 0 8px 8px;
      border-left-color: $light-duck;
      margin: 3px 0;
      transform-origin: 0 50%;
      right: -7px;
    }

    & > .tippy-backdrop {
      transform-origin: 50% 0;

      &[data-state='visible'] {
        transform: scale(1) translate(-50%, -50%);
      }

      &[data-state='hidden'] {
        transform: scale(0.2) translate(-75%, -50%);
      }
    }
  }

  &[data-placement^='right'] {
    & > .tippy-arrow {
      border-width: 8px 8px 8px 0;
      border-right-color: $light-duck;
      margin: 3px 0;
      transform-origin: 7px 50%;
      left: -7px;
    }

    & > .tippy-backdrop {
      transform-origin: -50% 0;

      &[data-state='visible'] {
        transform: scale(1) translate(-50%, -50%);
      }

      &[data-state='hidden'] {
        transform: scale(0.2) translate(-25%, -50%);
      }
    }
  }

  &[data-animatefill] {
    background-color: transparent !important;
  }
}

.tippy-backdrop {
  position: absolute;
  background-color: $light-duck;
  width: calc(110% + 32px);
  left: 50%;
  top: 50%;
  z-index: -1;
  transition: all cubic-bezier(0.46, 0.1, 0.52, 0.98);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &[data-state='hidden'] {
    opacity: 0;
  }

  &:after {
    content: '';
    float: left;
    padding-top: 100%;
  }

  & + .tippy-content {
    transition-property: opacity;
    will-change: opacity;

    &[data-state='hidden'] {
      opacity: 0;
    }
  }
}

.tippy-tooltip[data-animation='shift-away'][data-placement^='top'][data-state='hidden'] {
  transform: translateY(10px);
}

.tippy-tooltip[data-animation='shift-away'][data-placement^='bottom'][data-state='hidden'] {
  transform: translateY(-10px);
}

.tippy-tooltip[data-animation='shift-away'][data-placement^='left'][data-state='hidden'] {
  transform: translateX(10px);
}

.tippy-tooltip[data-animation='shift-away'][data-placement^='right'][data-state='hidden'] {
  transform: translateX(-10px);
}

.tippy-tooltip[data-animation='shift-away'][data-state='hidden'] {
  opacity: 0;
}

.icon-rounded {
  display: flex;
  padding: 5px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 40px;
  height: 40px;

  &.danger {
    background: #c02f30;
    color: white;
    & > svg {
      fill: white;
    }
  }
}

.tippy-tooltip.gray-theme {
  background-color: #e4e4e4;
  color: black;
}

.tippy-tooltip.gray-theme[data-placement^='top'] .tippy-arrow {
  border-top-color: #e4e4e4;
}
.tippy-tooltip.gray-theme[data-placement^='bottom'] .tippy-arrow {
  border-bottom-color: #e4e4e4;
}
.tippy-tooltip.gray-theme[data-placement^='left'] .tippy-arrow {
  border-left-color: #e4e4e4;
}
.tippy-tooltip.gray-theme[data-placement^='right'] .tippy-arrow {
  border-right-color: #e4e4e4;
}

.tippy-tooltip.transparent-theme {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ddd;
  border-radius: 4px;
}

.tippy-tooltip.transparent-theme[data-placement^='top'] .tippy-arrow {
  border-top-color: rgba(0, 0, 0, 0.7);
}
.tippy-tooltip.transparent-theme[data-placement^='bottom'] .tippy-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.7);
}
.tippy-tooltip.transparent-theme[data-placement^='left'] .tippy-arrow {
  border-left-color: rgba(0, 0, 0, 0.7);
}
.tippy-tooltip.transparent-theme[data-placement^='right'] .tippy-arrow {
  border-right-color: rgba(0, 0, 0, 0.7);
}
