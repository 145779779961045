$fonts-base-path: '../../fonts' !default;

@function font($file) {
  @return url($fonts-base-path + '/' + $file);
}

@font-face {
  font-family: 'Rawline';
  src: font('Rawline-SemiBold.woff2') format('woff2'),
    font('Rawline-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rawline';
  src: font('Rawline-Bold.woff2') format('woff2'),
    font('Rawline-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rawline';
  src: font('Rawline-Regular.woff2') format('woff2'),
    font('Rawline-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vanitas';
  src: font('Vanitas-Bold.woff2') format('woff2'),
    font('Vanitas-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Vanitas';
  src: font('Vanitas-Regular.woff2') format('woff2'),
    font('Vanitas-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: font('Montserrat-Regular.woff2') format('woff2'),
    font('Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: font('Montserrat-Light.woff2') format('woff2'),
    font('Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Ultra';
  src: font('Montserrat-UltraLight.woff2') format('woff2'),
    font('Montserrat-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

$font-family-sans-serif:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
  // Windows
  'Segoe UI',
  // Android
  'Roboto',
  // Basic web fallback
  'Helvetica Neue',
  Arial, sans-serif,
  // Emoji fonts
  'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$vanitas: 'Vanitas', sans-serif;
$rawline: 'Rawline', $font-family-sans-serif;
$montserrat: 'Montserrat', $font-family-sans-serif;
$montserrat-ultra: 'Montserrat Ultra', $font-family-sans-serif;

.rawline-semi-bold {
  font-family: $rawline;
  font-weight: 600;
  font-style: normal;
}

.rawline-bold {
  font-family: $rawline;
  font-weight: bold;
  font-style: normal;
}

.rawline-regular {
  font-family: $rawline;
  font-weight: normal;
  font-style: normal;
}

.vanitas-regular {
  font-family: $vanitas;
  font-weight: normal;
  font-style: normal;
}

.vanitas-bold {
  font-family: $vanitas;
  font-weight: bold;
  font-style: normal;
}

.montserrat-regular {
  font-family: $montserrat;
  font-weight: normal;
  font-style: normal;
}

.montserrat-light {
  font-family: $montserrat;
  font-weight: 300;
  font-style: normal;
}

.montserrat-ultra-light {
  font-family: $montserrat-ultra;
  font-weight: 200;
  font-style: normal;
}
