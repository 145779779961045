.notification-center {
  display: block;
  position: fixed;
  height: auto;
  z-index: 1000;

  .notification {
    color: $white;
    font-size: 12px;
    @extend .montserrat-regular;
    box-sizing: border-box;
    padding: 20px 40px 20px 20px;
    margin-bottom: 20px;
    transition: 500ms $ease-out-expo all;
    border: solid 1px;
    position: relative;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    pointer-events: none;

    &.show {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      pointer-events: all;
    }

    span.close {
      height: 16px;
      width: 16px;
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 15px;
      margin-top: -8px;
      cursor: pointer;
      transform: rotate(45deg);
      &:after,
      &:before {
        content: ' ';
        display: block;
        background: $white;
        position: absolute;
      }
      &:after {
        height: 100%;
        width: 2px;
        top: 0;
        left: 7px;
      }
      &:before {
        height: 2px;
        width: 100%;
        top: 7px;
        left: 0;
      }
    }

    &.success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
      span.close {
        &:after,
        &:before {
          background-color: #3c763d;
        }
      }
    }
    &.warning {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
      span.close {
        &:after,
        &:before {
          background-color: #8a6d3b;
        }
      }
    }
    &.error {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      span.close {
        &:after,
        &:before {
          background-color: #a94442;
        }
      }
    }
  }
}
