@import 'zones';

.ct--zones {
  .zones-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 40px;
    text-align: left;

    .ct--zone-item,
    .ct--zone-item-cta {
      box-sizing: border-box;
      flex-wrap: wrap;
      width: calc(100% / 3 - 20px);

      &:nth-child(2) {
        margin: 0 30px;
      }

      .about {
        padding: 20px;

        .title-news {
          margin: 0;
          font-size: 2rem;
        }
      }
    }

    .ct--zone-item-cta {
      position: relative;

      a {
        @extend .text-1;
        background-color: #fff8f0;
        color: $yellow;
        font-size: 3rem;
        position: absolute;
        width: 100%;
        height: calc(83% - 2px);
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 250ms, background-color 250ms;

        span {
          padding: 20px 5px;
          border-bottom: 3px solid $yellow;
          transition: border-bottom-color 250ms;
        }

        svg {
          color: $yellow;
          fill: $yellow;
          position: relative;
          top: 3px;
          margin-left: 10px;
          width: 0.75em;
          height: 0.75em;
          transition: color 250ms, fill 250ms;
        }

        &:hover {
          $color: #fff;
          background-color: $yellow;
          color: $color;
          span {
            border-bottom-color: $color;
          }
          svg {
            color: $color;
            fill: $color;
          }
        }
      }
    }
  }
}
