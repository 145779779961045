@import 'double-col';

.ct.ct--double-col {
  .container {
    .cols {
      display: flex;
      align-items: stretch;
      .col {
        .inner {
          .headline {
            position: absolute;
            right: 0;
            transform: translateY(-100%);
            white-space: nowrap;
          }
        }
        &.col-picture {
          .slider {
            margin-top: 140px;
            &:after {
              position: absolute;
              content: '';
              width: span(4 of 5);
              height: span(4 of 5);
              top: -span(1 of 5);
              right: -span(1 of 5);
              z-index: 1;
              background: blue;
            }
          }
        }
      }
    }
  }
}
