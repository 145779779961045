.ct--select-checkbox-multiple {
  position: relative;
  &.open {
    .filter-select-choice {
      .head-choice {
        @extend .rawline-bold;
      }
      .other-choice {
        display: block;
      }
      .all-choices {
        display: flex;
      }
    }
    .filter-choice .valid {
      display: block;
    }
    &.from-sidebar {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
  &.from-sidebar {
    .filter-select-choice .other-choice {
      max-height: initial;
    }
    .head-choice {
      padding: 7px 15px 15px;
      &:after {
        display: block;
      }
    }
  }
  .filter-select-choice {
    position: relative;
    width: 100%;
    .backup-cross {
      display: none;
    }
    .generic-label {
      display: block;
    }
    .response-filter.from-sidebar {
      margin-bottom: 20px;
      li {
        background: $medium-beige;
        padding: 5px 15px;
        margin: 0 2px 2px 0;
        font-size: 1rem;
        line-height: initial;
        display: inline-flex;
        align-items: center;
        span {
          @extend .rawline-regular;
          font-size: 13px;
          line-height: initial;
        }
        .icon {
          width: 10px;
          height: 10px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .select-choice,
    .all-choices,
    .head-choice {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background: $light-beige;
      align-items: center;
      cursor: pointer;
      .label {
        color: $black;
        @extend .rawline-regular;
        // font-size: 1.5rem;
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 0;
        &::before,
        &::after {
          content: ' ';
        }
      }
      /* Base for label styling */
      [type='checkbox']:not(:checked),
      [type='checkbox']:checked {
        position: absolute;
        left: -9999px;
      }
      [type='checkbox']:not(:checked) + label,
      [type='checkbox']:checked + label {
        position: relative;
        padding-right: 15px;
        cursor: pointer;
      }

      /* checkbox aspect */
      [type='checkbox']:not(:checked) + label:before,
      [type='checkbox']:checked + label:before {
        content: ' ';
        position: absolute;
        right: 0;
        top: 2px;
        width: 12px;
        height: 12px;
        border: 1px solid $medium-grey;
        background: $white;
        border-radius: 0;
      }
      /* checked mark aspect */
      [type='checkbox']:not(:checked) + label:after,
      [type='checkbox']:checked + label:after {
        content: ' ';
        position: absolute;
        right: 2px;
        top: 4px;
        height: 8px;
        width: 8px;
        background: $dark-duck;
        transition: all 0.2s;
      }
      /* checked mark aspect changes */
      [type='checkbox']:not(:checked) + label:after {
        display: none;
      }
      [type='checkbox']:checked + label:after {
        display: block;
      }
      input {
        pointer-events: none;
        // display: none;
      }
    }
    .head-choice {
      position: relative;
      padding: 7px 15px;
      @extend .rawline-regular;
      &:after {
        position: absolute;
        content: '';
        width: calc(100% - 30px);
        height: 1px;
        background: $dark-beige;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
      }
      .arrow {
        position: relative;
        width: 8px;
        height: 5px;
        transform: rotate(0deg);
        .icon {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      span {
        font-size: 13px;
        font-weight: bold;
      }
    }
    .all-choices {
      border-bottom: 1px solid $medium-grey;
      .label {
        font-size: 13px;
        margin: 0;
      }
    }
    .other-choice,
    .all-choices {
      display: none;
    }
    .other-choice {
      overflow-y: auto;
      width: 100%;
      max-height: calc(62px * 4);
      // padding:
    }
  }
  .filter-choice {
    position: relative;
    color: $black;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 35px;
    background: $light-beige;
    @extend .filter-1;
    .response-filter {
      display: block;
      width: calc(100% - 30px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: bold;
    }
    .arrow {
      position: relative;
      width: 8px;
      height: 5px;
      .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .valid {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $yellow;
      z-index: 5;
      left: 0;
      top: 0;
      border: 0;
      cursor: pointer;
      display: none;
      .icon {
        position: absolute;
        width: 10px;
        height: 10px;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px;
        z-index: 10;
        fill: $bobby-brown;
      }
      @include ct--button-action;
    }
  }
}
