.footer-faq,
.landing-section-faq {
  line-height: 1.5;
  margin-top: 40px;
  padding: 0 60px 60px;

  .landing-section-faq-title {
    margin-bottom: 50px;
    line-height: 1.25;
    text-align: center;
  }

  .landing-section-faq-content {
    .landing-section-faq-question {
      display: inline-block;
      margin-bottom: 20px;
      margin-right: 4%;
      vertical-align: top;
      width: 45%;

      .title-2 {
        line-height: 1.2;
        margin-bottom: 15px;
      }

      p {
        a {
          color: $yellow;
          font-weight: bold;
        }
      }
    }
  }
}

.landing-section-faq-container-single-column {
  .footer-faq,
  .landing-section-faq {
    .landing-section-faq-content {
      .landing-section-faq-question {
        display: block;
        margin-right: 0;
        width: 100%;
      }
    }
  }
}
