.page.menu-search {
  .intro-page-result {
    padding: 0 25px;
    margin-bottom: 25px;
    b {
      font-weight: 700;
    }
  }
}

.page.animation-index,
.page.menu-index {
  margin-top: 0 !important;

  .intro-page-result {
    padding: 0 25px;
    margin-bottom: 25px;
  }

  .animations-grid {
    padding: 25px;

    .animations-grid-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .animations-grid-item {
        position: relative;

        &:before {
          background-color: rgba(55, 55, 55, 0.55);
          content: '';
          display: block;
          flex: none;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          transition: background-color 0.2s ease;
        }

        background: transparent center center no-repeat;
        background-size: cover;
        color: #fff;
        text-align: center;
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        padding: 75px 10px;

        .animation-type {
          font-family: $rawline;
          font-size: 18px;
          padding: 10px;
          text-transform: uppercase;
          z-index: 2;
        }

        .animation-title {
          font-family: $vanitas;
          font-weight: bold;
          font-size: 42px;
          padding: 10px;
          z-index: 2;
          text-shadow: rgba(0, 0, 0, 0.15) 1px 4px 4px;
        }

        &:hover {
          &:before {
            background-color: rgba(55, 55, 55, 0.75);
          }
        }
      }
    }
  }
}
