@import 'button';

.ct.ct--button {
  &.arrow {
    &:hover {
      &:before {
        transform: scaleX(1);
        transform-origin: 0% 100%;
      }
      .label-btn {
        color: $yellow;
      }
      .arrow {
        transform: translateX(5px);
        transition: transform 250ms $ease-in-out-quad 250ms;
        .icon {
          fill: $yellow;
        }
      }
    }
  }
  &.text {
    &:hover {
      &:before {
        transform: scaleX(1.05) translateZ(0);
        background: $light-duck;
      }
      .label-btn {
        color: $light-duck;
        letter-spacing: 0.5px;
      }
      .arrow {
        transform: translateX(7px);
        .icon {
          fill: $light-duck;
        }
      }
    }
  }
}
