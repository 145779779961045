@charset "UTF-8";

/*********************
Vendors
*********************/

@import '~nouislider/distribute/nouislider.css';
@import '~@splidejs/splide/dist/css/splide.min.css';

/*********************
Utils & Variables
*********************/

@import 'utils/accessibility';
@import 'utils/mixins';
@import 'variables/colors';
@import 'variables/eases';
@import 'variables/fonts';
@import 'variables/grid';
@import 'utils/functions';
@import 'utils/normalize';
@import 'utils/reset';
@import 'utils/grid.desktop.scss';

/*********************
Common
*********************/
@import 'common/texts/texts.desktop';
@import 'common/layout/layout.desktop';
@import 'common/search/search.desktop';
@import 'common/search/search-enhanced.desktop';
@import 'common/search/index.desktop';
@import 'common/featured/featured.desktop';
@import 'common/show/show.desktop';
@import 'common/input/input.desktop';
@import 'common/input-account/input-account.desktop';
@import 'common/input-contact/input-contact.desktop';
@import 'common/notification/center.desktop';
@import 'common/notification/toaster.desktop';

/*********************
Components
*********************/

@import 'components/button/button.desktop';
@import 'components/navbar/navbar.desktop';
@import 'components/key-figures/key-figures.desktop';
@import 'components/double-col/double-col.desktop';
@import 'components/double-col-slider/double-col-slider.desktop';
@import 'components/double-col-slider-companies/double-col-slider-companies.desktop';
@import 'components/newsletter/newsletter.desktop';
@import 'components/menu/menu.desktop';
@import 'components/footer/footer.desktop';
@import 'components/header/header.desktop';
@import 'components/last-news/last-news.desktop';
@import 'components/select-checkbox/select-checkbox.desktop';
@import 'components/select-checkbox-multiple/select-checkbox-multiple.desktop';
@import 'components/select-slider/select-slider.desktop';
@import 'components/popins/popins.desktop';
@import 'components/navbar-search/navbar-search.desktop';
@import 'components/navbar-account/navbar-account.desktop';
@import 'components/item-result/item-result.desktop';
@import 'components/calendar/calendar.desktop';
@import 'components/select-range/select-range.desktop';
@import 'components/slider-item/slider-item.desktop';
@import 'components/control-quantity/control-quantity.desktop';
@import 'components/news-item/news-item.desktop';
@import 'components/instagram-net/instagram-net.desktop';
@import 'components/breadcrumb/breadcrumb.desktop';
@import 'components/select-search/select-search.desktop';
@import 'components/loader/loader';
@import 'components/lazy-load/lazy-load';
@import 'components/admin-panel/admin-panel';
@import 'components/gallery/gallery';
@import 'components/blog/articles.desktop';
@import 'components/selection/selection.desktop';
@import 'components/selection-modal/selection-modal.desktop';
@import 'components/tooltip/tooltip';
@import 'components/alert/alert';
@import 'components/zones/zones.desktop';
@import 'components/faq/faq.desktop';
@import 'components/filters/filters.desktop';
@import 'components/generic-modal/generic-modal.desktop';
@import 'components/category-slider/category-slider.desktop';
@import 'components/trends/trends.desktop';
@import 'components/content-block/content-block.desktop';
@import 'components/logos-slider/logos-slider.desktop';

/*********************
Pages
*********************/

@import 'pages/homepage/homepage.desktop';
@import 'pages/contact/contact.desktop';
@import 'pages/about-us/about-us.desktop';
@import 'pages/faq/faq.desktop';
@import 'pages/confidentiality/confidentiality.desktop';
@import 'pages/rent/rent.desktop';
@import 'pages/zones/zones.desktop';
@import 'pages/event/event.desktop';
@import 'pages/dashboard/dashboard.desktop';
@import 'pages/error/404/404.desktop';
@import 'pages/hellosign/hellosign-confirmation.desktop';
@import 'pages/team/team.desktop';
@import 'pages/tos/tos.desktop';
@import 'pages/events/events.desktop';
@import 'pages/charte-qualite/charte-qualite.desktop';
@import 'pages/brief/brief.desktop';
@import 'pages/seminar/seminar.desktop';
@import 'pages/seminar/seminaire-landing.desktop';
@import 'pages/static-place/static-place.desktop';

/*********************
User
*********************/

@import 'pages/user/login/login.desktop';
@import 'pages/user/register/register.desktop';
@import 'pages/user/guest/guest.desktop';
@import 'pages/user/transmission-request/transmission-request.desktop';
@import 'pages/user/validation-request/validation-request.desktop';

/*********************
Generic
*********************/

@import 'snap/snap.desktop';
