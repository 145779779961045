.row-input-contact,
.row-input-contact-place {
  width: 100%;
  height: auto;

  .label-contact {
    color: #f4c657;
    text-transform: uppercase;
    font-weight: 900;
  }

  .input-contact {
    width: 100%;
    background-color: $white;
    padding: 0 20px;
    margin-bottom: 5px;
    border: 1px solid transparent;
    font-size: 14px;
    &::placeholder {
      font-size: 1.4rem;
      color: $basket-li;
      font-family: $rawline;
      font-weight: normal;
    }
  }

  .message-error {
    display: block;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    color: $red;
    margin-bottom: 15px;
  }

  &.error {
    .input-contact {
      border: 1px solid $red;
      color: $red;
    }
    .message-error {
      visibility: visible;
      opacity: 1;
    }
  }
}
