@import '../../variables/colors';

.alert {
  $alert-color: $yellow;
  background: transparentize($yellow, 0.75);
  border: 1px solid transparentize($yellow, 0.5);
  color: $bobby-brown;
  margin: 10px;
  padding: 15px 20px;
  position: relative;
  @extend .text-1;
  line-height: 1.7;
  a {
    color: $bobby-brown;
    font-weight: bold;
  }
  &.alert-icon {
    padding-left: 64px;
    svg {
      display: block;
      height: 24px;
      left: 20px;
      position: absolute;
      top: calc(50% - 12px);
      width: 24px;
    }
  }
  &.alert-danger {
    $alert-color: #b0413e;
    background: transparentize($alert-color, 0.75);
    border: 1px solid transparentize($alert-color, 0.5);
    color: darken($alert-color, 30%);
    a {
      color: darken($alert-color, 30%);
    }
  }
  &.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    .icon {
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-right: 2px;
    }
  }
}
