@import 'header';

.header {
  position: relative;
  text-align: center;
  //padding-top: 260px;
  //padding-bottom: 20px;
  color: $white;
  background-color: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // width: 100vw;
  &:before {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: percentage(708 / 1366);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(55, 55, 55, 0.7);
    z-index: 5;
  }
  &.min-header {
    height: 580px;
  }

  &.full-header {
    //height: 100vh;
  }

  .first-title,
  .subtitle-header,
  .icon-container-header {
    z-index: 10;
  }

  .first-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: rgba(0, 0, 0, 0.15) 1px 4px 4px;
    small {
      display: block;
      font-size: 5rem;
    }
  }

  .cta-wrapper {
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  .subtitle-header {
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    color: $white;
    font-weight: 900;
    text-transform: uppercase;
  }

  .icon-container-header {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    transition: bottom 0.4s $ease-out-cubic;
    animation: arrow-down-move 1.5s infinite linear alternate;

    .icon-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;

      .header-arrow-down-sup {
        display: inline-flex;
        align-items: center;
        background: transparent;
        .icon {
          width: 9px;
          height: 7px;
          fill: $white;
          stroke: $white;
          color: $white;
        }
      }

      .header-arrow-down-sub {
        display: inline-flex;
        align-items: center;
        background: transparent;
        margin-top: 1px;

        .icon {
          width: 9px;
          height: 7px;
          fill: $white;
          stroke: $white;
          color: $white;
        }
      }
    }
  }
  @keyframes arrow-down-move {
    0% {
      bottom: 35px;
    }
    15% {
      bottom: 30px;
    }
    30% {
      bottom: 35px;
    }
  }
}
