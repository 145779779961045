@import 'login';

.page.user.login {
  padding: 80px 0 50px;

  .title-2-login {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .link-login {
    font-size: 1.3rem;
  }
  .big-title-login {
    font-size: 2.4rem;
  }

  .container-col {
    padding: 80px;

    .new-account {
      padding-right: 130px;
      width: 50%;
      .headline {
        font-size: 1.5rem;
      }
      .advantages-account {
        font-size: 1.3rem;
      }
    }
    .connexion-account {
      border-left: 1px solid $breadcrumb;
      width: 50%;
      form {
        .connexion {
          span {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
