.ct--loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 15;
  &.loading {
    display: block;
    .spinner-ring div {
      animation-play-state: running;
    }
  }
  .spinner-ring {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $yellow;
    border-radius: 50%;
    animation: spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation-play-state: paused;
    border-color: $yellow transparent transparent transparent;
  }
  .spinner-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .spinner-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .spinner-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spinner-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
