$brand-primary: $yellow !default;
$brand-success: $yes-picto !default;
$brand-info: $light-duck !default;
$brand-warning: $dark-duck !default;
$brand-danger: $no-picto !default;

$btn-font-weight: 300 !default;

$btn-default-color: $black !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-primary-color: $black !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color: $basket-li !default;

$padding-base-vertical: 0 !default;
$padding-base-horizontal: 40px !default;

$padding-large-vertical: 0 !default;
$padding-large-horizontal: 50px !default;

$padding-small-vertical: 0 !default;
$padding-small-horizontal: 24px !default;

$padding-xs-vertical: 0 !default;
$padding-xs-horizontal: 16px !default;

$line-height-large: 60px !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 40px !default;
$line-height-xs: 30px !default;

$border-radius-base: 0 !default;
$border-radius-large: 0 !default;
$border-radius-small: 0 !default;

$font-size-base: 1.3rem !default;
$font-size-large: 1.4rem !default; // ~18px
$font-size-small: 1.2rem !default; // ~12px

$line-height-base: 50px; // 20/14
$line-height-computed: floor(
  ($font-size-base * $line-height-base)
) !default; // ~20px

$btn-border-radius-base: $border-radius-base !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default;

$cursor-disabled: not-allowed !default;

$link-color: $yellow !default;
$link-hover-color: $yes-picto !default;
$link-hover-decoration: underline !default;

$input-color: $input-bg;
$input-border: $input-bg;
$input-border-radius: $border-radius-base;
$input-border-radius-large: $border-radius-large;
$input-border-radius-small: $border-radius-small;
$input-group-addon-bg: darken($input-bg, 25%) !default;
$input-group-addon-border-color: darken($input-bg, 25%);

//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg: #f5f5f5 !default;
//** Progress bar text color
$progress-bar-color: #fff !default;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base !default;

//** Default progress bar color
$progress-bar-bg: $brand-primary !default;
//** Success progress bar color
$progress-bar-success-bg: $brand-success !default;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning !default;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger !default;
//** Info progress bar color
$progress-bar-info-bg: $brand-info !default;
